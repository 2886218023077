<template>
    <div class="min-h-full flex flex-col justify-center py-6 sm:px-6 lg:px-8">
    <div class="mx-auto md:w-full md:max-w-xl">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 mt-8">
            <div class="text-lg text-center max-w-prose mx-auto">
                <h2>
                    <span class="mt-2 block text-2xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Você está quase lá!</span>
                </h2>
                <p class="mt-4 text-gray-500 leading-8">Antes de começar vamos configurar sua conta.</p>
                <img src="@/assets/person_info.svg" class="mt-4 h-25 mx-auto text-center" />
            </div>
            <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto text-center">
                <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <form class="mt-8 text-left" @submit.prevent="onSubmit" novalidate>
                        <label for="name" class="font-bold">Defina um nome para sua conta</label>
                        <input v-model="state.name" 
                            id="name" 
                            name="name" 
                            type="text" 
                            autocomplete="off" 
                            placeholder="Ex.: Finanças domésticas, Finanças empresariais"
                            required="true" class="w-full flex py-3 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" />
                        <p class="mt-2 text-xs text-gray-400">No Fluxous você pode criar diferentes contas para diferentes negócios que você administra.</p>
                        <div v-if="v$.name.$error" class="text-sm text-red-600">
                            <div>{{ v$.name.$errors[0].$message }}</div>
                        </div>
                        
                        <div class="mt-4 rounded-md shadow sm:mt-3 sm:flex-shrink-0">
                            <button :disabled="isLoading" type="submit" class="w-full flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                             <Spinner v-if="isLoading" />
                            Salvar
                            </button>
                        </div>
                    </form>
                </div>
            </div> 
        </div>
    </div> 
</div>
</template>

<script setup lang="ts">

import { useRouter } from 'vue-router';
import { store } from '@/store';
import ActionEnum from '@/store/modules/auth/action-enum';
import CompanyService from '@/services/CompanyService';
import { computed, reactive, ref } from 'vue';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { AxiosError } from 'axios';
import Spinner from '@/components/Spinner.vue';
import createUserCompany from '@/useCases/createUserCompany';

const router = useRouter();

const state = reactive({
    name: ''
});

const isLoading = ref(false);

const rules = computed(() => ({
    name: {
        required: helpers.withMessage('Obrigatório', required)
    },
}));
const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const createCompany = async () => {
    isLoading.value = true;

    const { name } = state;

    try {
        await createUserCompany.execute(name);
        isLoading.value = false;
    } catch (error: any | AxiosError) {
        $externalResults.value = { ...error?.response.data.errors };
        isLoading.value = false;
    }
}

const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();
    console.log(isValid);

    if (!isValid) return;

    await createCompany();
}


const onLogout = async () => {
    try {
        await store.dispatch(`auth/${ActionEnum.AUTH_LOGOUT}`);
        await router.push({ name: 'login' });
    } catch (error: any) {
        console.log(error);
    }
}
</script>