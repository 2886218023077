<template>
<input ref="inputRef" type="text" />
</template>

<script lang="ts">
import { defineComponent, PropType, watch } from 'vue';
import { useCurrencyInput, CurrencyInputOptions} from 'vue-currency-input';
import { watchDebounced } from '@vueuse/core'

export default defineComponent({
    name: 'CurrencyInput',
    emits: ['update:modelValue', 'change'],
    props: {
        modelValue: {
            type: Number,
            default: () => ({}),
        },
        options: {
            type: Object as PropType<CurrencyInputOptions> | any
        }
    },
    setup(props, {emit}) {
        const { inputRef, numberValue, setOptions, setValue } = useCurrencyInput(props.options, false)

        watchDebounced(numberValue, (value) => emit('update:modelValue', value), { debounce: 100 })


        watch(
            () => props.modelValue, // Vue 2: props.value
            (value) => {
                setValue(value)
            }
        )

        watch(
            () => props.options,
            (options) => {
                setOptions(options)
            }
        )
        return { inputRef };
    }
});

</script>