import dayjs from "dayjs";
import Period from "../Period";
import IPeriodFormatter from "./IPeriodFormatter";

export default class CustomFormatter implements IPeriodFormatter {

    format (interval: Period): string  {
        return `
            ${dayjs(interval.start).format('DD MMM YYYY')} - 
            ${dayjs(interval.end).format('DD MMM YYYY')}
        `;
    }

    
}