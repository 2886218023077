<template>
    <div>
        <div class="text-lg text-gray-500">
            Bem-vindo, <span class="font-bold">{{ user.name }}</span>
        </div>
        <div class="mt-4 flex flex-row gap-6">
            <div class="basis-1/2">
                <MyCompaniesWidget />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { store } from '@/store';
import { computed } from '@vue/reactivity';
import MyCompaniesWidget from '@/components/User/Widgets/MyCompaniesWidget.vue';
import Company from '@/types/Company';

const user = computed( () => store.getters['user/userInfo']);
const currentCompany = computed<Company>(() => store.getters['user/currentCompany'])
</script>