<template>
<div id="divUserInfo" class="relative overflow-visible pb-6">
    <Menu :style="{left: '78%'}" as="div" v-if="seriesIncome.length || seriesExpense.length" class="relative inline-block text-left z-30 self-start">
        <div class="fixed relative">
            <MenuButton class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <DownloadIcon class="h-6 w-6 inline mr-2"/>
                <span class="sr-only">Abrir opções</span>
                <span class="text-xs">Exportar categorias listadas</span>
            </MenuButton>
        </div>

        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-left absolute right-0 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                <MenuItem v-slot="{ active }">
                    <a href="#" @click.prevent="() => exportAsPng()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        PNG
                    </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                    <a href="#" @click.prevent="() => exportAsCSV()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                        CSV
                    </a>
                </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>

    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="text-gray-500 px-2 pb-4">
        <label for="predicted-checkbox" class="text-xs">
            <Checkbox v-model="predicted" class="mr-2" id="predicted-checkbox"/>
            Considerar lançamentos não pagos
        </label>
    </div>

    <div class="flex gap-2">
        <div class="w-full flex justify-center items-center" :class="[!seriesIncome.length ? 'h-48' : '']">
            <VueApexCharts v-if="seriesIncome.length" height="350px" type="donut" :options="chartOptionsIncome" :series="seriesIncome" class="p-0 m-0"/>
            <div v-else class="flex-col text-center text-gray-400 text-sm items-center justify-center">
                <QuestionMarkCircleIcon class="h-16 w-16 inline text-gray-300"/>
                <p>Sem receitas no período</p>
            </div>
        </div>
        <div class="w-full flex justify-center items-center" :class="[!seriesExpense.length ? 'h-48' : '']">
            <VueApexCharts v-if="seriesExpense.length" height="350px" type="donut" :options="chartOptionsExpense" :series="seriesExpense" class="p-0 m-0"/>
            <div v-else class="flex-col text-center text-gray-400 text-sm items-center justify-center">
                <QuestionMarkCircleIcon class="h-16 w-16 inline text-gray-300"/>
                <p>Sem despesas no período</p>
            </div>
        </div>
    </div>

    <div class="mt-8">
        <h1 class="text-md p-2">Receitas</h1>
        <dl class="mt-1 w-full divide-y divide-gray-200 px-2">
            <Disclosure as="div" v-for="income in results.income" :key="income.id" v-slot="{ open }" class="py-2 px-2 text-sm text-gray-600 hover:text-green-600 transition-colors">
                <dt class="w-full ">
                    <DisclosureButton class="flex w-full justify-between text-left font-medium">
                        <div class="flex items-center">
                            <TransactionKindBadge :kind="income.kind" class="mr-2" /> {{ income.name }}
                        </div>
                        <div class="flex items-center justify-between gap-4">
                            <span>{{  Dinero({ amount: Number(income.transactions_sum_amount),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                            <ChevronRightIcon :class="[open ? 'rotate-90' : 'rotate-0', 'h-4 w-4 transform inline']" aria-hidden="true" />
                        </div>
                        
                    </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd">
                    <div class="table w-full text-xs mt-1 px-1">
                        <div class="table-row-group">
                            <div style="display:grid;grid-template-columns: 1fr 1fr 1fr;" class="table-row text-gray-600 hover:bg-gray-100 transition-colors" v-for="transaction in income.transactions" >
                                <div class="table-cell py-1 px-1">
                                    {{  transaction.description }}
                                </div>
                                <div class="table-cell py-1 px-1 text-right">{{ dayjs(transaction.billing_date ).format('DD/MM/YYYY') }}</div>
                                <div class="table-cell py-1 px-1 text-right">
                                    <span>{{  Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                                    <span class="ml-2" :title="transaction.paid ? 'Pago' : 'Não pago'">
                                        <ThumbUpIcon v-if="transaction.paid" class="h-4 w-4 inline text-green-500" aria-hidden="true" />
                                        <ThumbDownIcon v-else class="h-4 w-4 inline text-gray-400" aria-hidden="true" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </DisclosurePanel>
            </Disclosure>
        </dl>
    </div>

    <div class="mt-8" id="expense">
        <h1 class="text-md p-2">Despesas</h1>
        <dl class="mt-1 w-full divide-y divide-gray-200 px-2">
            <Disclosure as="div" v-for="expense in results.expense" :key="expense.id" v-slot="{ open }" class="py-2 px-2 text-sm text-gray-600 hover:text-red-600 transition-colors">
                <dt class="w-full ">
                    <DisclosureButton class="flex w-full justify-between text-left font-medium">
                        <div class="flex items-center">
                            <TransactionKindBadge :kind="expense.kind" class="mr-2" /> {{ expense.name }}
                        </div>
                        <div class="flex items-center justify-between gap-4">
                            <span>{{  Dinero({ amount: Number(expense.transactions_sum_amount),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                            <ChevronRightIcon :class="[open ? 'rotate-90' : 'rotate-0', 'h-4 w-4 transform inline']" aria-hidden="true" />
                        </div>
                    </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd">
                    <div class="table w-full text-xs mt-1 px-1">
                        <div class="table-row-group">
                            <div style="display:grid;grid-template-columns: 1fr 1fr 1fr;" class="table-row text-gray-600 hover:bg-gray-100 transition-colors" v-for="transaction in expense.transactions" >
                                <div class="table-cell py-1 px-1">
                                    {{  transaction.description }}
                                </div>
                                <div class="table-cell py-1 px-1 text-right">{{ dayjs(transaction.billing_date ).format('DD/MM/YYYY') }}</div>
                                <div class="table-cell py-1 px-1 text-right">
                                    <span>{{  Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                                    <span class="ml-2" :title="transaction.paid ? 'Pago' : 'Não pago'">
                                        <ThumbUpIcon v-if="transaction.paid" class="h-4 w-4 inline text-green-500" aria-hidden="true" />
                                        <ThumbDownIcon v-else class="h-4 w-4 inline text-gray-400" aria-hidden="true" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </DisclosurePanel>
            </Disclosure>
        </dl>
    </div>
</div>
<div id="reportInfoMain" class="relative overflow-visible pb-6" v-if="seriesIncome.length || seriesExpense.length" style="clip-path: inset(0 100% 0 0);margin-top: -40vh;">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div id="reportInfo">
        <h1 class="text-md p-2 text-center">Relatório de Categorias</h1>
        <div class="text-center">
            <span id="reportInfoDate">Perído: dd/mm/yyyy - dd/mm/yyyy</span>
        </div>
        <hr class="mt-5" style="border-top: 5px solid #bbb;border-radius: 2px;"/>
    </div>

    <div class="mt-8" id="incomeReports">
        <h1 class="text-md p-2">Receitas</h1>
        <dl class="mt-1 w-full divide-y divide-gray-200 px-2">
            <div v-for="income in results.income" :key="income.id" class="py-2 px-2 text-sm text-gray-600 hover:text-green-600 transition-colors">
                <dt class="w-full ">
                    <div class="flex w-full justify-between text-left font-medium">
                        <div class="flex items-center">
                            <TransactionKindBadge :kind="income.kind" class="mr-2" /> {{ income.name }}
                        </div>
                        <div class="flex items-center justify-between gap-4">
                            <span>{{  Dinero({ amount: Number(income.transactions_sum_amount),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                        </div>
                    </div>
                </dt>
                <dd>
                    <div class="table w-full text-xs mt-1 px-1">
                        <div class="table-row-group">
                            <div style="display:grid;grid-template-columns: 1fr 1fr 1fr;" class="table-row text-gray-600 hover:bg-gray-100 transition-colors" v-for="transaction in income.transactions" >
                                <div class="table-cell py-1 px-1">
                                    {{  transaction.description }}
                                </div>
                                <div class="table-cell py-1 px-1 text-right">{{ dayjs(transaction.billing_date).format('DD/MM/YYYY') }}</div>
                                <div class="table-cell py-1 px-1 text-right">
                                    <span>{{  Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </div>
        </dl>
        <hr class="mt-5" style="border-top: 5px solid #bbb;border-radius: 2px;"/>
    </div>

    <div class="mt-8" id="expenseReports">
        <h1 class="text-md p-2">Despesas</h1>
        <dl class="mt-1 w-full divide-y divide-gray-200 px-2">
            <div v-for="expense in results.expense" :key="expense.id" class="py-2 px-2 text-sm text-gray-600 hover:text-red-600 transition-colors">
                <dt class="w-full ">
                    <div class="flex w-full justify-between text-left font-medium">
                        <div class="flex items-center">
                            <TransactionKindBadge :kind="expense.kind" class="mr-2" /> {{ expense.name }}
                        </div>
                        <div class="flex items-center justify-between gap-4">
                            <span>{{  Dinero({ amount: Number(expense.transactions_sum_amount),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                        </div>
                        
                    </div>
                </dt>
                <dd>
                    <div class="table w-full text-xs mt-1 px-1">
                        <div class="table-row-group">
                            <div style="display:grid;grid-template-columns: 1fr 1fr 1fr;" class="table-row text-gray-600 hover:bg-gray-100 transition-colors" v-for="transaction in expense.transactions" >
                                <div class="table-cell py-1 px-1">
                                    {{  transaction.description }}
                                </div>
                                <div class="table-cell py-1 px-1 text-right">{{ dayjs(transaction.billing_date).format('DD/MM/YYYY') }}</div>
                                <div class="table-cell py-1 px-1 text-right">
                                    <span>{{  Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </dd>
            </div>
        </dl>
    </div>

    <div>
        <h1 style="color: #999999; font-size: 1.5vh" id="reportIssueDate">Relatório emitido em: dd/mm/yyy</h1>
    </div>
</div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, PropType, inject } from 'vue';
import { watchDebounced } from '@vueuse/shared';
import VueApexCharts from 'vue3-apexcharts';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronRightIcon, ThumbUpIcon } from '@heroicons/vue/solid';
import { ThumbDownIcon, QuestionMarkCircleIcon, DownloadIcon } from '@heroicons/vue/outline';
import { Toast } from '@/constants/swal-mixins';

import { sumBy } from 'lodash';
import ReportService from '@/services/ReportService';
import Category from '@/types/Category/Category';
import { IFilter } from '@/types/IFilter';

import {store} from '@/store';

import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import TransactionKindBadge from '@/components/Transactions/TransactionKindBadge.vue';
import Checkbox from '@/components/Shared/Checkbox.vue';
import { IImageToCompile, combine } from '../../../modules/Export';
import * as htmlToImage from 'html-to-image';
import headerImage from '/src/assets/logo-menu.png';

interface Transaction {
    description: string;
    amount: number; 
    billing_date: string;
    is_overdue: boolean;
    is_payable: Boolean;
    kind: string;
    paid: boolean;
}

interface CategoryWithSumAmount extends Category {
    transactions_sum_amount: number;
    transactions: Array<Transaction>
}

interface ReportResponse {
    income: Array<CategoryWithSumAmount>,
    expense: Array<CategoryWithSumAmount>,
    previous_balance: Array<CategoryWithSumAmount>;
}

const predicted = ref(false);
const isLoading = ref(false);
const reportInfo = ref<string>("");
const filters = inject('Filter') as IFilter;
const results = ref<ReportResponse>({} as ReportResponse);
const seriesIncome = ref<Array<number>>([]);
const labelsIncome = ref<Array<string>>([]);
const seriesExpense = ref<Array<number>>([]);
const labelsExpense = ref<Array<string>>([]);

const chartOptionsCommon = ref<ApexOptions>({
    chart: {
        type: 'donut',
    },
    plotOptions: {
        pie: {
            donut: {
                size: '60%',
                labels: {
                    show: true,
                    name: {
                        formatter: function(val: string) {
                            return val;
                        }
                    },
                    value: {
                        fontSize: '15px',
                        formatter: function(val: string) {
                            try {
                                return Dinero({ amount: Number(val),  currency: 'BRL' }).setLocale('pt-BR').toFormat()
                            } catch(error) {
                                return '';
                            }
                        }
                    },
                    total: {
                        show: true,
                        showAlways: true,
                        label: 'Total',
                        fontSize: '15px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 600,
                        color: '#373d3f',
                        formatter: function (w) {
                            const total = w.globals.seriesTotals.reduce((a: number, b: number) => {
                                return a + b
                            }, 0);
                            return Dinero({ amount: total,  currency: 'BRL' }).setLocale('pt-BR').toFormat()
                        }
                    }
                }
            }
        }
    },
    tooltip: {
        enabled: true,
        shared: false,
        y: {
            formatter: function(val: number) {
                return val ? Dinero({ amount: val,  currency: 'BRL' }).setLocale('pt-BR').toFormat() : ''
            }
        },
        followCursor: false,
        onDatasetHover: {
            highlightDataSeries: true,
        },
        style: {
            fontSize: '12px',
        },
    },
});

const chartOptionsIncome = ref<ApexOptions>({
    chart: {
        id: 'vuechart-income',
        type: 'donut',
        background: '#fff'
    },   
    legend: {
        show: true,
        showForSingleSeries: true,
        position: 'bottom',
        horizontalAlign: 'center', 
    }, 
    title: {
        text: 'Receitas',
        align: 'center',
    },
    theme: {
        mode: 'light', 
        palette: 'palette1', 
        monochrome: {
            enabled: true,
            color: '#28B463',
            shadeTo: 'light',
            shadeIntensity: 0.70
        },
    },    
    plotOptions: chartOptionsCommon.value.plotOptions,
    tooltip: chartOptionsCommon.value.tooltip,
});

const chartOptionsExpense = ref<ApexOptions>({
    chart: {
        id: 'vuechart-expense',
        type: 'donut',
        background: '#fff'
    },
    legend: {
        show: true,
        showForSingleSeries: false,
        position: 'bottom',
        horizontalAlign: 'center', 
    }, 
    title: {
        text: 'Despesas',
        align: 'center',
    },
    theme: {
        mode: 'light', 
        palette: 'palette1', 
        monochrome: {
            enabled: true,
            color: '#FF4D70',
            shadeTo: 'light',
            shadeIntensity: 0.65
        },
    },  
    plotOptions: chartOptionsCommon.value.plotOptions,
    tooltip: chartOptionsCommon.value.tooltip,
});

async function getReport(): Promise<void>  {
    isLoading.value = true;

    try {
        const resp = await ReportService.generate({
            type: 'category',
            graph: 'line',
            date_start: dayjs(filters?.start).format('YYYY-MM-DD'),
            date_end: dayjs(filters?.end).format('YYYY-MM-DD'),
            periodicity: filters?.periodicity || 'daily',
            predicted: predicted.value
        });

        results.value = resp.data.data;
        mapResults(resp.data.data);
        setTimeout(() => {
            const divToResize = document.getElementById('reportInfoMain');
            const divUserInfo = document.getElementById('divUserInfo');
            if (divUserInfo && divToResize) {
                if (seriesIncome.value.length && seriesExpense.value.length) {
                    const toSum = divToResize.clientHeight;
                    divToResize.style.cssText = `clip-path: inset(0px 100% 0px 0px); margin-top: -${toSum}px;`;
                } else {
                    divToResize.style.cssText = 'clip-path: inset(0px 100% 0px 0px); margin-top: -40vh;';
                }
            }
        }, 150);

        let currentCompany = store.getters['user/currentCompany'];
        reportInfo.value = `Relatorio-Categorias-${currentCompany.name}-${dayjs(filters?.start).format('DD/MM/YYYY')}-${dayjs(filters?.end).format('DD/MM/YYYY')}`;
        chartOptionsIncome.value = {
            ...chartOptionsIncome.value,
            ...{
                labels: [...labelsIncome.value]
            }
        };

        chartOptionsExpense.value = {
            ...chartOptionsExpense.value,
            ...{
                labels: [...labelsExpense.value]
            }
        };

        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

function mapResults(result: ReportResponse): any {
    seriesIncome.value = result.income.map( (item:CategoryWithSumAmount) => item.transactions_sum_amount ? Number(item.transactions_sum_amount) : 0);
    labelsIncome.value = result.income.map( (item:CategoryWithSumAmount) => item.name);

    seriesExpense.value = result.expense.map( (item:CategoryWithSumAmount) => item.transactions_sum_amount ? Number(item.transactions_sum_amount)*-1 : 0);
    labelsExpense.value = result.expense.map( (item:CategoryWithSumAmount) => item.name);
}

async function exportAsCSV() {
    const data: {}[] = [];
    let previous_balance = 0;
    let total_income = 0;
    let total_expense = 0;

    previous_balance = Number(sumBy(results.value.previous_balance, (inc: any) => Number(inc.transactions_sum_amount)));
    results.value.income.forEach(item => {
        item.transactions.forEach(transaction => {
            data.push({
                "Data": dayjs(transaction.billing_date).format('DD/MM/YYYY'),
                "Descrição": transaction.description,
                "Tipo": 'Receita',
                "Categoria": item.name,
                "Valor (R$)": Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat()
            });

            total_income += transaction.amount;
        });
    });

    results.value.expense.forEach(item => {
        item.transactions.forEach(transaction => {
            data.push({
                "Data": dayjs(transaction.billing_date).format('DD/MM/YYYY'),
                "Descrição": transaction.description,
                "Tipo": 'Despesa',
                "Categoria": item.name,
                "Valor (R$)": Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat()
            });

            total_expense += transaction.amount;
        });
    });

    let csvHeader = Object.keys(data[0]).join(';') + '\n';
    let csvBody = data.map(row => Object.values(row).join(';')).join('\n');
    csvBody = csvBody + "\n" +
    `;;;Total receitas;${Dinero({ amount: total_income,  currency: 'BRL' }).setLocale('pt-BR').toFormat()}
    ;;;Total despesas;${Dinero({ amount: total_expense,  currency: 'BRL' }).setLocale('pt-BR').toFormat()}`;

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvHeader + csvBody);
    hiddenElement.target = '_blank';
    hiddenElement.download = reportInfo.value + '.csv';
    hiddenElement.click();
}

async function exportAsPng() {
    try {
        const imagesToMerge: IImageToCompile[] = [];
        const charts = window.Apex._chartInstances;
        const income = charts.find((chart:any) => chart.id === 'vuechart-income');
        const expense = charts.find((chart:any) => chart.id === 'vuechart-expense');
        const reportInfoDiv = document.getElementById('reportInfo');
        const reportInfoDateDiv = document.getElementById('reportInfoDate');
        const reportIssueDateDiv = document.getElementById('reportIssueDate');
        const chartOptions = {scale: 1.5};
    
        let xPosition = 800;
        let yPosition = 50;
    
        imagesToMerge.push({ src: headerImage, x: xPosition, y: yPosition });
        xPosition = 120;
        yPosition += 150;
    
        if (reportInfoDateDiv && reportInfoDiv) {
            reportInfoDateDiv.innerHTML = `Perído: ${dayjs(filters?.start).format('DD/MM/YYYY')} - ${dayjs(filters?.end).format('DD/MM/YYYY')}`;
            await htmlToImage.toPng(reportInfoDiv, { backgroundColor: '#fff', pixelRatio: 1.8, width: 960, height: reportInfoDiv.clientHeight + 35}).then(function (dataUrl) {
                imagesToMerge.push({ src: dataUrl, x: xPosition, y: yPosition });
                if (reportInfoDiv) {
                    yPosition += Math.floor(reportInfoDiv.clientHeight) + 110;
                }
            }).catch(function (error: any) {
                Toast.fire({
                    icon: 'error',
                    title: `Erro ao exportar a categoria como PNG(1).`,
                    iconColor: 'white',
                });
            });
        }
    
        xPosition = 760;
    
        if (income != undefined) {
            let incomeImage = await income.chart.dataURI(chartOptions);
            imagesToMerge.push({ src: incomeImage.imgURI, x: xPosition, y: yPosition });
            yPosition += 518;
            xPosition = 110;
    
            const incomeReportImageDiv = document.getElementById('incomeReports');
            if (incomeReportImageDiv) {
                await htmlToImage.toPng(incomeReportImageDiv, { backgroundColor: '#fff', pixelRatio: 1.8, width: 960, height: incomeReportImageDiv.clientHeight + 35}).then(function (dataUrl) {
                    imagesToMerge.push({ src: dataUrl, x: xPosition, y: yPosition });
                    if (incomeReportImageDiv) {
                        yPosition += Math.floor(incomeReportImageDiv.clientHeight) + 418;
                    }
                }).catch(function (error: any) {
                    Toast.fire({
                        icon: 'error',
                        title: `Erro ao exportar a categoria como PNG(2).`,
                        iconColor: 'white',
                    });
                });
            }
        }
    
        if (expense != undefined) {
            xPosition = 760;
            let expenseImage = await expense.chart.dataURI(chartOptions);
            imagesToMerge.push({ src: expenseImage.imgURI, x: xPosition, y: yPosition });
            yPosition += 518;
            xPosition = 110;
    
            const expenseReportImageDiv = document.getElementById('expenseReports');
            if (expenseReportImageDiv) {
                await htmlToImage.toPng(expenseReportImageDiv, { backgroundColor: '#fff', pixelRatio: 1.8, width: 960, height: expenseReportImageDiv.clientHeight + 35}).then(function (dataUrl) {
                    imagesToMerge.push({ src: dataUrl, x: xPosition, y: yPosition });
                    if (expenseReportImageDiv) {
                        yPosition += Math.floor(expenseReportImageDiv.clientHeight) + 318;
                    }
                }).catch(function (error: any) {
                    Toast.fire({
                        icon: 'error',
                        title: `Erro ao exportar a categoria como PNG(3).`,
                        iconColor: 'white',
                    });
                });
            }
        }
    
        if (reportIssueDateDiv) {
            reportIssueDateDiv.innerHTML = `Relatório emitido em: ${dayjs().format('DD/MM/YYYY')}`;
            await htmlToImage.toPng(reportIssueDateDiv, { backgroundColor: '#fff', pixelRatio: 1.8, width: 960, height: reportIssueDateDiv.clientHeight + 35}).then(function (dataUrl) {
                imagesToMerge.push({ src: dataUrl, x: xPosition, y: yPosition });
                if (reportIssueDateDiv) {
                    yPosition += Math.floor(reportIssueDateDiv.clientHeight) + 20;
                }
            }).catch(function (error: any) {
                Toast.fire({
                    icon: 'error',
                    title: `Erro ao exportar a categoria como PNG(4).`,
                    iconColor: 'white',
                });
            });
        }
    
        const reportImage = await combine(imagesToMerge, {backgroundColor: '#fff', quality: 1, width: 1960, height: yPosition });
        
        var a = document.createElement("a");
        a.href = reportImage;
        a.download = `${reportInfo.value}.png`;
        a.click();
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: `Erro ao exportar a categoria como PNG(5).`,
            iconColor: 'white',
        });
    }
}

watch(predicted, async () => {
    getReport();
})

watchDebounced(
    filters, 
    async (newValue, oldValue) => {
        await getReport();
    },
    { debounce: 200, maxWait: 1000 },
);

onMounted(async () => {
    await getReport();
});

</script>