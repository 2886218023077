import dayjs from "dayjs";
import LastMonthFormatter from "../Formatters/LastMonthFormatter";
import Period from "../Period";
import IPeriodNavigator from "./INavigator";

export default class LastMonthNavigator implements IPeriodNavigator {

    private currentPeriod: Period;
    private key: string = 'monthly';
    private periodicity: string = 'monthly';

    constructor(
        private initial: Date,
        private interval: number = 1
    ) {
        this.currentPeriod = {
            start: dayjs(this.initial).startOf('month').subtract(this.interval-1, 'month').toDate(),
            end: this.initial,
        }
    }

    setPeriod(start: Date, end: Date): void {
        
    }

    reset() {
        this.currentPeriod = {
            start: dayjs(this.initial).startOf('month').subtract(this.interval-1, 'month').toDate(),
            end: this.initial,
        }

        return this.currentPeriod;
    }

    getCurrent(): Period {
        return this.currentPeriod;
    }

    getKey(): string {
        return this.key;
    }

    getLabel(): string {
        return `Últimos ${this.interval} meses`;
    }

    getPeriodicity(): string {
        return this.periodicity;
    }

    getInterval(): number {
        return this.interval;
    }

    getPrevious(): Period {
        const start = dayjs(this.currentPeriod.start).subtract(this.interval-1, 'month').startOf('month').toDate();
        const end = dayjs(this.currentPeriod.end).subtract(this.interval-1, 'month').endOf('month').toDate();
        
        this.currentPeriod = { start, end };
        return this.currentPeriod;
    }

    getNext(): Period {
        const start = dayjs(this.currentPeriod.start).add(this.interval-1, 'month').startOf('month').toDate();
        const end = dayjs(this.currentPeriod.end).add(this.interval-1, 'month').endOf('month').toDate();

       this.currentPeriod = { start, end };
       return this.currentPeriod;
    }

    format(): string {
        return new LastMonthFormatter().format(this.currentPeriod);
    }
}