<template>
    <span class="text-gray-400 text-xs  inline-block">
        <a href="#"  @click="toggle" class="flex align-center">
            <EyeIcon class="h-4 w-4 mr-2" v-if="showAmount" /> 
            <EyeOffIcon class="h-4 w-4 mr-2" v-else />
            {{ showAmount ? 'Esconder' : 'Mostrar' }} saldo
        </a>
    </span>
</template>

<script setup lang="ts">
    import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid'
    import { useStore } from '@/store';
import { computed } from '@vue/reactivity';

const store = useStore();

const showAmount = computed(() => store.getters['layout/showAmount'])

const toggle = () => {
    store.commit('layout/TOGGLE_AMOUNT')
}
</script>