import User from "@/types/User/User";
import { AxiosResponse } from "axios";
import api from "./http/api";

interface UserListResponse {
    data: Array<User>
}

class CompanyUserService {
    list() {
        return api({ auth: true, company: true })
            .get<UserListResponse>('/backoffice/v1/users', { params: {
                with: 'roles',
            }});
    }

    delete(id: string) {
        return api({ auth: true, company: true })
            .delete(`/backoffice/v1/users/${id}`);
    }
}

export default new CompanyUserService();