<template>
<div class="relative overflow-visible pb-6">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <div class="bg-white px-4 py-5  sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Categorias</h3>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">
                <button type="button" @click="createModal" class="inline-block items-center px-2 py-2 border border-transparent text-xs rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Nova categoria
                </button>
            </div>
        </div>
    </div>
    <div class="mx-6">
        <TabGroup @change="changeTab">
            <div  class="hidden sm:block">
            <TabList as="div" class="border-b border-gray-200">
                <nav class="-mb-px flex" aria-label="Tabs">
                    <Tab v-slot="{ selected }" as="template">
                        <a href="#" :class="[selected ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'focus:outline-none w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm']" :aria-current="selectedTab ? 'page' : undefined">
                            Despesas
                        </a>
                    </Tab>
                    <Tab v-slot="{ selected }" as="template">
                         <a href="#" :class="[selected ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'focus:outline-none w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm']" :aria-current="selectedTab ? 'page' : undefined">
                            Receitas
                        </a>
                    </Tab>
                </nav>
            </TabList>
            <TabPanels class="py-4">
                <TabPanel>
                    <div v-for="category in expenses" class="group block hover:bg-gray-50 border-b">
                        <div class="px-4 py-4 sm:px-6">
                            <div class=" flex items-center justify-between">
                                <div class="flex items-center text-sm font-normal text-gray-600 truncate">
                                    <TransactionKindBadge :kind="category.kind" class="mr-2" /> {{ category.name }} 
                                    <span v-if="category.default" class="text-xs text-gray-300 ml-2">Categoria padrão</span>
                                </div>
                                <div class="group-hover:opacity-100 opacity-20 text-sm flex items-center justify-between">
                                    <a v-if="!category.default" href="#" @click.prevent="() => editModal(category)" class="ml-2 text-indigo-500 hover:text-indigo-600">
                                       <PencilAltIcon class="inline-block w-4" /> Editar
                                    </a> 
                                    <a v-if="!category.default" href="#" @click.prevent="() => deleteConfirm(category)" class="ml-2 text-red-500 hover:text-red-600">
                                       <TrashIcon class="inline-block w-4" /> Excluir
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div v-for="category in incomes" class="group block hover:bg-gray-50 border-b">
                        <div class="px-4 py-4 sm:px-6">
                            <div class=" flex items-center justify-between">
                                <div class="flex items-center text-sm font-normal text-gray-600 truncate">
                                    <TransactionKindBadge :kind="category.kind" class="mr-2" /> {{ category.name }}
                                    <span v-if="category.default" class="text-xs text-gray-300 ml-2">Categoria padrão</span>
                                </div>
                                <div class="group-hover:opacity-100 opacity-20 text-sm flex items-center justify-between">
                                    <a v-if="!category.default" href="#" @click.prevent="() => editModal(category)" class="ml-2 text-indigo-500 hover:text-indigo-600">
                                       <PencilAltIcon class="inline-block w-4" /> Editar
                                    </a> 
                                    <a v-if="!category.default" href="#" @click.prevent="() => deleteConfirm(category)" class="ml-2 text-red-500 hover:text-red-600">
                                       <TrashIcon class="inline-block w-4" /> Excluir
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
            </TabPanels>
            </div>
        </TabGroup>

        <Modal :show="modalOpen" :static="true" @close="() => modalOpen = false" #default="{slotProps}">
            <Component 
                :is="componentsModal[modalMode]"
                :kind="kind"
                :category="selectedCategory"
                @canceled="slotProps.close(); canceled()" 
                @created="() => { slotProps.close(); created() }"
                @updated="() => { slotProps.close(); updated() }"
            />
        </Modal>
    </div>
</div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import { PencilAltIcon, TrashIcon } from '@heroicons/vue/solid'
import CategoryService from '@/services/CategoryService';
import Category from "@/types/Category/Category";
import TransactionKindEnum from '@/types/Transaction/TransactionKindEnum';
import CreateCategoryForm from '@/components/Categories/CreateCategoryForm.vue'
import EditCategoryForm from '@/components/Categories/EditCategoryForm.vue'
import Modal from '@/components/Modal.vue';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import TransactionKindBadge from '@/components/Transactions/TransactionKindBadge.vue';
import { Toast, DangerSwal } from '@/constants/swal-mixins'
import Swal from 'sweetalert2';

enum ModalModeEnum {CREATE = 'create', EDIT = 'edit'};
const modalOpen = ref(false);
const modalMode = ref(ModalModeEnum.CREATE);
const isLoading = ref(false);

const componentsModal = { 
    [ModalModeEnum.CREATE] : CreateCategoryForm,
    [ModalModeEnum.EDIT] : EditCategoryForm,
}

const selectedTab = ref(0);
const selectedCategory = ref<Category>();

const state = reactive({
    categories: [] as Category[],
});

const kind = computed(() => { 
    const obj = (selectedTab.value == 0) ? TransactionKindEnum.EXPENSE : TransactionKindEnum.INCOME;

    return TransactionKindEnum.toObject(obj)
})
const expenses = computed( () => state.categories.filter( (item: Category) => item.kind == 'expense'));
const incomes = computed( () => state.categories.filter( (item: Category) => item.kind == 'income'));

const createModal = () => {
    selectedCategory.value = {} as Category ;
    modalOpen.value = true
    modalMode.value = ModalModeEnum.CREATE
}

const editModal = (category: Category) => {
    selectedCategory.value = category;
    modalOpen.value = true
    modalMode.value = ModalModeEnum.EDIT
}

const deleteConfirm = async (category: Category) => {
    await DangerSwal.fire({
        icon: 'question',
        title: `Excluir categoria`,
        html: `Deseja realmente excluir a categoria <strong>${category.name}</strong>?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                if(category.id) {
                    try {
                        await CategoryService.delete(category.id);
                        deleted();
                        resolve(true)
                    } catch(error) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Erro ao excluir categoria',
                            iconColor: 'white',
                        })
                        reject(error)
                    }
                }
            })
        }
    })
}

const canceled = () => {
    selectedCategory.value = {name: '', kind: '', description: '', default: false}
}

const created = () => {
    Toast.fire({
        icon: 'success',
        title: `Categoria criada com sucesso`,
        iconColor: 'white',
    })

    loadCategories()
}

const updated = () => {
    Toast.fire({
        icon: 'success',
        title: `Categoria atualizada com sucesso`,
        iconColor: 'white',
    })

    loadCategories()
}

const deleted = () => {
    Toast.fire({
        icon: 'success',
        title: `Categoria excluída com sucesso`,
        iconColor: 'white',
    })

    loadCategories()
}


const changeTab = (index: number) => {
    selectedTab.value = index
}

const loadCategories = async () => {
    isLoading.value = true;
    try {
        let response = await CategoryService.list({paginate: false});
        state.categories = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

onMounted(async () => {
    await loadCategories();
})
</script>