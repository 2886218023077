<template>
    <TransitionRoot :show="isShowing" as="template">
        <Dialog :show="show" @close="setIsOpen" :unmount="false" class="relative z-50">
            <TransitionChild 
                appear
                enter="duration-300 ease-out" 
                enter-from="opacity-0" 
                enter-to="opacity-100"
                leave="duration-200 ease-in" 
                leave-from="opacity-100" 
                leave-to="opacity-0"
            >
                <!--backdrop-->
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity backdrop-blur-sm" />
                <div id="toast-modal"></div>
            </TransitionChild>

            <div class="z-50 fixed inset-0 overflow-y-auto">
                <div class="flex items-end sm:items-center justify-center min-h-full text-center sm:p-0">
                    <TransitionChild 
                        appear
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative bg-white rounded-lg text-left shadow-xl transform transition-all p-1 sm:w-full sm:max-w-lg">
                            <!--class="relative bg-white rounded-lg text-left shadow-xl transform transition-all w-2xl sm:p-6">-->
                            <button class="h-0 w-0 overflow-hidden hidden inline p-0 m-0"></button>
                            <div :key="1"><slot :slotProps="{open, close}"></slot></div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
    
</template>

<script setup lang="ts">
import { ref, toRef, watch } from 'vue'
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue'

const props = defineProps({
    show: {
        type: Boolean,
    },
    static: {
        type: Boolean,
        default: false
    }
})

const show = ref(false)
const isShowing = toRef(props, 'show');
const isStatic = toRef(props, 'static');
const emit = defineEmits(['open', 'close'])

/*watch(isShowing, () => {
    show.value = isShowing.value
});*/

const open = (value: boolean) => {
    show.value = value;
    emit('open')
}

const setIsOpen = (value: boolean) => {
    
    if(isStatic.value) {
        show.value = !value
        return;
    }

    emit('close')
}

const close = () => {
    show.value = false;
    emit('close')
}
</script>