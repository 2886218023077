import axios, { AxiosRequestConfig } from "axios";

const baseURL = import.meta.env.VITE_API_URL

const options: AxiosRequestConfig<any> = {
    baseURL
};

const http = axios.create(options);

http.interceptors.request.use(
    config => {
        /*
        * Here you can add a header with a JWT token, ensuring it will be
        * sent with ALL your requests.
        */
        return config;
    },
    error => Promise.reject(error),
);

http.interceptors.response.use(
    response => response,
    error => {
        /**
         * 
         */
        return Promise.reject(error);
    },
);

export default http;