import AccountCreate from "@/types/Account/AccountCreate";
import Account from "@/types/Account";
import { Axios, AxiosResponse } from "axios";
import api from "./http/api";


interface AccountFilter {
    name?: string,
    description?: string,
    paginate?: boolean,
    paginate_count?: number,
    sort?:string,
    order?:string
}

export interface AccountUpdate {
    name: string,
    description: string,
    opening_balance: number,
    bank_id?: string
}

class AccountService {

    get(id: string) {
        return api({ auth: true, company: true }).get<AxiosResponse>(`/backoffice/v1/accounts/${id}`);
    }

    list(params: AccountFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>('/backoffice/v1/accounts', { params });
    }

    create(account: AccountCreate) {
        console.log(account);
        return api({ auth: true, company: true }).post<AxiosResponse>('/backoffice/v1/accounts', {
            name: account.name,
            description: account.description,
            opening_balance: account.opening_balance,
            type: account.type,
            bank_id: account.bank_id
        });
    }

    update(id: string, account: AccountUpdate) {
        return api({ auth: true, company: true }).put<AxiosResponse>(`/backoffice/v1/accounts/${id}`, {
            name: account.name,
            description: account.description,
            opening_balance: account.opening_balance,
            bank_id: account.bank_id
        });
    }

    delete(id: string) {
        return api({ auth: true, company: true }).delete<AxiosResponse>(`/backoffice/v1/accounts/${id}`);
    }

}

export default new AccountService();