<template>
    <div>
        <Overlay :show="isLoading">
            <Spinner border-color="border-red-900" />
        </Overlay>

        <component :is="layout">
            <slot></slot>
        </component>
    </div>
</template>

<script setup lang="ts">
import { watch, shallowRef, ref, defineAsyncComponent } from 'vue';
import { computed } from '@vue/reactivity';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';
import DefaultLayoutVue from './DefaultLayout.vue';
import Spinner from '@/components/Spinner.vue';
import Overlay from '@/components/Overlay.vue';
import LoadingLayout from '@/components/LoadingLayout.vue';

const route = useRoute();
const store = useStore();
const isLoading = ref(false);
const layout = shallowRef(DefaultLayoutVue);
const newLayout = computed( () => store.getters['layout/current']);

/** Watch store */
watch(
    () => newLayout.value, 
    async () => {
        isLoading.value = true;

        /** ISSUE */
        layout.value = defineAsyncComponent( { 
            loader: () => import (`../layouts/${newLayout.value.path}.vue`),
            loadingComponent: LoadingLayout,
            delay: 200,
        });

        isLoading.value = false;
    }
);

/** Wacth route */
watch(
    () => route.meta,
    async value => {
        if(route.meta.layout) {
            store.commit('layout/SET_LAYOUT', route.meta.layout );
        }
    },
    { immediate: true }
);

store.commit('layout/REGISTER_LAYOUT', { name: 'auth', path: 'AuthLayout' });
store.commit('layout/REGISTER_LAYOUT', { name: 'dashboard', path: 'DashboardLayout' });
store.commit('layout/REGISTER_LAYOUT', { name: 'dashboard-user', path: 'DashboardUserLayout' });
</script>