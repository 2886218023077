<template>
<div v-if="invitesCount" class="relative bg-yellow-200">
    <div class="mx-auto max-w-7xl py-2 px-2">
        <div class="pr-16 sm:px-16 sm:text-center">
            <p class="text-yellow-600">
                <span class="md:hidden">Convites pendentes</span>
                <span class="hidden md:inline">Você possui {{invitesCount}} convite(s) aguardando resposta.</span>
                <span class="block sm:ml-2 sm:inline-block">
                <a href="#" @click="openModal" class="font-bold text-yellow-600 underline">
                    Ver convites
                </a>
                </span>
            </p>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
            <button type="button" class="flex rounded-md p-2  text-yellow-500 focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Dismiss</span>
                <XIcon class="h-4 w-4" aria-hidden="true" />
            </button>
        </div>
    </div>

    <Modal v-if="invitesCount" :show="modalOpen" :static="false" @close="() => modalOpen = false" #default="{slotProps}">
        <div class="relative overflow-visible p-0">
            <div  class="space-y-2 w-96">
                <div class="flex items-center px-4 mt-2">
                    <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Convites</h3>

                    <div class="ml-4 flex flex-shrink-0">
                        <a href="#" @click="slotProps.close" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close</span>
                            <XIcon class="h-4 w-4" aria-hidden="true" />
                        </a>
                    </div>
                </div>
                
                <div class="divide-y divide-gray-200">
                    <div class="flex justify-between items-center p-4" v-for="invite in invites" :key="invite.id" >
                        <div>
                            <p class="text-medium text-gray-600">{{ invite.company?.name }}</p>
                            <p class="text-sm text-gray-600">{{ invite.inviter?.email }}</p>   
                        </div>
                        <div class="flex gap-2">
                            <!-- <a href="#" class="text-red-600 hover:text-red-500">
                                <BanIcon class="h-5 w-5 "/>
                            </a> -->
                            <a :href="invite.url" target="_target" class="text-green-600 hover:text-green-500" title="Aceitar convite">
                                <CheckCircleIcon class="h-5 w-5"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-5">
                <div class="flex justify-center">
                    <button type="reset" @click="slotProps.close" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </Modal>
</div>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { computed } from '@vue/reactivity';
import { XIcon, CheckCircleIcon } from '@heroicons/vue/solid';
import UserInviteService from '@/services/UserInviteService';
import CompanyInvite from '@/types/CompanyInvite';
import Modal from '@/components/Modal.vue';

const isLoading = ref(false);
const modalOpen = ref(false);
const invites = ref<CompanyInvite[]>([]);
const invitesCount = computed( () => invites.value.length);

async function getInvites(): Promise<void> {
    isLoading.value = true;
    try {
        let response = await UserInviteService.list();
        invites.value = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

async function acceptInvite(url: string): Promise<void> {
    isLoading.value = true;
    try {
        let response = await UserInviteService.list();
        invites.value = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

async function openModal() {
    modalOpen.value = true;
}

onMounted(async() => {
    await getInvites();
});
</script>