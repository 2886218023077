<template>
     <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/fluxous-logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Confirmação de email</h2>
            <div class="mt-2 max-w-xl text-sm text-gray-500 text-center"></div>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div v-if="state.verified">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        <CheckCircleIcon class="h-10 inline text-green-400 align-center" aria-hidden="true" />
                        Email verificado com sucesso!
                    </h3>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Agora você pode fazer login na sua conta.</p>
                        <router-link tag="a" to="/login"  class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Entrar na minha conta
                        </router-link>
                    </div>
                </div>
                <div v-else>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        <ClockIcon out style="" class="h-10 inline text-red-400 align-center" aria-hidden="true" />
                        Link expirado
                    </h3>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Este link de confirmação de email não é mais válido.</p><br>
                    </div>
                </div>
            </div>

            <p class="mt-6 text-center text-sm text-gray-400">
                Já possuo uma conta. <router-link tag="a" to="/login"
                    class="link-effect text-indigo-600 hover:text-indigo-500 font-bold">
                    Quero entrar
                </router-link>
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import api from '@/services/http/api';
import { onMounted, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { CheckCircleIcon, ClockIcon } from '@heroicons/vue/outline';

const route = useRoute();

const state = reactive({
    id: route.query.id,
    hash: route.query.hash,
    expires: route.query.expires,
    signature: route.query.signature,
    verified: false,
});

onMounted(async () => {
    const { expires, signature } = state;
    try {
        await api({ auth: false }).put(`/backoffice/v1/auth/email/verify/${state.id}/${state.hash}`, null, {
            params: {
                expires, signature
            }
        });

        state.verified = true;
    } catch (error: any) {
        state.verified = false;
    }

})
</script>