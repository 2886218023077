<template>
<div class="relative overflow-visible p-0">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    
    <div class="space-y-4 w-full p-4">
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Buscar Lançamento</h3>
    
            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="cancel" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>

        <div>
            <TransactionFilters @period="setPeriod"/>
        </div>
    
        <!--FILTERS-->
        <div class="pb-4 pt-2 px-4 -mx-5" :class="[isFiltered ? 'bg-orange-50' : 'bg-gray-50']">
            <div>
                <ul role="list" class="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:flex items-end justify-between">
                    <li class="rounded-lg">
                        <label class="block text-xs font-medium text-gray-400">
                            Descrição
                        </label>
                        <input
                            v-model="filter.description"
                            id="description"
                            name="description"
                            type="text"
                            autocomplete="off"
                            :required="true"
                            placeholder="Descrição"
                            class="mt-1 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                        />
                    </li>
                    <li class="rounded-lg flex items-end">
                        <button
                            type="button"
                            @click.prevent="() => clearFilter()"
                            :disabled="!isFiltered"
                            class="mr-2 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition"
                            :class="[isFiltered ? 'bg-orange-400 hover:bg-orange-500' : 'bg-gray-400 hover:bg-gray-400 opacity-60 cursor-not-allowed']"
                        >
                            <XIcon class="h-4 w-4" />
                        </button>
                        <button type="button"
                                @click.prevent="() => filterResults(true)"
                                :disabled="isLoading"
                                class="inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition"
                                :class="[isFiltered ? 'bg-orange-400 hover:bg-orange-500' : 'bg-gray-400 hover:bg-gray-500']"
                        >
                            <FilterIcon class="h-4 w-4" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <!--/FILTERS-->
        
        <!--TRANSACTIONS-->
        <div class="mt-4 py-2 px-4 -mx-5">
            <div v-if="transactions.length">
                <div class="flex flex-col flex-1 text-xs divide-y divide-gray-200">
                    <div v-for="transaction in transactions" :key="transaction.id"
                         class="flex space-x-1 relative justify-between w-min-full py-2 px-2 items-center hover:bg-gray-100 transition duration-200">
                        <div class="flex items-center font-normal text-gray-400 w-1/2">
                            <TransactionKindBadge :kind="transaction.kind" class="mr-2" />
                            
                            <span class="flex-1 text-gray-600">{{ transaction.description }}</span>
    <!--                        <span v-if="transaction.is_recurring && transaction.recurrence?.limit_by == 'COUNT'" class="text-xs text-gray-400">
                                ({{ transaction.recurrence_index }}/{{ transaction.recurrence_total }})
                            </span>-->
                        </div>
    
                        <div class="flex flex-1 w-24 justify-end" title="data">
                            {{ dayjs(transaction.billing_date).format("DD/MM/YYYY") }}
                        </div>
    <!--                    <div class="flex flex-1 w-24 justify-end" title="Conta">
                            <RecurringIcon :type="transaction.recurrence?.limit_by" class="text-gray-400 mr-2" />
                            <span v-if="transaction.origin_account && transaction.target_account">
                                De: <router-link :to="{name:'company.accounts.detail', params: {id: transaction.origin_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                    {{ transaction.origin_account.name }}
                                </router-link><br />
                                Para: <router-link :to="{name:'company.accounts.detail', params: {id: transaction.target_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                    {{ transaction.target_account.name }}
                                </router-link>
                            </span>
                            <span v-else-if="transaction.origin_account">
                                <router-link :to="{name:'company.accounts.detail', params: {id: transaction.origin_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                    {{ transaction.origin_account.name }}
                                </router-link>
                            </span>
                            <span v-else-if="transaction.target_account">
                                <router-link :to="{name:'company.accounts.detail', params: {id: transaction.target_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                    {{ transaction.target_account.name }}
                                </router-link>
                            </span>
                        </div>-->
    
                        <div class="flex flex-1 justify-end items-center whitespace-nowrap" title="Categoria">
                            <CategoryBadge :kind="transaction.kind" :text="transaction.category?.name" />
                        </div>
    
                        <div class="flex-1 w-24 text-right justify-end">
                            <span class="whitespace-nowrap" :class="[transaction.amount < 0 ? 'text-red-500' : 'text-blue-500']">
                                {{ Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>

                        <a v-if="transaction.ofx_reference" href="#" title="Transação já conciliada anteriormente">
                            <ExclamationCircleIcon class="h-6 w-6 text-yellow-500 cursor-default" />
                        </a>

                        <a v-else href="#" title="Conciliar com esta transação" @click="() => setSelected(transaction)">
                            <PlusCircleIcon class="h-6 w-6 text-blue-500" />
                        </a>
                    </div>
                </div>
            </div>
            <!--/TRANSACTIONS-->
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import dayjs from "dayjs";
import Transaction from "@/types/Transaction";
import TransactionService from "@/services/TransactionService";
import {Toast} from "@/constants/swal-mixins";
import CategoryBadge from '@/components/Categories/CategoryBadge.vue';
import TransactionKindBadge from '@/components/Transactions/TransactionKindBadge.vue';
import TransactionFilters from '@/components/Transactions/TransactionFilters.vue';
import Dinero from 'dinero.js';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

import {XIcon} from '@heroicons/vue/solid';

import {ExclamationCircleIcon, FilterIcon, PlusCircleIcon} from '@heroicons/vue/outline';
import TransactionType from "@/types/Transaction/TransactionType";

const emit = defineEmits(['chosen', 'canceled', 'error'])

const props = defineProps({
    type: String,
    date: String
})

interface Filter {
    date_start: Date,
    date_end: Date,
    description?: string|undefined,
    type?: string|undefined,
    limit_by?: string,
    limit?: number,
    page?: number|undefined,
    last_page?: number|undefined,
    from?: number|undefined,
    to?: number|undefined,
    per_page?: number|undefined,
    total?: number|undefined,
}

const isLoading = ref(false);
const isFiltered = ref(false);

const filter = ref<Filter>({
    date_start: dayjs().startOf('month').toDate(),
    date_end:  dayjs().endOf('month').toDate(),
    description: '',
    type: undefined,
    limit_by: undefined,
    limit: 10,
    page: 1,
    last_page: undefined,
    from: undefined,
    to: undefined,
    per_page: 15,
    total: 25,
});

const transactions = ref<Transaction[]>([]);

const page = ref(1);

const initialDate = ref(new Date());
const endDate = ref(new Date());

const loadTransactions = async () => {
    isLoading.value = true;

    try {
        let response = await TransactionService.list({
            billing_date_start: dayjs(filter.value.date_start).format('YYYY-MM-DD'),
            billing_date_end: dayjs(filter.value.date_end).format('YYYY-MM-DD'),
            description: filter.value.description,
            type: filter.value.type,
            limit_by: filter.value.limit_by,
            load_category: true,
            load_account: true,
            load_recurrence: true,
            sort: 'billing_date',
            paginate: true,
            page: page.value,
            per_page: 10
        });

        transactions.value = response.data.data;
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao carregar os lançamentos!',
            iconColor: 'white',
        })
    }
    
    isLoading.value = false;
}

const setPeriod = async (period: any) => {
    filter.value.date_start = period.date_start;
    filter.value.date_end = period.date_end;
    initialDate.value = period.date_start;
    endDate.value = period.date_end;

    await filterResults(false);
}

const filterResults = async (filtered = false) =>
{
    isFiltered.value = filtered;

    await loadAllAmounts(true)
}

const clearFilter = async () =>
{
    isFiltered.value = false;

    filter.value.description = '';
    filter.value.date_start = initialDate.value;
    filter.value.date_end = endDate.value;

    await loadAllAmounts(true)
}

const loadAllAmounts = async (refresh?: boolean) => {
    page.value = 1;

    if (refresh) {
        isLoading.value = true;
        
        transactions.value = [];
        
        await loadTransactions();

        isLoading.value = false;
    }
}

const setSelected = (transaction: TransactionType) => {
    emit('chosen', transaction);
}

const cancel = () => {
    emit('canceled');
}

onMounted( async () => {
    await loadTransactions();
})
</script>
