import { Axios, AxiosResponse } from "axios";
import api from "./http/api";


interface AuditFilter {
    user_id?: string,
    user_type?: string,
    event?: string,
    auditable_id?: string,
    auditable_type?: string,
    company?: string,
    paginate?: boolean,
    paginate_count?: number,
    sort?:  string,
    order?: string
}

class AuditService {

    list(params: AuditFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>('/backoffice/v1/audits', { params });
    }
}

export default new AuditService();