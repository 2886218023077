import Credential from '@/types/Auth/Credential';
import Register from '@/types/Auth/Register';
import Token from '@/types/Auth/Token';
import { AxiosResponse } from 'axios';
import ActionEnum from './action-enum';
import MutationEnum from "./mutation-enum";

export enum Status {
    LOGIN_PROGRESS = 'auth.login.progress',
    LOGIN_SUCCESS = 'auth.login.success',
    LOGIN_ERROR = 'auth.login.error',
    LOGOUT_PROGRESS = 'auth.logout.progress',
    LOGOUT_SUCCESS = 'auth.logout.success',
    LOGOUT_ERROR = 'auth.logout.error',
    REGISTER_PROGRESS = 'auth.register.progress',
    REGISTER_SUCCESS = 'auth.register.success',
    REGISTER_ERROR = 'auth.register.error',
}

export interface StateType {
    token: Token | null,
    remember: boolean,
    status: string
}

export type Mutations<S = StateType> = {
    [MutationEnum.LOGIN_STATUS](state: S, payload: Status): void,
    [MutationEnum.LOGOUT_STATUS](state: S, payload: Status): void,
    [MutationEnum.REGISTER_STATUS](state: S, payload: Status): void,
    [MutationEnum.TOKEN_STORE](state: S, payload: Token): void,
    [MutationEnum.TOKEN_CLEAR](state: S): void,
    [MutationEnum.REMEMBER_SAVE](state: S, payload: boolean): void
}

export interface GettersTypes<StateType> {
    isAuthenticated(state: StateType): boolean;
    loginInProgress(state: StateType): boolean;
    token(state: StateType): Token | null;
    remember(state: StateType): boolean;
}

export interface ActionsTypes {
    [ActionEnum.AUTH_LOGIN]({commit }: any, user: Credential): Promise<AxiosResponse<Token>>;
    [ActionEnum.AUTH_LOGOUT]({commit }: any, user: Credential): Promise<void>;
    [ActionEnum.AUTH_REGISTER]({commit }: any, user: Register): Promise<AxiosResponse>;
}