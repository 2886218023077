<template>
    <div class="relative" id="divUserInfo">
        <Overlay :show="isLoading">
            <Spinner border-color="border-gray-500" />
        </Overlay>

        <div class="rounded-lg bg-white shadow">
            <div class="p-4">
                <h3 class="text-xl font-normal text-gray-500">{{ state.imported ? 'Conciliação bancária' : 'Importar Lançamentos' }}</h3>
                <span v-if="state.imported" class="block text-xs font-medium text-gray-400 pt-1">Período: de {{ dayjs(state.data.start_date).format("DD/MM/YYYY") }} a {{ dayjs(state.data.end_date).format("DD/MM/YYYY") }}</span>
            </div>

            <form v-if="!state.imported" class="space-y-4 w-full p-4" @submit.prevent="onSubmit" novalidate>
                <Listbox as="div" v-model="state.target_account">
                    <ListboxLabel :class="[v$.target_account.$error ? 'text-red-500' : 'text-gray-700']" class="block text-sm font-medium">Conta</ListboxLabel>
                    <div class="mt-1 relative">
                        <ListboxButton
                            class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            :class="[
                                v$.target_account.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                            ]"
                        >
                            <span class="block truncate" :class="[state.target_account?.id ? 'text-gray-700' : 'text-gray-400']">
                                {{  state.target_account?.id ? state.target_account?.name : 'Selecione uma conta' }}
                            </span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </ListboxButton>

                        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                <ListboxOption as="template" v-for="account in state.accounts" :key="account.id" :value="account" v-slot="{ active, selected }">
                                    <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-8 pr-4']">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                            {{ account.name }}
                                        </span>
                                        <span class="block truncate text-xs text-gray-400">{{ account.description }}</span>

                                        <span v-if="state.target_account?.id === account.id" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </li>
                                </ListboxOption>
                            </ListboxOptions>
                        </transition>

                        <div v-if="v$.target_account.$error" class="text-xs text-red-600">
                            <div>{{ v$.target_account.$errors[0].$message }}</div>
                        </div>
                    </div>
                </Listbox>

                <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    <div class="pt-5 pb-6">
                        <div class="flex flex-col items-center justify-center" v-if="! state.file">
                            <CloudUploadIcon class="mb-2 text-gray-400 h-12 w-12" aria-hidden="true" />
                            
                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Selecione o arquivo .ofx do seu banco</span></p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">ou arraste ele para cá</p>
                        </div>
                        <div class="flex flex-col items-center justify-center" v-else>
                            <CheckCircleIcon class="mb-2 text-green-400 h-12 w-12" aria-hidden="true" />

                            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Arquivo .OFX anexado com sucesso</span></p>
                            <p class="text-xs text-gray-500 dark:text-gray-400">Clique para substituir</p>
                        </div>
                    </div>
                    <input id="dropzone-file" @input="handleFileInput($event)" type="file" class="hidden" />
                </label>

                <button type="submit" :disabled="isLoading" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <Spinner class="mr-2" v-if="isLoading"/> Realizar importação
                </button>
            </form>
            <div v-else>
                <div class="bg-gray-50 space-y-2 p-4">
                    <div class="flex gap-2" v-for="transaction in state.data.transactions">
                        <div class="bg-white rounded-lg shadow flex-1 p-4">
                            <div class="flex">
                                <div class="flex-1 mr-6">
                                    <span :class="[transaction.memo.length > 100 ? 'text-sm' : 'text-md']">
                                        {{ transaction.memo }}
                                    </span>
                                </div>

                                <span :class="[transaction.amount > 0 ? 'text-green-500' : 'text-red-500']">{{ Dinero({ amount: parseInt((transaction.amount * 100).toString()), currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                            </div>

                            <div class="text-gray-400 text-sm mt-3">
                                {{ dayjs(transaction.date).format("DD/MM/YYYY") }}
                            </div>
                        </div>

                        <div class="self-center">
                            <ArrowCircleRightIcon v-if="! transaction.reference" class="mb-2 text-indigo-400 h-8 w-8" aria-hidden="true" />
                            
                            <a href="#" v-else title="Desvincular lançamentos" class="group" @click.prevent="() => confirmUnlink(transaction)">
                                <CheckCircleIcon class="mb-2 text-green-400 h-8 w-8 block group-hover:hidden" aria-hidden="true" />

                                <MinusCircleIcon class="mb-2 text-red-400 h-8 w-8 hidden group-hover:block" aria-hidden="true" />
                            </a>
                        </div>
                        
                        <div v-if="transaction.reference" class="bg-white rounded-lg shadow flex-1 p-4">
                            <div class="flex">
                                <div class="flex-1 mr-6">
                                    <span :class="[transaction.reference?.description?.length ?? 0 > 100 ? 'text-sm' : 'text-md']">
                                        {{ transaction.reference?.description }}
                                    </span>
                                </div>

                                <span :class="[transaction.amount > 0 ? 'text-green-500' : 'text-red-500']">{{ Dinero({ amount: transaction.reference?.amount, currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                            </div>

                            <div class="flex justify-between">
                                <div class="text-gray-400 text-sm mt-3">
                                    {{ dayjs(transaction.reference?.billing_date).format("DD/MM/YYYY") }}
                                </div>

                                <a href="#" @click.prevent="() => confirmDelete(transaction)" class="flex items-center justify-center text-sm group">
                                    <TrashIcon class="w-4 h-4 text-gray-400 mr-2 group-hover:text-red-500" />
                                    <span class="group-hover:text-red-500">Excluir</span>
                                </a>
                            </div>
                        </div>

                        <div v-else class="bg-white rounded-lg shadow flex flex-1 items-center justify-end space-x-1 p-4">
                            <Menu as="div" class="relative">
                                <div>
                                    <MenuButton class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                                        <span class="sr-only">Abrir opções</span>
                                        <span class="flex text-xs"><PlusCircleIcon class="h-4 w-4 mr-2" aria-hidden="true" /> Novo lançamento</span>
                                    </MenuButton>
                                </div>

                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                    <MenuItems class="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div class="py-1">
                                            <MenuItem v-if="transaction.amount > 0" v-slot="{ active }">
                                                <a href="#" @click.prevent="() => openModal('income-create', transaction)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                    Receita
                                                </a>
                                            </MenuItem>
                                            <MenuItem v-else v-slot="{ active }">
                                                <a href="#" @click.prevent="() => openModal('expense-create', transaction)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                    Despesa
                                                </a>
                                            </MenuItem>
                                            <MenuItem v-slot="{ active }">
                                                <a href="#" @click.prevent="() => openModal('transference-create', transaction)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                    Transferência
                                                </a>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </transition>
                            </Menu>
                            
                            <button @click.prevent="() => openModal('search', transaction)" class="flex py-2 px-4 border border-transparent shadow-sm text-xs rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                <LinkIcon class="h-4 w-4 mr-2" aria-hidden="true" /> Conciliar com existente
                            </button>
                        </div>
                        
                    </div>
                </div>

                <div class="p-4">
                    <button @click="() => router.push({name: 'company.transactions.index'})" :disabled="isLoading" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        <Spinner class="mr-2" v-if="isLoading"/> Concluir importação
                    </button>

                    <!--<button @click="importAgain()" :disabled="isLoading" class="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <Spinner class="mr-2" v-if="isLoading"/> Importar outro arquivo
                    </button>-->
                </div>
            </div>
        </div>

        <Modal :show="modalOpen" @close="() => modalOpen = false" :static="true" #default="{slotProps}">
            <Component
                :is="modalComponent"
                :account="state.target_account.id"
                :description="state.selectedOFXTransaction.memo"
                :amount="Math.abs(state.selectedOFXTransaction.amount) * 100"
                :date="state.selectedOFXTransaction.date"
                :type="state.selectedOFXTransaction.type"
                :ofx_reference="state.selectedOFXTransaction.id"
                :transaction="state.selectedTransaction.id"
                @created="(transaction: Transaction) => { slotProps.close(); onCreated(transaction); }"
                @chosen="(transaction: Transaction) => { slotProps.close(); onChosen(transaction); }"
                @unlinked="() => { slotProps.close(); onUnlinked(); }"
                @deleted="() => { slotProps.close(); onDeleted(); }"
                @canceled="slotProps.close()"
                @error="(error: any) => { slotProps.close(); onError(error); }"
            />
        </Modal>
    </div>
</template>

<script setup lang="ts">
import {onMounted, reactive, ref, shallowRef} from 'vue';
import {computed} from '@vue/reactivity';
import ConfirmDeleteTransactionForm from '../../../components/Transactions/ConfirmDeleteTransactionForm.vue';
import ConfirmUnlinkOfxTransactionForm from '../../../components/Transactions/ConfirmUnlinkOfxTransactionForm.vue';

import {
    ArrowCircleRightIcon,
    CheckCircleIcon,
    CheckIcon,
    LinkIcon,
    MinusCircleIcon,
    PlusCircleIcon,
    SelectorIcon,
    TrashIcon
} from '@heroicons/vue/solid';

import {CloudUploadIcon} from '@heroicons/vue/outline';

import {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from '@headlessui/vue'

import dayjs from 'dayjs'
import Dinero from 'dinero.js'

import Account from '@/types/Account';
import AccountService from '@/services/AccountService';
import OFXService from '@/services/OFXService';
import {Toast} from '@/constants/swal-mixins';

import Spinner from '@/components/Spinner.vue';
import Overlay from '@/components/Overlay.vue';
import Category from '@/types/Category/Category';
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import {AxiosError} from "axios";
import OFXAccount from "@/types/OFXAccount";
import CreateExpense from "@/components/Transactions/CreateExpense.vue";
import CreateIncome from "@/components/Transactions/CreateIncome.vue";
import CreateTransference from "@/components/Transactions/CreateTransference.vue";
import Modal from '@/components/Modal.vue';
import Transaction from "@/types/Transaction";
import OFXTransaction from "@/types/OFXTransaction";
import TransactionService from "@/services/TransactionService";
import Search from "@/pages/Company/Transactions/Search.vue";
import {useRouter} from "vue-router";
import {forEach} from "lodash";

interface ModalComponentsType {
    key: string,
    component: any
}

const isLoading = ref(false);

const rules = computed(() => ({
    target_account: {
        required: helpers.withMessage('Selecione uma conta', required),
    },
}));

interface Filter {
    date_start: Date,
    date_end: Date,
    account_id?: string|null,
    paginate?: Boolean,
}

interface AccountSelect {
    id?: string|null,
    name?: string
}

const reportInfo = ref<string>("");

const modalComponents: ModalComponentsType[] = [
    { key: "expense-create", component: CreateExpense },
    { key: "income-create", component: CreateIncome },
    { key: "transference-create", component: CreateTransference },
    { key: "search", component: Search },
    { key: "confirm-delete", component: ConfirmDeleteTransactionForm },
    { key: "confirm-unlink", component: ConfirmUnlinkOfxTransactionForm },
];

const modalComponent = shallowRef(CreateExpense);

const modalOpen = ref(false);

const state = reactive({
    id: '',
    accounts: [] as Account[],
    transactions: [] as Transaction[],
    target_account: {} as AccountSelect,
    file: null,
    imported: false,
    data: {} as OFXAccount,
    selectedTransaction: {} as Transaction,
    selectedOFXTransaction: {} as OFXTransaction,
});

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const router = useRouter();

const importOFX = async () => {
    isLoading.value = true;
    
    let formData = new FormData();
    formData.append('target_account_id', state.target_account.id ?? '');
    formData.append('upload', state.file ?? '');

    try {
        let response = await OFXService.process(formData);

        state.data = response.data.data;
        
        state.imported = true;

        await loadTransactions();
    } catch (error: any | AxiosError) {
        $externalResults.value = { ...error?.response.data.errors };
    }
    
    isLoading.value = false;
}

const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await importOFX();
}

const importAgain = () => {
    state.imported = false;
    state.target_account = {};
    state.file = null;
}

const loadAccounts = async () => {
    try {
        let response = await AccountService.list({paginate: false})
        
        state.accounts = response.data.data;
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao carregar as contas!',
            iconColor: 'white',
        })
    }
}

const loadTransactions = async () => {
    try {
        let response = await TransactionService.list({
            billing_date_start: dayjs(state.data.start_date).format('YYYY-MM-DD'),
            billing_date_end: dayjs(state.data.end_date).format('YYYY-MM-DD'),
            account_id: state.target_account.id,
            conciliated: true,
            paginate: false,
        })
        
        state.transactions = response.data.data;

        state.transactions.forEach(transaction => {
            state.data.transactions.forEach(ofx => {
                if (ofx.id === transaction.ofx_reference) {
                    ofx.reference = transaction;
                }
            });
        });
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao carregar as contas!',
            iconColor: 'white',
        })
    }
}

const handleFileInput = (event: any) => {
    state.file = event.target.files[0];
}

const setSelectedTransaction = (value: Transaction) => {
    state.selectedTransaction = value
}

const setSelectedOFXTransaction = (value: OFXTransaction) => {
    state.selectedOFXTransaction = value
}

const setModalIsOpen = (value: boolean) => {
    modalOpen.value = value
}

const setModalComponent = (value: string) => {
    modalComponent.value = modalComponents.find(component => component.key == value)?.component
}

const openModal = (value: string, transaction: OFXTransaction) => {
    setSelectedOFXTransaction(transaction);
    setModalComponent(value);
    setModalIsOpen(true);
}

const onCreated = async (transaction: Transaction) => {
    state.selectedOFXTransaction.reference = transaction
}

const onChosen = async (transaction: Transaction) => {
    isLoading.value = true;

    try {
        await TransactionService.conciliate(transaction.id, {
            ofx_reference: state.selectedOFXTransaction.id
        })
        
        state.selectedOFXTransaction.reference = transaction

        Toast.fire({
            icon: 'success',
            title: `Transação conciliada com sucesso`,
            iconColor: 'white',
        })
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: `Erro ao conciliar a transação`,
            iconColor: 'white',
        })
    }

    isLoading.value = false;
}

const onDeleted = async () => {
    state.selectedOFXTransaction.reference = null
}

const onUnlinked = async () => {
    state.selectedOFXTransaction.reference = null
}

const onError = (error: any = 'criar') => {
    Toast.fire({
        icon: 'error',
        title: `Erro ao ${error} a transação`,
        iconColor: 'white',
    })
}

const confirmDelete = (transaction: OFXTransaction) => {
    setSelectedOFXTransaction(transaction);
    setSelectedTransaction(transaction.reference!);
    setModalComponent('confirm-delete');
    setModalIsOpen(true);
}

const confirmUnlink = (transaction: OFXTransaction) => {
    setSelectedOFXTransaction(transaction);
    setSelectedTransaction(transaction.reference!);
    setModalComponent('confirm-unlink');
    setModalIsOpen(true);
}

onMounted(async () => {
    isLoading.value = true;
    await loadAccounts();
    isLoading.value = false;
})

</script>
