<template>
<main class="container max-w-5xl mx-auto">
    <div class="bg-white px-4 py-5 sm:px-6 shadow sm:rounded-md sm:overflow-hidden bg-white+">
        
        <div class="flex items-center w-full">
            <div class="self-start w-1/3">
                <h3 class="text-xl font-normal text-gray-500">Relatórios</h3>
            </div>

            <div class="w-2/3">
                <PeriodNavigator :navigators="navigators" @changed="handleChangedPeriod" />
            </div>
        </div>

        <div class="flex gap-4 py-2 border-y my-4 text-sm">
            <router-link 
                exact-active-class="text-gray-700 font-semibold"
                class="text-indigo-600"
                tag="a" 
                :to="{ name: 'company.reports.income-expense',  query }"
            >
                Receita x Despesas
            </router-link>
            <router-link 
                exact-active-class="text-gray-700 font-semibold"
                class="text-indigo-600"
                tag="a" 
                :to="{ name: 'company.reports.categories', query}"
            >
                Categoria
            </router-link>
            <router-link 
                exact-active-class="text-gray-700 font-semibold"
                class="text-indigo-600"
                tag="a" 
                :to="{ name: 'company.reports.accounts', query}"
            >
                Contas
            </router-link>
        </div>

        <section>
            <router-view></router-view>
        </section>

    </div>
</main>
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';
import dayjs from 'dayjs';
import PeriodNavigator, { PeriodEventProp } from '@/components/Shared/PeriodNavigator/PeriodNavigator.vue';
import INavigator from '@/components/Shared/PeriodNavigator/Navigators/INavigator';
import DayNavigator from '@/components/Shared/PeriodNavigator/Navigators/DayNavigator';
import MonthNavigator from '@/components/Shared/PeriodNavigator/Navigators/MonthNavigator';
import LastMonthNavigator from '@/components/Shared/PeriodNavigator/Navigators/LastMonthNavigator';
import WeekNavigator from '@/components/Shared/PeriodNavigator/Navigators/WeekNavigator';
import YearNavigator from '@/components/Shared/PeriodNavigator/Navigators/YearNavigator';
import CustomNavigator from '@/components/Shared/PeriodNavigator/Navigators/CustomNavigator';
import { IFilter, FilterKey } from '@/types/IFilter';

const query = ref({});
const filters = inject('Filter') as IFilter;

if (!filters) {
  throw new Error(`Could not resolve ${FilterKey.description}`);
}

const navigators: Array<INavigator> = [
    new DayNavigator(dayjs().toDate(), 1),
    new WeekNavigator(dayjs().toDate(), 1),
    new MonthNavigator(dayjs().toDate(), 1),
    new LastMonthNavigator(dayjs().toDate(), 3),
    new LastMonthNavigator(dayjs().toDate(), 6),
    new YearNavigator(dayjs().toDate(), 1),
    new CustomNavigator(dayjs().toDate(), dayjs().toDate(), 1, true)
];

async function handleChangedPeriod(payload: PeriodEventProp) {
    filters.start = dayjs(payload.start).format('YYYY-MM-DD');
    filters.end = dayjs(payload.end).format('YYYY-MM-DD');
    filters.periodicity = payload.periodicity;
    filters.interval = payload.interval;

    query.value = filters;
}
</script>