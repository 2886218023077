import Company from "@/types/Company";
import { AxiosResponse } from "axios";
import api from "./http/api";

interface ProfileUpdate {
    name?: string,
    email?:  string,
}

interface PasswordReset {
    current_password: string,
    password: string,
    password_confirmation: string
}

class UserService {

    info() {
        return api({ auth: true }).get('/backoffice/v1/user/info');
    }

    companies(): Promise<AxiosResponse> {
        return api({ auth: true }).get('/backoffice/v1/user/companies');
    }

    tokens() {
        return api({ auth: true }).get('/backoffice/v1/user/tokens');
    }

    permissions() {
        return api({ auth: true }).get('/backoffice/v1/user/permissions');
    }

    updateProfile(params: ProfileUpdate) {
        return api({ auth: true }).put('/backoffice/v1/user/profile', {...params});
    }

    changeCurrentCompany(id: string) {
        return api({ auth: true }).put('/backoffice/v1/user/companies/switch', { id });
    }

    changePassword(params: PasswordReset) {
        return api({ auth: true }).put('/backoffice/v1/user/password/reset', { ...params });
    }
}

export default new UserService();