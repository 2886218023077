<template>
<div class="relative overflow-visible pb-6">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="text-gray-500 px-2 pb-4">
        <label for="predicted-checkbox" class="text-xs">
            <Checkbox v-model="predicted" class="mr-2" id="predicted-checkbox"/>
            Considerar lançamentos não pagos
        </label>
    </div>

    <div>
        <VueApexCharts height="400px" type="line" :options="chartOptions" :series="series" class="p-0 m-0"/>
    </div>
    <div class="mt-4">
        <h2>Detalhamento</h2>
        
        <div>
            <table class="min-w-full divide-y divide-gray-100">
                <thead>
                    <tr>
                        <th scope="col" class="py-3.5 px-0 text-left text-xs font-normal text-gray-500">Data</th>
                        <td scope="col" class="py-3.5 px-0 text-right text-xs font-normal text-gray-500">Receita</td>
                        <td scope="col" class="py-3.5 px-0 text-right text-xs font-normal text-gray-500">Despesa</td>
                        <td scope="col" class="py-3.5 px-0 text-right text-xs font-normal text-gray-500">Saldo</td>
                        <td scope="col" class="py-3.5 px-0 text-right text-xs font-normal text-gray-500">Saldo Acumulado</td>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-200">
                    <tr>
                        <td colspan="6" scope="col" 
                            class="py-2 text-right text-xs font-normal text-gray-500 opacity-60"
                        >
                            <span class="px-2">Saldo anterior:</span>
                            <span class="text-gray-500">
                                {{ Dinero({ amount: previousBalance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>    
                        </td>
                    </tr>
                    <tr v-for="(result, index, key) in resultsTable" :key="index" class="py-2">
                        <td class="py-2 text-sm text-gray-500">{{ index }}</td>
                        <td class="py-2 text-sm text-right text-green-700">{{ result.incomesLineCurrency }}</td>
                        <td class="py-2 text-sm text-right text-red-500">{{ result.expensesLineCurrency }}</td>
                        <td class="py-2 text-sm text-right"
                            :class="{'text-red-500': result.balanceLine < 0, 'text-blue-500': result.balanceLine >= 0}"
                        >
                            {{ result.balanceLineCurrency}}
                        </td>
                        <td class="py-2 text-sm text-right text-gray-500">
                            {{ Dinero({ amount: result.balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>    
</template>

<script setup lang="ts">
import { onMounted, ref , watch, inject } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import ReportService from '@/services/ReportService'
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import { map, sumBy } from 'lodash';
import { ApexOptions } from 'apexcharts';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import Checkbox from '@/components/Shared/Checkbox.vue';
import { watchDebounced } from '@vueuse/shared';
import { IFilter } from '@/types/IFilter';
import {store} from '@/store';

interface Transaction {
    amount: number; 
    billing_date: string;
    is_overdue: boolean;
    is_payable: Boolean;
    kind: string;
}

interface PeriodLine {
    [P: string]: {
        expense: Transaction[];
        income: Transaction[]
    }
}

interface ReportResponse {
    periods: PeriodLine,
    previous_balance: number;
}

interface PeriodMapTable {
    [index: string]: {
        incomesLine: number;
        expensesLine: number;
        balanceLine: number;
        incomesLineCurrency: string;
        expensesLineCurrency: string;
        balanceLineCurrency: string;
        balance: number;
    }
}

const filters = inject('Filter') as IFilter;
const isLoading = ref(false);
const resultsTable = ref<PeriodMapTable>({} as PeriodMapTable);
const previousBalance = ref(0);
const predicted = ref(false);

const chartOptions = ref<ApexOptions>({
    chart: {
        id: 'vuechart-general',
        animations: {
            enabled: true,
            easing: 'easein',
            speed: 300,
            animateGradually: {
                enabled: false,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        toolbar: {
            export: {
                csv: {
                    columnDelimiter: ',',
                    headerCategory: 'data',
                    headerValue: 'value',
                    dateFormatter: function(timestamp: number) {
                        return dayjs(timestamp).utc().format('DD/MM/YYYY');
                    }
                }
            }
        },
    },
    xaxis: {
        type: 'datetime',
        categories: [],
        labels: {
            style: {
                fontSize: '10',
            },
            rotate: -45,
            rotateAlways: true,
            datetimeFormatter: {
                day: 'dd/MM',
                month: "MM 'yyyy'",
            },
        },
        crosshairs: {
            fill: {
                type: 'gradient',
                gradient: {
                    colorFrom: '#000',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                }
            }
        },
        tickPlacement: 'on',
        axisTicks: {
            show: true
        },
        tooltip: {
            enabled: true,
            formatter: function(val: string) {
                return dayjs(val).utc().format('DD/MM/YYYY');
            }
           
        },
    },
    yaxis: {
        labels: {
            show: true,
            formatter(val: number) {
                return Dinero({ amount: val,  currency: 'BRL' }).setLocale('pt-BR').toFormat()
            }
        },
        crosshairs: {
            position: 'top'
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: function(val: number) {
                return Dinero({ amount: val,  currency: 'BRL' }).setLocale('pt-BR').toFormat()
            }
        },
        x: {
            show: false,
        },
        followCursor: false,
        onDatasetHover: {
            highlightDataSeries: false,
        },
        style: {
            fontSize: '12px',
        },
    },
    title: {
        text: 'Receitas x Despesas',
        floating: true,
        align: 'center',
        style: {
            color: '#444'
        }
    },
    legend: {
        offsetY: 10,
        fontSize: '10',
        itemMargin: {
            vertical: 10
        }
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: [1, 1, 0.5, 0.5],
        curve: "smooth"
    },
    plotOptions: {
        bar: {
            dataLabels: {
              position: 'top',
            },
            columnWidth: "80%"
        }
    },
    grid: {
        xaxis: {
            lines: {
                show: true
            }
        },   
        yaxis: {
            lines: {
                show: true
            }
        },
        row: {
            colors: ['#fff', '#f2f2f2']
        },
        column: {
            colors: ['#fff', '#f2f2f2'],
            opacity: 0.5
        },  
    },
    markers: {
        size: 1,
        colors: ['#28B463', '#FF4D70', '#66C7F4', '#9f9f9f'],
        strokeColors: ['#28B463', '#FF4D70', '#66C7F4', '#9f9f9f'],
        strokeWidth: 4,
        strokeOpacity: 0.9,
        fillOpacity: 1,
        shape: "circle",
        radius: 5,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
            size: 5,
        }
    },
    fill: {
        opacity: [0.8, 0.8, 0.2, 0.1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
    },
});

const series = ref<ApexAxisChartSeries>([
    {
        name: 'receita',
        data: []
    },
    {
        name: 'despesa',
        data: []
    },
    {
        name: 'saldo',
        data: []
    }
]);

async function getReport(): Promise<void>  {
    isLoading.value = true;

    try {
        const resp = await ReportService.generate({
            type: 'general',
            graph: 'line',
            date_start: dayjs(filters?.start).format('YYYY-MM-DD'),
            date_end: dayjs(filters?.end).format('YYYY-MM-DD'),
            periodicity: filters?.periodicity || 'daily',
            predicted: predicted.value
        });

        resultsTable.value = mapToTable(resp.data.data);
        previousBalance.value = resp.data.data.previous_balance;

        const expenses = map(resp.data.data.periods, (item) => item.expense ? sumBy(item.expense, 'amount') * -1 : 0);
        const incomes = map(resp.data.data.periods, (item) => item.income ? sumBy(item.income, 'amount') : 0 );
        const balances = map(resp.data.data.periods, (item) => { 
            const inc = Number(sumBy(item.income, (inc: any) => Number(inc.amount)));
            const exp =  Number(sumBy(item.expense, (exp: any) => -Number(exp.amount)));
            return inc-exp;
        });

        const totalBalances: number[] = [];
        let prevTotalBalance = previousBalance.value;

        balances.forEach(function(value: number, index: number, array: number[]) {
            if(index>0) {
                prevTotalBalance = Number(totalBalances[index-1]);
            }
            totalBalances.push(prevTotalBalance + value);
        });

        let currentCompany = store.getters['user/currentCompany'];
        const periodsObj = Object.keys(resp.data.data.periods);
        const newFileName = `Relatorio-${chartOptions.value.title?.text?.replaceAll(' ', '')}-${currentCompany.name}-${dayjs(periodsObj[0]).format('DD.MM.YYYY')}-${dayjs(periodsObj[periodsObj.length - 1]).format('DD.MM.YYYY')}`;
        chartOptions.value = {
            ...chartOptions.value,
            chart: {
                toolbar: {
                    export: {
                        csv: {
                            filename: newFileName,
                        },
                        svg: {
                            filename: newFileName,
                        },
                        png: {
                            filename: newFileName,
                        }
                    }
                },
            },
            ...{
                xaxis: {
                    categories: [...map(Object.keys(resp.data.data.periods), function(date){
                        return date
                    })],
                }
            }
        };

        series.value = [
            {
                name: 'receita',
                data: incomes,
                type: 'column',
                color: '#28B463',
            },
            {
                name: 'despesa',
                data: expenses,
                type: 'column',
                color: '#FF4D70',
            },
            {
                name: 'saldo',
                data: balances,
                type: 'area',
                color: '#66C7F4',
            },
            {
                name: 'saldo acumulado',
                data: totalBalances,
                type: 'area',
                color: '#9f9f9f',
            }
        ];
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
        console.log(error);
    }
}

function mapToTable(results: ReportResponse): PeriodMapTable {

    const periods = results.periods;
    const newObject = {} as PeriodMapTable;

    Object.keys(periods).forEach(function(key: string, index: number) {
        const incomesLine = periods[key].income.reduce((acc, transaction) => transaction.amount + acc, 0) || 0;
        const expensesLine = periods[key].expense.reduce( (acc, transaction) => transaction.amount + acc, 0) * -1 || 0;
        const balanceLine = incomesLine - expensesLine;

        newObject[dayjs(key).format('DD/MM/YYYY')] = {
            incomesLine,
            expensesLine,
            balanceLine,
            incomesLineCurrency: Dinero({ amount: incomesLine,  currency: 'BRL' }).setLocale('pt-BR').toFormat(),
            expensesLineCurrency: Dinero({ amount: expensesLine,  currency: 'BRL' }).setLocale('pt-BR').toFormat(),
            balanceLineCurrency: Dinero({ amount: balanceLine,  currency: 'BRL' }).setLocale('pt-BR').toFormat(),
            balance: 0
        }
    });

    let previousBalance = results.previous_balance;

    Object.keys(newObject).forEach(function(key: string, index: number) {
        newObject[key].balance = previousBalance + newObject[key].balanceLine;
        previousBalance = Number(newObject[key]?.balance);
    });

    return newObject;
}

watch(predicted, async () => {
    getReport();
})

watchDebounced(
    filters, 
    async (newValue, oldValue) => {
        await getReport();
    },
    { debounce: 200, maxWait: 1000 },
);

onMounted(async () => {
    await getReport();
});

</script>