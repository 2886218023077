<template>
<div class="relative sm:max-w-3xl mx-auto">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="flex items-center">
        <router-link tag="a" :to="{name: 'user.notifications.index'}" replace class="ml-4 text-sm font-normal text-gray-400 hover:text-gray-700">
            <span class="text-sm flex items-center">
                <ArrowNarrowLeftIcon class="flex-shrink-0 h-6 w-6 text-sm mr-2" aria-hidden="true" /> 
                Voltar
            </span>
        </router-link>
    </div>

    <div class="mt-4 rounded-lg bg-white shadow p-6 border-b border-gray-200 ">
        <p class="text-xs text-gray-400">{{ dayjs(notification.created_at).format('DD/MM/YYYY HH:mm:ss') }}</p>
        <h2 class="text-lg text-gray-700 font-medium mt-2">{{ notification.data?.title }}</h2>
        <p class="mt-4 text-gray-600">{{ notification.data?.text }}</p>
        <p v-if="notification.data?.action_url" class="mt-4">
            <a :href="notification.data?.action_url" target="_blank" class="text-indigo-600 hover:text-indigo-700 font-semibold">
                {{ notification.data?.action_text}}
            </a>
        </p>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { ArrowNarrowLeftIcon } from '@heroicons/vue/outline';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import Notification from "@/types/Notification";
import NotificationService from '@/services/NotificationService';
import dayjs from 'dayjs';

const props = defineProps(['id']);
const isLoading = ref(false);
const notification = ref<Notification>({} as Notification);

async function getNotification(id: string): Promise<void> {
   isLoading.value = true;
   try {
       const response = await NotificationService.get(id);
       notification.value = response.data;
       isLoading.value = false;
   } catch(error) {
        console.log(error);
        isLoading.value = false;
   }
}

onMounted(async () => {
   await getNotification(props.id);
});

</script>