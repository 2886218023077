<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/fluxous-logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Altere a sua senha</h2>
            <div class="mt-2 max-w-xl text-sm text-gray-500 text-center">Informe a sua nova senha abaixo.</div>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div v-if="state.validToken">
                    <div v-if="state.changed">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">
                            <CheckCircleIcon class="h-10 inline text-green-400 align-center" aria-hidden="true" />
                            Senha alterada com sucesso!
                        </h3>
                        <div class="mt-2 max-w-xl text-sm text-gray-500">
                            <p>Faça login com seu e-mail e a nova senha cadastrada.</p>
                            <router-link tag="a" to="/login"  class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Entrar na minha conta
                            </router-link>
                        </div>
                    </div>

                    <form v-else class="space-y-6" @submit.prevent="onSubmit" novalidate>
                        <div>
                            <label for="password" class="block text-sm font-medium text-gray-700">Senha</label>
                            <div class="mt-1">
                                <input id="password" name="password" v-model="state.password" type="password"
                                    autocomplete="off" :required="true" placeholder="Informe sua senha"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                            <div v-if="v$.password.$error" class="text-sm text-red-600">
                                <div>{{ v$.password.$errors[0].$message }}</div>
                            </div>
                        </div>

                        <div>
                            <label for="password-confirmation" class="block text-sm font-medium text-gray-700">Confirme
                                sua
                                senha</label>
                            <div class="mt-1">
                                <input id="password_confirmation" name="password_confirmation"
                                    v-model="state.password_confirmation" type="password" autocomplete="off"
                                    :required="true" placeholder="Confirme sua senha"
                                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                            </div>
                            <div v-if="v$.password_confirmation.$error" class="text-sm text-red-600">
                                <div>{{ v$.password_confirmation.$errors[0].$message }}</div>
                            </div>
                        </div>

                        <div>
                            <button type="submit"
                                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Alterar minha senha
                            </button>
                        </div>
                    </form>
                </div>
                <div v-else>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        <ClockIcon out style="" class="h-10 inline text-red-400 align-center" aria-hidden="true" />
                        Link expirado
                    </h3>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                        <p>O link para alteração de senha expirou ou não é válido.</p><br>
                        <p>Se deseja alterar sua senha solicite novamente a alteração.</p>
                        <router-link to="/forgot-password" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Esqueci minha senha
                        </router-link>
                    </div>
                </div>
            </div>

            <p class="mt-6 text-center text-sm text-gray-400">
                Já possuo uma conta. <router-link tag="a" to="/login"
                    class="link-effect text-indigo-600 hover:text-indigo-500 font-bold">
                    Quero entrar
                </router-link>
            </p>
        </div>
    </div>
</template>


<script setup lang="ts">
import { reactive, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useVuelidate from '@vuelidate/core'
import { required, helpers, sameAs, minLength } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import api from '@/services/http/api';
import { CheckCircleIcon, ClockIcon } from '@heroicons/vue/outline';

const route = useRoute();

const state = reactive({
    password: '',
    password_confirmation: '',
    email: route.query.email,
    token: route.query.token,
    changed: false,
    validToken: true
});

const rules = computed(() => ({
    password: {
        required: helpers.withMessage('Obrigatório', required),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
    },
    password_confirmation: {
        required: helpers.withMessage('Obrigatório', required),
        sameAsPassword: helpers.withMessage('Senhas não coincidem', sameAs(state.password)),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
    }
}));

const v$ = useVuelidate(rules, state);

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    state.changed = false;

    if (!isValid) return;

    try {
        await api({ auth: false }).post('/backoffice/v1/auth/password/reset', {
            ...state
        });
        state.changed = true;
    } catch (error: any | AxiosError) {
        console.log(error?.response.data.message);
        state.changed = false;
    }
}

onMounted(async () => {
    const { email, token } = state;
    try {
        await api({ auth: false }).get('/backoffice/v1/auth/password/token', {
            params: { email, token }
        });

        state.validToken = true;
    } catch (error: any | AxiosError) {
        console.log(error?.response.data.message);
        state.validToken = false;
    }

})
</script>