import dayjs from "dayjs";
import Period from "../Period";
import IPeriodFormatter from "./IPeriodFormatter";

export default class YearPeriodFormatter implements IPeriodFormatter {

    format (interval: Period): string  {
        const startYear = dayjs(interval.start).format('YYYY');

        return startYear;
    }
}