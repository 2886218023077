<template>
    <div class="flex justify-center py-1 w-full">
        <a href="#" @click="selectedPeriodType.previous()" class="text-gray-300 hover:text-gray-500 transition">
            <ChevronLeftIcon class="h-8 w-8" />
        </a>

        <div class="flex flex-col self-start items-center justify-start px-6">
            <span class="text-gray-500 text-md font-normal">
                {{ selectedPeriodType.formatPeriod() }}
            </span>
            
            <Listbox as="div" v-model="selectedPeriodType">
                <div class="relative z-30">
                <ListboxButton class="w-48 md:w-64 cursor-pointer py-1 text-center text-gray-300 hover:text-gray-500 transition">
                    <span class="pointer-events-none text-center">
                        <ChevronDownIcon class="h-4 w-4 inline-block" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute w-64 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs text-center">
                        <ListboxOption as="template" v-for="period in periodTypes" :key="period.key" :value="period" v-slot="{ active, selected }">
                            <li @click="() => { period.setPeriod(); period.emit()}" :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ period.label }}</span>

                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
        </div>

        <a href="#" @click="selectedPeriodType.next()" class="text-gray-300 hover:text-gray-500 transition">
            <ChevronRightIcon class="h-8 w-8"/>
        </a>
    </div>

    <Modal :show="modalOpen" @close="() => modalOpen = false" :static="false" #default="{slotProps}">
        <div class="flex items-end sm:items-start p-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Período personalizado</h3>

            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="resetCustomPeriod" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>
        <div class="p-2 w-96">
            <div class="flex gap-2 p-4">
                <div class="">
                    <label for="" class="block text-sm font-medium text-gray-700">Data inicial</label>
                    <v-date-picker 
                        v-model="dates.date_start" 
                        mode="date" 
                        :masks="{ input: 'DD/MM/YYYY' }" 
                        is-required 
                        :input-debounce="100"
                        :attributes="[{popover: {visibility: 'hover'}}]"
                        >
                        <template v-slot="{ inputValue, togglePopover, inputEvents }">
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <button @click.prevent="togglePopover()" class="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>

                                <input 
                                    v-on="inputEvents"
                                    :value="inputValue"
                                    type="text" 
                                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm rounded-md text-gray-700 border-gray-300 focus:border-indigo-500 focus:ring-indigo-50" placeholder=""
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div class="">
                    <label for="" class="block text-sm font-medium text-gray-700">Data final</label>
                    <v-date-picker 
                        v-model="dates.date_end" 
                        mode="date" 
                        :masks="{ input: 'DD/MM/YYYY' }" 
                        is-required 
                        :input-debounce="100"
                        :min-date="dates.date_start"
                    >
                        <template v-slot="{ inputValue, togglePopover, inputEvents }">
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <button @click.prevent="togglePopover()" class="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                                <input 
                                    v-on="inputEvents"
                                    :value="inputValue"
                                    type="text"
                                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm rounded-md text-gray-700 border-gray-300 focus:border-indigo-500 focus:ring-indigo-50" placeholder=""
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
            </div>
            <div class="flex justify-center">
                <button type="button" @click.stop="resetCustomPeriod" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                <button type="button" @click.stop="setCustomPeriod" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Filtrar
                </button>
            </div>
        </div>
            
    </Modal>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import { computed } from '@vue/reactivity';
import { useRefHistory } from '@vueuse/core';
import dayjs from 'dayjs';
import { 
    ChevronLeftIcon, 
    ChevronRightIcon, 
    CheckIcon, 
    ChevronDownIcon, 
    CalendarIcon 
} from '@heroicons/vue/outline';

import { XIcon } from '@heroicons/vue/solid';

import { 
    Listbox, 
    ListboxButton, 
    ListboxOption, 
    ListboxOptions 
} from '@headlessui/vue';
import Modal from '@/components/Modal.vue';

const emit = defineEmits(['period'])

const modalOpen = ref(false);

const dates = ref({
    date_start: dayjs().toDate(),
    date_end: dayjs().toDate(),
})

const filterBetweenYears = computed(() => {
    const startYear = dayjs(dates.value.date_start).year();
    const endYear = dayjs(dates.value.date_end).year();

    return  startYear !== endYear
})

const periodTypes = [
    {
        key: 'day',
        label: 'Hoje',
        setPeriod: () => {
            dates.value.date_start = dayjs().toDate();
            dates.value.date_end = dayjs().toDate();
        },
        emit: () => {
            updateFilter();
        },
        formatPeriod: () => {
            const day = dayjs(dates.value.date_start).format('DD');
            const month = dayjs(dates.value.date_start).format('MMMM');
            
            return `
                ${day} de 
                ${month.charAt(0).toUpperCase()}${month.slice(1)} 
                ${dayjs(dates.value.date_start).format('YYYY')}
            `;
        },
        previous: () => {
            dates.value.date_start = dayjs(dates.value.date_start).subtract(1, 'day').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).subtract(1, 'day').toDate();

            updateFilter();
        },
        next: () => {
            dates.value.date_start = dayjs(dates.value.date_start).add(1, 'day').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).add(1, 'day').toDate();

            updateFilter();
        }
    },
    {
        key: 'week',
        label: 'Esta semana',
        setPeriod: () => {
            dates.value.date_start = dayjs().startOf('week').toDate(),
            dates.value.date_end = dayjs().endOf('week').toDate()
        },
        emit: () => {
            updateFilter();
        },
        formatPeriod: () => {
            const startYear = dayjs(dates.value.date_start).format('YYYY');
            const endYear = dayjs(dates.value.date_end).format('YYYY');

            return `
                ${dayjs(dates.value.date_start).format('DD MMM')} ${startYear} à 
                ${dayjs(dates.value.date_end).format('DD MMM')} ${endYear}
            `;
        },
        previous: () => {
            dates.value.date_start = dayjs(dates.value.date_start).subtract(1, 'week').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).subtract(1, 'week').toDate();

            updateFilter();
        },
        next: () => {
            dates.value.date_start = dayjs(dates.value.date_start).add(1, 'week').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).add(1, 'week').toDate();

            updateFilter();
        },
    },
    {
        key: 'month',
        label: 'Este mês',
        setPeriod: () => {
            dates.value.date_start = dayjs().startOf('month').toDate(),
            dates.value.date_end = dayjs().endOf('month').toDate()
        },
        emit: () => {
            updateFilter();
        },
        formatPeriod: () => {
            const year = dayjs(dates.value.date_start).format('YYYY');

            return `
                ${dayjs(dates.value.date_start).format('DD MMM')} à 
                ${dayjs(dates.value.date_end).format('DD MMM')} ${year}
            `;
        },
        previous: () => {
            dates.value.date_start = dayjs(dates.value.date_start).subtract(1, 'month').startOf('month').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).subtract(1, 'month').endOf('month').toDate();

            updateFilter();
        },
        next: () => {
            dates.value.date_start = dayjs(dates.value.date_start).add(1, 'month').startOf('month').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).add(1, 'month').endOf('month').toDate();

            updateFilter();
        }
    },
    {
        key: 'custom',
        label: 'Período personalizado',
        setPeriod: () => {
            modalOpen.value = true;
            dates.value.date_start = dayjs().startOf('month').toDate(),
            dates.value.date_end = dayjs().endOf('month').toDate()
        },
        emit: () => {},
        formatPeriod: () => {
            const startYear = dayjs(dates.value.date_start).format('YYYY');
            const endYear = dayjs(dates.value.date_end).format('YYYY');

            return `
                ${dayjs(dates.value.date_start).format('DD MMM')} ${startYear} - 
                ${dayjs(dates.value.date_end).format('DD MMM')} ${endYear}
            `;
        },
        previous: () => {
            const dateStart = dayjs(dates.value.date_start);
            const dateEnd = dayjs(dates.value.date_end);
            
            const diff = Math.abs(dayjs(dateStart).diff(dateEnd, 'days'));

            dates.value.date_start = dayjs(dates.value.date_start).subtract(diff, 'days').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).subtract(diff, 'days').toDate();

            updateFilter();
        },
        next: () => {
            const dateStart = dayjs(dates.value.date_start);
            const dateEnd = dayjs(dates.value.date_end);
            
            const diff = Math.abs(dayjs(dateStart).diff(dateEnd, 'days'));

            dates.value.date_start = dayjs(dates.value.date_start).add(diff, 'days').toDate();
            dates.value.date_end = dayjs(dates.value.date_end).add(diff, 'days').toDate();

            updateFilter();
        }
    },
]

const defaultPeriod = periodTypes.find( item => item.key == 'month' )
const selectedPeriodType = ref(defaultPeriod!)

const { undo } = useRefHistory(selectedPeriodType)

const updateFilter = () => {
    emit('period', dates.value);
}

const setCustomPeriod = () => {
    updateFilter();
    modalOpen.value = false;
}

const resetCustomPeriod = async () => {
    await nextTick();
    undo()
    selectedPeriodType.value.setPeriod();
    updateFilter();
    modalOpen.value = false;
}

onMounted( () => {
    selectedPeriodType.value.setPeriod();
    updateFilter();
})


</script>