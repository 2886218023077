import { ActionContext, ActionTree } from 'vuex'
import { IRootState } from '@/store/interfaces'
import UserService from '@/services/UserService'
import Company from '@/types/Company'
import { StateType } from './types'
import ActionEnum from './action-enum'
import MutationEnum from './mutation-enum'

export const actions: ActionTree<StateType, IRootState> = {
    [ActionEnum.GET_INFO]({state, commit}: ActionContext<StateType, IRootState>) {
        return new Promise((resolve, reject) => {
            UserService.info().then( response => {
                commit(MutationEnum.ADD_INFO, response.data);

                state.companies.forEach( (element: Company) => {
                    if(state.user?.current_company_id == element.id) {
                        commit(MutationEnum.SET_CURRENT_COMPANY, element);
                    }
                });

                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
    
    [ActionEnum.GET_COMPANIES]({state, commit}: ActionContext<StateType, IRootState>) {
        commit(MutationEnum.CLEAR_COMPANIES)

        return new Promise((resolve, reject) => {
            UserService.companies().then( response => {
                response.data.data.forEach( (element: Company) => {
                    if(state.user?.current_company_id == element.id) {
                        commit(MutationEnum.SET_CURRENT_COMPANY, element);
                    }
                    commit(MutationEnum.ADD_COMPANY, element)
                });
                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },
}