<template>
 <div class="relative overflow-visible p-0">
    <div  class="w-full p-4 space-y-2">
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Detalhes do Cliente</h3>
        </div>

        <p class="text-xs text-red-700 rounded-md p-2">Atenção: Esta será a última vez que você verá o <strong>Secret</strong> deste cliente. Copie-o e guarde-o em um lugar seguro.</p>
        
        <div class="bg-gray-200 p-4 rounded-md text-sm font-mono">
            <p>Client: {{ client.name }}</p>
            <p>Id: {{ client.id }}</p>
            <p class="font-bold">Secret: {{ client.plainSecret }}</p>
            <button @click="copy(textToClipboad || '')" class="mt-4 py-1 px-2 border border-transparent text-xs rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                <span v-if='!copied'><ClipboardCopyIcon class="w-4 h-4 inline" /> Copiar</span>
                <span v-else><ClipboardCheckIcon class="w-4 h-4 inline" /> Copiado!</span>
            </button>
        </div>

        <div class="pt-5">
            <div class="flex justify-center gap-2">
                <button type="reset" @click="cancel" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Fechar</button>
            </div>
        </div>
    </div>
    <p class="inset"></p>
</div>
</template>

<script setup lang="ts">
    import { PropType, toRef } from 'vue';
    import { useClipboard, usePermission } from '@vueuse/core'
    import { computed } from '@vue/reactivity';
    import { ClipboardCopyIcon, ClipboardCheckIcon } from '@heroicons/vue/outline';
    import OAuthClient from '@/types/OAuthClient';

    const props = defineProps({
        client: {
            type: Object as PropType<OAuthClient>,
            required: true,
        }
    })

    const emit = defineEmits<{ (event: 'canceled'): void }>();

    const textToClipboad = computed<string>( () => 
    `Fluxous Client\nName: ${client.value.name}\nId: ${client.value.id}\nSecret: ${client.value.plainSecret}`
    )

    const client = toRef(props, 'client');
    const { text, isSupported, copy, copied } = useClipboard({ source: textToClipboad})
    const permissionRead = usePermission('clipboard-read')

    function cancel(): void {
        emit('canceled');
    }
</script>