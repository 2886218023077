import dayjs from "dayjs";
import CustomFormatter from "../Formatters/CustomFormatter";
import Period from "../Period";
import IPeriodNavigator from "./INavigator";

export default class CustomNavigator implements IPeriodNavigator {

    private currentPeriod: Period;
    private label: string = 'Período personalizado';
    private key: string = 'custom';
    private periodicity: string = 'monthly';
    
    constructor(
        private start: Date,
        private end: Date,
        private interval: number = 1,
        public readonly getExternal = true
    ) {
        this.currentPeriod = {
            start: dayjs(this.start).startOf('month').toDate(),
            end: dayjs(this.end).endOf('month').toDate(),
        }
    }

    setPeriod(start: Date, end: Date) {
        this.currentPeriod = {
            start: dayjs(start).toDate(),
            end: dayjs(end).toDate(),
        }
    }

    reset() {
        this.currentPeriod = {
            start: dayjs(this.start).startOf('month').toDate(),
            end: dayjs(this.end).endOf('month').toDate(),
        }

        return this.currentPeriod;
    }

    getCurrent(): Period {
        return this.currentPeriod;
    }

    getKey(): string {
        return this.key;
    }

    getLabel(): string {
        return this.label;
    }

    getPeriodicity(): string {
        return this.periodicity;
    }

    getInterval(): number {
        return this.interval;
    }

    getPrevious(): Period {
        const dateStart = dayjs(this.currentPeriod.start);
        const dateEnd = dayjs(this.currentPeriod.end);

        const diff = Math.abs(dayjs(dateStart).diff(dateEnd, 'days'));

        const start = dayjs(this.currentPeriod.start).subtract(diff, 'days').toDate();
        const end = dayjs(this.currentPeriod.end).subtract(diff, 'days').toDate();
        
        this.currentPeriod = { start, end };
        return this.currentPeriod;
    }

    getNext(): Period {
        const dateStart = dayjs(this.currentPeriod.start);
        const dateEnd = dayjs(this.currentPeriod.end);

        const diff = Math.abs(dayjs(dateStart).diff(dateEnd, 'days'));

        const start = dateStart.add(diff, 'days').toDate();
        const end = dateEnd.add(diff, 'year').toDate();

        this.currentPeriod = { start, end };
        return this.currentPeriod;
    }

    format(): string {
        return new CustomFormatter().format(this.currentPeriod);
    }
}