<template>
<div class="relative overflow-visible p-0">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <form class="space-y-6" @submit.prevent="onSubmit" novalidate>
        <div>
            <label for="current_password" class="block text-sm font-medium" :class="[v$.current_password.$error ? 'text-red-500' : 'text-gray-700']">Senha atual</label>
            <div class="mt-1">
                <input v-model="state.current_password" 
                    id="current_password" 
                    name="current_password" 
                    type="password" 
                    autocomplete="off"
                    :required="true" 
                    placeholder="Nova senha"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    :class="[
                        v$.current_password.$error ? 'text-red-600 border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                    ]"
                />
                <p class="mt-2 text-xs" :class="[v$.current_password.$error ? 'text-red-500' : 'text-gray-400']">Letras, números e ponto, mínimo de 8 caracteres</p>
            </div>
            <div v-if="v$.current_password.$error" class="text-sm text-red-600">
                <div>{{ v$.current_password.$errors[0].$message }}</div>
            </div>
        </div>
        <div>
            <label for="password" class="block text-sm font-medium" :class="[v$.password.$error ? 'text-red-500' : 'text-gray-700']">Nova senha</label>
            <div class="mt-1">
                <input v-model="state.password" 
                    id="password" 
                    name="password" 
                    type="password" 
                    autocomplete="off"
                    :required="true" 
                    placeholder="Nova senha"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    :class="[
                        v$.password.$error ? 'text-red-600 border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                    ]"
                />
                <p class="mt-2 text-xs text-gray-400" :class="[v$.password.$error ? 'text-red-500' : 'text-gray-400']">Letras, números e ponto, mínimo de 8 caracteres</p>
            </div>
            <div v-if="v$.password.$error" class="text-sm text-red-600">
                <div>{{ v$.password.$errors[0].$message }}</div>
            </div>
        </div>
        <div>
            <label for="password_confirmation" class="block text-sm font-medium" :class="[v$.password_confirmation.$error ? 'text-red-500' : 'text-gray-700']">Confirmar senha</label>
            <div class="mt-1">
                <input v-model="state.password_confirmation" 
                    id="password_confirmation" 
                    name="password_confirmation"
                    type="password" 
                    autocomplete="off"
                    :required="true" 
                    placeholder="Confirme a senha"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                    :class="[
                        v$.password_confirmation.$error ? 'text-red-600 border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                    ]"
                />
                <p class="mt-2 text-xs" :class="[v$.password_confirmation.$error ? 'text-red-500' : 'text-gray-400']" >Letras, números e ponto, mínimo de 8 caracteres</p>
            </div>
            <div v-if="v$.password_confirmation.$error" class="text-sm text-red-600">
                <div>{{ v$.password_confirmation.$errors[0].$message }}</div>
            </div>
        </div>
        <div>
            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Alterar senha
            </button>
        </div>
    </form>
</div>
</template>

<script setup lang="ts">

import { reactive, computed, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers, sameAs, minLength } from '@vuelidate/validators';

import { Toast } from '@/constants/swal-mixins';
import UserService from '@/services/UserService'

import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

const isLoading = ref(false);

const state = reactive({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const rules = computed(() => ({
    current_password: {
        required: helpers.withMessage('Obrigatório', required),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
    },
    password: {
        required: helpers.withMessage('Obrigatório', required),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
    },
    password_confirmation: {
        required: helpers.withMessage('Obrigatório', required),
        sameAsPassword: helpers.withMessage('Senhas não coincidem', sameAs(state.password)),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
    }
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const changePassword = async () => {
    isLoading.value = true;
    try {

        const {current_password, password, password_confirmation} = state;

        await UserService.changePassword({
            current_password,
            password,
            password_confirmation,
        });

        await Toast.fire({
            icon: 'success',
            title: 'Senha alterada com sucesso',
            iconColor: 'white',
        })

        v$.value.$clearExternalResults();

        state.current_password = ''
        state.password = ''
        state.password_confirmation = ''

        v$.value.$reset();
        
        isLoading.value = false;
    } catch(error: any) {
        Toast.fire({
            icon: 'error',
            title: 'Não foi possível alternar a senha',
            iconColor: 'white',
        });

        $externalResults.value = { ...error?.response.data.errors };
        isLoading.value = false;
    }
}

const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await changePassword();
}

</script>