import { MutationTree } from "vuex"
import MutationEnum from "./mutation-enum"
import { MutationType, StateType, Layout } from "./types"

export const mutations: MutationTree<StateType> & MutationType = {
    [MutationEnum.SET_LAYOUT](state, payload: string) {
        
        let current = state.layouts.find( items => items.name === payload);
        
        state.current = current;
    },
    
    [MutationEnum.REGISTER_LAYOUT](state, payload: Layout) {
        state.layouts.push(payload);
    },

    [MutationEnum.TOGGLE_AMOUNT](state) {
        state.showAmount = !state.showAmount;
    },
}