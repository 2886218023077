<template>
<div class="relative overflow-visible p-2">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <div class="text-center justify-center items-center flex flex-col rounded-lg pb-4">
        <img v-if="state.kind.name==='income'" src="@/assets/tag_income.svg" class="w-10 text-center mb-4" />
        <img v-else src="@/assets/tag_expense.svg" class="w-10 text-center mb-4" />
        <h3 class="text-lg leading-6 font-medium text-gray-900">Criar categoria para {{state.kind.label.toLowerCase()}}</h3>
    </div>
    <form  class="space-y-2" @submit.prevent="onSubmit" novalidate>
        <div :class="[v$.name.$error ? 'text-red-500' : 'text-gray-400']">
            <label for="name" class="block text-sm font-medium" :class="[v$.name.$error ? 'text-red-500' : 'text-gray-700']">Nome da categoria</label>
            <div class="mt-1">
                <input id="name" 
                    name="name" 
                    v-model="state.name" 
                    type="text" autocomplete="off"
                    :required="true" placeholder="Informe um nome"
                    :class="[
                        v$.name.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                    ]"
                />
            </div>
            <div v-if="v$.name.$error" class="mt-1 text-xs text-red-600">
                <div>{{ v$.name.$errors[0].$message }}</div>
            </div>
        </div>
        <div>
            <label for="description" class="block text-sm font-medium text-gray-700">Descrição</label>
            <div class="mt-1">
                <textarea id="description" name="name" v-model="state.description" type="text" autocomplete="off"
                    :required="true" placeholder="Descrição"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
                <p class="mt-2 text-xs text-gray-400"></p>
            </div>
            <div v-if="v$.description.$error" class="text-sm text-red-600">
                <div>{{ v$.description.$errors[0].$message }}</div>
            </div>
        </div>
        <div class="pt-5">
            <div class="flex justify-center">
                <button type="reset" @click="cancel" :disabled="isLoading" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                <button type="submit" :disabled="isLoading" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                     <Spinner class="mr-2" v-if="isLoading"/> Criar categoria
                </button>
            </div>
        </div>
    </form>
</div>
</template>

<script setup lang="ts">
import { computed, ref } from '@vue/reactivity';
import { reactive, toRef } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxLength } from '@vuelidate/validators';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import CategoryService from '@/services/CategoryService';
import { AxiosError } from 'axios';

const emit = defineEmits(['created', 'canceled'])
const isLoading = ref(false);

const props = defineProps({
    kind: { 
        type: Object,
        required: true
    },
    category: { 
        type: Object,
        required: false
    },
});

const kind = toRef(props, 'kind')
const image = computed( () => kind.value.name == "income" ? 'tag_income.svg' : 'tag_expense.svg')

const state = reactive({
    name: '',
    description: '',
    kind: kind.value,
    default: false
})

const rules = computed(() => ({
    name: {
        required: helpers.withMessage('Obrigatório', required),
        maxLength: helpers.withMessage('Tamanho máximo 50 caracteres', maxLength(50)),
    },
    description: {
        maxLength: helpers.withMessage('Tamanho máximo 150 caracteres', maxLength(150)),
    },    
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const cancel = () => {
    emit('canceled');
}

const createCategory = async () => {
    isLoading.value = true;
    try {
        await CategoryService.create({
            ...state,
            kind: state.kind.name
        })
        isLoading.value = false;
        emit('created')
    } catch(error: any | AxiosError) {
        isLoading.value = false;

        if(error.response.data.errors.name) {
            $externalResults.value = {
                name: error.response.data.errors.name[0],
            }
        }

    }
}

const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await createCategory();
}
</script>