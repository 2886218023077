import { 
    createRouter, 
    createWebHistory, 
    RouteRecordRaw, 
} from 'vue-router';

import authGuard from './guards/authGuard';
import companyGuard from './guards/companyGuard';
import currentCompanyGuard from './guards/currentCompanyGuard';

/** AUTH */
import LoginPage from '@pages/Auth/Login/Index.vue';
import RegisterPage from '@pages/Auth/Register/Index.vue';
import PasswordForgotPage from '@pages/Auth/Password/PasswordForgot.vue';
import PasswordResetPage from '@pages/Auth/Password/PasswordReset.vue';
import EmailVerificationPage from '@/pages/Auth/Email/EmailVerification.vue';

/** COMPANY */
import CompanyPage from '@pages/Company/Index.vue';
import CompanyDashboardPage from '@pages/Company/Dashboard.vue';
import TransactionsPage from '@pages/Company/Transactions/Index.vue';
import ImportPage from '@pages/Company/Transactions/Import.vue';
import AccountsPage from '@/pages/Company/Accounts/Index.vue';
import AccountDetailPage from '@pages/Company/Accounts/AccountDetail.vue';
import ReportsPage from '@pages/Company/Reports/Index.vue';
import ReportsIncomeExpensePage from '@/pages/Company/Reports/IncomeExpense.vue';
import ReportsAccountsPage from '@/pages/Company/Reports/Accounts.vue';
import ReportsCategoryPage from '@/pages/Company/Reports/Category.vue';
import SettingsPage from '@pages/Company/Settings/Index.vue';
import SettingsCompany from '@pages/Company/Settings/Company.vue';
import SettingsUsers from '@pages/Company/Settings/Users.vue';
import SettingsCategories from '@pages/Company/Settings/Categories.vue';
import SettingsReports from '@pages/Company/Settings/Reports.vue';
import SettingsAudits from '@pages/Company/Settings/Audits.vue';
import SettingsIntegration from '@pages/Company/Settings/Integration.vue';

/** USER */
import UserPage from '@pages/User/Index.vue';
import UserDashboardPage from '@pages/User/Dashboard.vue';
import UserPageNewCompany from '@pages/User/NewCompany.vue';
import ProfileIndex from '@pages/User/Profile/Index.vue';
import ProfileHome from '@pages/User/Profile/Home.vue';
import ProfilePassword from '@pages/User/Profile/Password.vue';
import UserNotificationIndexPage from '@/pages/User/Notifications/Index.vue';
import UserNotificationDetailPage from '@/pages/User/Notifications/Detail.vue';

/** GUEST */
import InviteAcceptPage from '@/pages/Guest/Invites/Accept.vue';


/** COMMON */
import NotFoundPage from '@pages/Common/Errors/NotFound.vue';


const routes: Array<RouteRecordRaw> = [
    /** COMPANY ROUTES */
    { 
        path: '/',
        name: 'company.index',
        component: CompanyPage,
        redirect: { name: 'company.dashboard' },
        meta: { 
            auth: true,
            currentCompany: true,
            company: true,
            layout: 'dashboard'
        }, 
        children: [
            {
                path: '', 
                name: 'company.dashboard', 
                component: CompanyDashboardPage,
            },
            {
                path: '/transactions', 
                name: 'company.transactions.index', 
                component: TransactionsPage,
                props: true
            },
            {
                path: '/transactions/import', 
                name: 'company.transactions.import', 
                component: ImportPage
            },
            {
                path: '/accounts', 
                name: 'company.accounts.index', 
                component: AccountsPage,
            },
            {
                path: '/accounts/:id/detail', 
                name: 'company.accounts.detail', 
                component: AccountDetailPage,
                props: true
            },
            {
                path: '/reports', 
                name: 'company.reports.index', 
                component: ReportsPage,
                redirect: { name: 'company.reports.income-expense' },
                children: [
                    {
                        path: '/reports/income-expense',
                        name: 'company.reports.income-expense',
                        component: ReportsIncomeExpensePage,
                    },
                    {
                        path: '/reports/categories',
                        name: 'company.reports.categories',
                        component: ReportsCategoryPage,
                    },
                    {
                        path: '/reports/accounts',
                        name: 'company.reports.accounts',
                        component: ReportsAccountsPage,                        
                    }
                ]
            },
            {
                path: 'settings', 
                name: 'company.settings.index', 
                component: SettingsPage,
                redirect: {name: 'company.settings.company'},
                children: [
                    {
                        path: '', 
                        name: 'company.settings.company', 
                        component: SettingsCompany,
                    },
                    {
                        path: '/settings/users', 
                        name: 'company.settings.users', 
                        component: SettingsUsers,
                    },
                    {
                        path: '/settings/categories', 
                        name: 'company.settings.categories', 
                        component: SettingsCategories,
                    },
                    {
                        path: '/settings/audits', 
                        name: 'company.settings.audits', 
                        component: SettingsAudits,
                    },
                    {
                        path: '/settings/reports', 
                        name: 'company.settings.reports', 
                        component: SettingsReports,
                    },
                    {
                        path: '/settings/integrations', 
                        name: 'company.settings.integrations', 
                        component: SettingsIntegration,
                    },
                ]
            }
        ]
    },
    /** USER ROUTES */
    {
        path: '/user', 
        name: 'user.index', 
        component: UserPage,
        redirect: { name: 'user.dashboard' },
        meta: {
            auth: true,
            layout: 'dashboard-user'
        },
        children: [
            {
                path: '',
                name: 'user.dashboard',
                component: UserDashboardPage,
            },
            {
                path: 'new-company',
                name: 'user.new-company',
                component: UserPageNewCompany,
            },
            {
                path: 'profile', 
                name: 'user.profile.index', 
                component: ProfileIndex,
                redirect: {name: 'user.profile.home'},
                meta: {
                    layout: 'dashboard-user'
                },
                children: [
                    {
                        path: '', 
                        name: 'user.profile.home', 
                        component: ProfileHome,
                    },
                    {
                        path: 'password', 
                        name: 'user.profile.password', 
                        component: ProfilePassword,
                    },
                ]
            },
            {
                path: 'notifications', 
                name: 'user.notifications.index',
                component: UserNotificationIndexPage,
            },

            {
                path: 'notifications/:id/detail',
                name: 'user.notifications.detail',
                component: UserNotificationDetailPage,
                props: true
            }
        ]
    },
    /** AUTH ROUTES */
    { 
        path: '/login', 
        name: 'login', 
        component: LoginPage,
        meta: { 
            layout: 'auth'
        }, 
    },
    { 
        path: '/register/:email?',
        name: 'register', 
        component: RegisterPage,
        props: true,
        meta: { 
            layout: 'auth'
        }, 
    },
    { 
        path: '/forgot-password', 
        name: 'password.forgot', 
        component: PasswordForgotPage,
        meta: { 
            layout: 'auth'
        },
    },
    { 
        path: '/reset-password', 
        name: 'password.reset', 
        component: PasswordResetPage,
        meta: { 
            layout: 'auth'
        },
    },
    { 
        path: '/email-verification', 
        name: 'email.verification', 
        component: EmailVerificationPage,
        meta: { 
            layout: 'auth'
        },
    },
    /** PUBLIC PAGES */
    { 
        path: '/invite/accept', 
        name: 'invite.accept', 
        component: InviteAcceptPage,
        meta: { 
            layout: 'auth'
        },
    },
    /** ERROR PAGES */
    { 
        path: '/:pathMatch(.*)*', 
        name: 'not-found', 
        component: NotFoundPage,
        meta: { 
            layout: 'default'
        },
    },
    { 
        path: '/:pathMatch(.*)', 
        name: 'not-found', 
        component: NotFoundPage,  
        meta: { 
            layout: 'default'
        },  
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
});

router.beforeEach(authGuard);
router.beforeEach(companyGuard);
router.beforeEach(currentCompanyGuard);

export default router;