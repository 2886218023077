import Swal from "sweetalert2";

export const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    },
    customClass: {
        popup: 'colored-toast'
    }
})

export const DangerSwal =  Swal.mixin({
    showConfirmButton: true,    
    showCancelButton: true,
    timerProgressBar: true,
    buttonsStyling: false,
    showLoaderOnConfirm: true,
    customClass: {
        title: 'text-md h2 font-normal font-sans mt-0',
        icon: 'mt-4',
        confirmButton: 'danger-button mr-4',
        cancelButton: 'secondary-button',
        actions: 'mb-4'
    }
})

export const ConfirmSwal =  Swal.mixin({
    showConfirmButton: true,    
    showCancelButton: true,
    timerProgressBar: true,
    buttonsStyling: false,
    showLoaderOnConfirm: true,
    customClass: {
        title: 'text-md h2 font-normal font-sans mt-0',
        icon: 'mt-4',
        confirmButton: 'primary-button mr-4',
        cancelButton: 'secondary-button',
        actions: 'mb-4'
    }
})