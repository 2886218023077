<template>
<div class="relative">
    <Overlay :show="isLoading"> 
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div  class="space-y-4 w-96 p-4">
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Excluir lançamento</h3>

            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="cancel" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>
        <div class="pb-2">
            Deseja realmente excluir o lançamento <span class="font-medium">"{{ state.description }}"</span>?
        </div>

        <div>
            <p class="text-sm bg-red-100 p-2 rounded-md text-red-700">Atenção: esta operação não pode ser desfeita.</p>
        </div>
        
        
        <div v-if="state.is_recurring" class="mt-2">
            <div class="flex flex-col space-y-2 justify-center ">
                <button type="button" @click.stop="() => deleteTransaction('CURRENT')" class="block justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Excluir apenas este o lançamento
                </button>
                <button type="button" @click.stop="() => deleteTransaction('CURRENT_NEXT')" class="block justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Excluir este lançamento e os próximos
                </button>
                <button type="button" @click.stop="() => deleteTransaction('ALL')" class="block justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                    Excluir TODOS os lançamentos
                </button>
            </div>
        </div>
        <div v-else class="flex flex-col space-y-2 justify-center">
            <button type="button" @click.stop="deleteTransaction('')" class="block justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                Sim
            </button>
            <button type="button" @click="emit('canceled')" class="block justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                Não
            </button>
        </div>

    </div>
    <div id="confirm-delete"></div>
</div>

</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { XIcon } from '@heroicons/vue/outline';
import { DangerSwal, Toast } from '@/constants/swal-mixins';
import TransactionService from '@/services/TransactionService';
import Account from '@/types/Account';
import Category from '@/types/Category/Category';
import TransactionKindEnum from '@/types/Transaction/TransactionKindEnum';
import TransactionTypeEnum from '@/types/Transaction/TransactionTypeEnum';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

interface AccountSelect {
    id?: string|null,
    name?: string
}

const props = defineProps({
    transaction: {
        type: String,
        required: true
    },
})

const emit = defineEmits(['deleted', 'canceled', 'error'])

const state = ref({
    id: '',
    type: TransactionTypeEnum.DEBIT,
    kind: TransactionKindEnum.EXPENSE,
    description: '',
    origin_account_id: '',
    origin_account: {} as AccountSelect | undefined,
    billing_date: new Date(),
    payment_date: null,
    amount: 0,
    accounts: [] as Account[],
    paid: true,
    category: {} as Category,
    category_id: '',
    is_recurring: false,
    recurrence_index: 0,
    recurrence_total: 0,
    recurrence: {
        frequency: 'MONTHLY',
        interval: 1,
        start_date: dayjs(new Date()).toDate(),
        limit_by: 'DATE',
        limit_date: dayjs(new Date()).add(2, 'year').toDate(),
        limit_count: 2,
    }
});

const isLoading = ref(false);

const loadTransaction = async () => {
    isLoading.value = true;

    try {
        let response = await TransactionService.get(props.transaction, {
            load_category: true,
            load_account: true,
            load_recurrence: true,
        });

        const { data } = response.data;

        state.value = { ...data, amount: Math.abs(data.amount) };
        isLoading.value = false;
    } catch (error: any | AxiosError) {
        isLoading.value = false;

        Toast.fire({
            icon: 'error',
            title: 'Falha ao obter os dados da transação'
        });
    }
}

const cancel = () => {
    emit('canceled');
}

const deleteTransaction = async (delete_mode?: string) => {

    isLoading.value = true;

    try {
        await TransactionService.delete(props.transaction, {delete_mode});
        Toast.fire({
            icon: 'success',
            title: 'Lançamento excluído com successo',
            iconColor: 'white',
        })
        isLoading.value = false;
        emit('deleted')
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Erro ao excluir o lançamento',
            iconColor: 'white',
        })
        isLoading.value = false;
        emit('error', 'excluir')
    }
}

onMounted( async () => {
    isLoading.value = true;
    await loadTransaction();
    isLoading.value = false;
})

</script>