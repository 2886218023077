import Category from "@/types/Category/Category";
import { Axios, AxiosResponse } from "axios";
import api from "./http/api";

interface CategoryFilter {
    name?: string,
    description?: string,
    kind?: string,
    sort?: string,
    order?: string
    paginate?: boolean
}

class CategoryService {

    list(params: CategoryFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>('/backoffice/v1/categories', { params });
    }

    create(params: Category) {
        return api({ auth: true, company: true }).post<AxiosResponse>('/backoffice/v1/categories', { ...params } );
    }

    update(id: string, params: Category) {
        return api({ auth: true, company: true }).put<AxiosResponse>(`/backoffice/v1/categories/${id}`, { ...params } );
    }

    delete(id: string) {
        return api({ auth: true, company: true }).delete<AxiosResponse>(`/backoffice/v1/categories/${id}`);
    }

}

export default new CategoryService();