import CompanyInvite from "@/types/CompanyInvite";
import api from "./http/api";

interface CompanyInviteListResponse {
    data: Array<CompanyInvite>
}

interface CreateInvite {
    email: string,
    role_id: string
}

interface AcceptInvite {
    id: string,
    hash: string,
    expires: string,
    signature: string,
}

class CompanyInviteService {
    list() {
        return api({ auth: true, company: true })
            .get<CompanyInviteListResponse>('/backoffice/v1/invites', { params: {
                with: 'inviter,role',
                accepted: false,
                sort: 'created_at'
            }});
    }

    create(data: CreateInvite) {
        return api({ auth: true, company: true })
            .post<CompanyInviteListResponse>('/backoffice/v1/invites', data );
    }

    send(id: string) {
        return api({ auth: true, company: true })
            .put(`/backoffice/v1/invites/${id}/send`);
    }

    accept(data: AcceptInvite) {
       return api({ auth: false }).get(`/backoffice/v1/guest/invites/accept/${data.id}/${data.hash}`, {
            params: {
                expires: data.expires, 
                signature: data.signature
            }
        });
    }

    verify({id}: AcceptInvite) {
        return api({ auth: false }).get(`/backoffice/v1/guest/invites/verify/${id}`);
     }

    delete(id: string) {
        return api({ auth: true, company: true })
            .delete(`/backoffice/v1/invites/${id}`);
    }
}

export default new CompanyInviteService();