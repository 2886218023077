<template>
<div>
<div class="relative" id="divUserInfo">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div ref="triggerHeader"></div>
        <div class="rounded-lg bg-white shadow">
            <!--HEADER-->
            <div class="sticky top-0 pt-4 px-4 pb-4 lg:pb-0 grid grid-cols-1 gap-4 md:grid-cols-2 lg:flex justify-between items-center z-30"
                :class="[!headerIsVisible ? 'shadow-down bg-white' : 'no-shadow p-4']"
            >
                <div class="self-start">
                    <h3 class="text-xl font-normal text-gray-500">Lançamentos</h3>
                </div>

                <div>
                    <TransactionFilters @period="setPeriod"/>
                </div>
                
                <div class="flex space-x-2 z-30 self-start">
                    

                    <Menu as="div" class="relative">
                        <div>
                            <MenuButton class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                                <span class="sr-only">Abrir opções</span>
                                <span class="text-xs">+ Novo lançamento</span>
                            </MenuButton>
                        </div>

                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                        <a href="#" @click.prevent="() => openModal('expense-create')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                            Despesa
                                        </a>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a href="#" @click.prevent="() => openModal('income-create')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                            Receita
                                        </a>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a href="#"  @click.prevent="() => openModal('transference-create')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                            Transferência
                                        </a>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>

                    <Menu as="div" class="relative">
                        <div>
                            <MenuButton class="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-xs font-normal text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto">
                                <span class="sr-only">Abrir opções</span>
                                <span class="text-xs">Outras ações</span>
                            </MenuButton>
                        </div>

                        <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                            <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1 divide-y divide-gray-200">
                                    <div class="pt-1 cursor-default">
                                        <div class="px-5 py-2 flex flex-row items-center">
                                            <span class="text-xs text-gray-400">OFX</span>
                                            <span class="text-xs text-red-400 font-semibold">&nbsp;(NEW!)</span>
                                        </div>
                                        <MenuItem class="mb-1" v-slot="{ active }">
                                            <router-link tag="a" :to="{name: 'company.transactions.import'}" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                Importar lançamentos
                                            </router-link>
                                        </MenuItem>
                                    </div>
                                    <MenuItems>
                                        <div class="pt-1 cursor-default">
                                            <div class="px-5 py-2 flex flex-row items-center">
                                                <span class="text-xs text-gray-400">Exportar lançamentos listados</span>
                                            </div>
                                            <MenuItem v-slot="{ active }">
                                                <a href="#" @click.prevent="() => exportAsPng()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                    PNG
                                                </a>
                                            </MenuItem>
                                            <MenuItem v-slot="{ active }">
                                                <a href="#" @click.prevent="() => exportAsCSV()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                    CSV
                                                </a>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </div>
                            </MenuItems>
                        </transition>
                    </Menu>
                </div>
            </div>
            <!--/HEADER-->

            <!--FILTERS-->
            <div class="pb-4 pt-2 px-4" :class="[isFiltered ? 'bg-orange-50' : 'bg-gray-50']">
                <div>
                    <ul role="list" class="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 lg:flex items-end justify-between">
                        <li class="rounded-lg">
                            <label class="block text-xs font-medium text-gray-400">
                                Descrição
                            </label>
                            <input
                                v-model="filter.description"
                                id="description" 
                                name="description" 
                                type="text" 
                                autocomplete="off"
                                :required="true" 
                                placeholder="Descrição"
                                class="mt-1 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" 
                            />
                        </li>
                        <li class="rounded-lg lg:w-48">
                            <label class="block text-xs font-medium text-gray-400">
                                Conta
                            </label>
                            <select v-model="filter.account"
                                class="mt-1 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" 
                            >
                                <option selected :value="undefined">Todas</option>
                                <option v-for="account in filter.accounts" :key="account.id" :value="account">{{ account.name }}</option>
                            </select>
                        </li>
                        <li class="rounded-lg">
                            <label class="block text-xs font-medium text-gray-400">
                                Tipo
                            </label>
                            <select v-model="filter.kind" 
                                class="mt-1 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" 
                            >
                                <option :value="undefined">Todos</option>
                                <option value="income">Receita</option>
                                <option value="expense">Despesa</option>
                                <option value="transference">Transferência</option>
                            </select>
                        </li>
                        <li class="rounded-lg lg:w-48">
                            <label class="inline text-xs font-medium p-0 m-0 text-gray-400">
                                Categoria
                            </label>
                            <CategorySelect
                                v-model="filter.category" 
                                :kind="filter.kind"
                                :show-empty="true"
                                class="mt-0 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" 
                            />
                        </li>
                        <li class="rounded-lg">
                            <label class="block text-xs font-medium text-gray-400">
                                Pago
                            </label>
                            <select v-model="filter.paid" 
                                class="mt-1 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" 
                            >
                                <option :value="undefined">Pago e Não pago</option>
                                <option :value="1">Pago</option>
                                <option :value="0">Não pago</option>
                            </select>
                        </li>
                        <li class="rounded-lg">
                            <label class="block text-xs font-medium text-gray-400">
                                Periodicidade
                            </label>
                            <select v-model="filter.limit_by"
                                class="mt-1 block w-full rounded-md py-2 text-base sm:text-xs border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500" 
                            >
                                <option selected :value="undefined">Todas</option>
                                <option selected value="DATE">Fixo</option>
                                <option selected value="COUNT">Parcelado</option>
                            </select>
                        </li>
                        <li class="rounded-lg flex items-end">
                            <button 
                                type="button" 
                                @click.prevent="() => clearFilter()" 
                                :disabled="!isFiltered" 
                                class="mr-2 inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition"
                                :class="[isFiltered ? 'bg-orange-400 hover:bg-orange-500' : 'bg-gray-400 hover:bg-gray-400 opacity-60 cursor-not-allowed']"
                                >
                                <XIcon class="h-4 w-4" />
                            </button>
                            <button type="button" 
                                @click.prevent="() => filterResults(true)" 
                                :disabled="isLoading" 
                                class="inline-flex justify-center py-2 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white transition"
                                :class="[isFiltered ? 'bg-orange-400 hover:bg-orange-500' : 'bg-gray-400 hover:bg-gray-500']"
                            >
                                <FilterIcon class="h-4 w-4" />
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <!--/FILTERS-->

            <!--TRANSACTIONS-->
            <div class="mt-4 py-2 px-4">
                <div v-if="transactions.length">
                <div v-for="{transactions, balance},date in transactionsGrouped" class="static">
                    <div class="mt-2 flex flex-col ml-10 xl:ml-0 overflow-x-auto overflow-hidden">
                        <div class="flex flex-row text-sm">
                            <div class="absolute left-4 xl:-left-12 flex flex-col text-gray-400 font-medium mr-4 items-end justify-center">
                                <span class="text-md">{{ dayjs(date).format("DD") }}</span>
                                <span class="text-xs">{{ dayjs(date).format("MMM") }}</span>
                                <span v-if="filterBetweenYears" class="text-sm text-gray-400 ">
                                    {{ dayjs(date).format("YYYY") }}
                                </span>
                            </div>

                            <div class="flex flex-col flex-1 text-xs divide-y divide-gray-200">
                                <div v-for="transaction in transactions" :key="transaction.id" 
                                    class="flex space-x-1 relative justify-between w-min-full py-2 px-2 cursor-pointer hover:bg-gray-100 transition duration-200"
                                    :class="{'bg-yellow-50': transaction.is_overdue }"
                                    @click="openDetail(transaction)"
                                >
                                    <div class="flex items-center font-normal text-gray-400 w-1/2">
                                        <TransactionKindBadge :kind="transaction.kind" class="mr-2" />
                                        <span v-if="transaction.is_overdue" title="Lançamento atrasado" class="mr-2">
                                            <ExclamationIcon class="h-4 w-4 inline text-orange-200" /> 
                                        </span>
                                        <span class="text-gray-600">{{ transaction.description }}</span>
                                        <span v-if="transaction.is_recurring && transaction.recurrence?.limit_by == 'COUNT'" class="text-xs text-gray-400">
                                            ({{ transaction.recurrence_index }}/{{ transaction.recurrence_total }})
                                        </span>
                                    </div>
                                    
                                    <div class="flex flex-1 w-24 justify-end" title="Conta">
                                        <RecurringIcon :type="transaction.recurrence?.limit_by" class="text-gray-400 mr-2" />
                                        <span v-if="transaction.origin_account && transaction.target_account">
                                            De: <router-link :to="{name:'company.accounts.detail', params: {id: transaction.origin_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                                {{ transaction.origin_account.name }}
                                            </router-link><br />
                                            Para: <router-link :to="{name:'company.accounts.detail', params: {id: transaction.target_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                                {{ transaction.target_account.name }}
                                            </router-link>
                                        </span>
                                        <span v-else-if="transaction.origin_account">
                                            <router-link :to="{name:'company.accounts.detail', params: {id: transaction.origin_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                                {{ transaction.origin_account.name }}
                                            </router-link>
                                        </span>
                                        <span v-else-if="transaction.target_account">
                                            <router-link :to="{name:'company.accounts.detail', params: {id: transaction.target_account_id }}" tag="a" class="truncate text-gray-400 hover:text-gray-500 hover:underline">
                                                {{ transaction.target_account.name }}
                                            </router-link>
                                        </span>
                                    </div>

                                    <div class="flex flex-1 w-24 justify-end items-center" title="Categoria">
                                        <CategoryBadge :kind="transaction.kind" :text="transaction.category?.name" />
                                    </div>

                                    <div class="flex-1 w-24 text-right justify-end">
                                        <span :class="[transaction.amount < 0 ? 'text-red-500' : 'text-blue-500']">
                                            {{ Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                        </span>
                                    </div>
                                    
                                    <div class="px-2">
                                        <div v-if="isLoadingPaid && selectedTransaction.id == transaction.id">
                                            <Spinner border-color="border-gray-500" size="h-4 w-4" border-size="border-2" />
                                        </div>
                                        <div v-else>
                                            <div v-if="transaction.is_payable">
                                                <a href="#" @click.prevent.stop="setPaid(transaction, false)" v-if="transaction.paid" title="Marcar como não-paga">
                                                    <ThumbUpIcon class="h-4 w-4 inline text-green-500" aria-hidden="true" />
                                                </a>
                                                <a v-else href="#" @click.prevent.stop="setPaid(transaction, true)" :disabled="!transaction.is_payable" title="Marcar como paga" :class="{'disabled:opacity-75': !transaction.is_payable}">
                                                    <ThumbDownIcon class="h-4 w-4 inline text-gray-400" aria-hidden="true" />
                                                </a>
                                            </div>
                                            <div v-else title="Não se pode mudar status de saldo inicial" >
                                                <ThumbUpIcon class="h-4 w-4 inline text-green-200" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex py-2 pl-2 pr-8 bg-gray-100 text-xs opacity-80 hover:opacity-100 duration-200 justify-between">
                            <span class="text-gray-400">{{ dayjs(date).format("DD [de] MMMM") }}</span>
                            <div class="flex gap-4">
                                <span class="text-gray-400">Saldo do dia</span>
                                <span :class="{ 'text-red-500': balance < 0, 'text-blue-500': balance > 0, 'text-gray-400': balance === 0 }">
                                    {{ Dinero({ amount: balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                </span>
                            </div>               
                        </div>               
                    </div>
                </div>
                <div v-if="!isLoadingMore" ref="triggerFooter"></div>

                <!-- BALANCE RESUME -->
                <div 
                    class="sticky relative bottom-0 text-sm"
                    :class="[!footerIsVisible ? 'shadow-up bg-white' : 'bg-white']"
                >
                    <Overlay opacity-class="opacity-60" :show="isLoadingBalance">
                        <Spinner border-color="border-gray-300" />
                    </Overlay>
                    
                    <div class="flex flex-col items-end p-8">
                        <div v-if="showBalanceDetails">
                            <span class="text-right text-gray-500">
                                Saldo anterior
                            </span>
                            <span class="text-right text-green-700 w-32 inline-block">
                                {{ Dinero({ amount: balances.balance?.previous_balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>

                        <div v-if="showBalanceDetails">
                            <span class="text-right text-gray-500">
                                Receita realizada
                            </span>
                            <span class="text-right text-green-700 w-32 inline-block">
                                {{ Dinero({ amount: balances.incomes?.consolidated,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>

                        <div v-if="showBalanceDetails">
                            <span class="text-right text-gray-400">
                                Receita prevista
                            </span>
                            <span class="text-right text-gray-400 w-32 inline-block">
                                {{ Dinero({ amount: balances.incomes?.predicted,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>

                        <div v-if="showBalanceDetails">
                            <span class="text-right text-gray-500">
                                Despesa realizada
                            </span>
                            <span class="text-right text-gray-400 w-32 inline-block">
                                <span class="text-red-500">
                                    {{ Dinero({ amount: balances.expenses?.consolidated,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                </span>
                            </span>
                        </div>
                        
                        <div v-if="showBalanceDetails">
                            <span class="text-right text-gray-400">
                                Despesa prevista
                            </span>
                            <span class="text-right text-gray-400 w-32 inline-block">
                                {{ Dinero({ amount: balances.expenses?.predicted,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>

                        <br v-if="showBalanceDetails" />

                        <div>
                            <span class="text-right text-gray-500">
                                Saldo
                            </span>
                            <span class="text-right text-gray-400 font-medium w-32 inline-block">
                                <span :class="[balances.balance?.consolidated < 0 ? 'text-red-500' : 'text-blue-500']">
                                    {{ Dinero({ amount: (balances.balance?.previous_balance + balances.balance?.consolidated),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                </span>
                            </span>
                        </div>

                        <div>
                            <span class="text-right text-gray-400">
                                Previsto
                            </span>
                            <span class="text-right text-gray-400 font-medium w-32 inline-block">
                                {{ Dinero({ amount: (balances.balance?.previous_balance + balances.balance?.predicted),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>

                        <div class="mt-4 text-xs text-gray-400">
                            <a href="#" @click.prevent="toggleBalanceDetails" class="flex items-center">
                                <PlusIcon class="w-4 h-4"/> Detalhes
                            </a>
                        </div>
                    </div>
                </div>
                <!--/BALANCE RESUME-->  
            </div>
            <div v-else class="flex flex-col items-center justify-center p-24 text-gray-400">
                <ExclamationIconOutline class="w-24 h-24" />
                <span class="text-lg">Sem lançamentos no período</span>
            </div>
            <!--/TRANSACTIONS-->
        </div>
    </div>

    <TransactionDetailOverlay 
        :open.sync="isDetailOpen" 
        :transaction="selectedTransaction"
        v-show="isDetailOpen" 
        @close="closeDetail"
        @updated="() => { closeDetail(); onUpdated(); }"
        @deleted="() => { closeDetail(); onDeleted(); }"
        class="z-50"
    />

    <Modal :show="modalOpen" @close="() => modalOpen = false" :static="true" #default="{slotProps}">
        <Component
            :is="modalComponent"
            @created="() => { slotProps.close(); onCreated(); }"
            @updated="() => { slotProps.close(); onUpdated(); }"
            @deleted="() => { slotProps.close(); onDeleted(); }"
            @canceled="slotProps.close(); onCanceled()" 
            @error="(error: any) => { slotProps.close(); onError(error); }" 
        />
    </Modal>
</div>
<div class="relative" id="reportInfoMain" v-if="transactions.length" style="clip-path: inset(0 100% 0 0);margin-top: -40vh;">
    <div class="rounded-lg bg-white shadow">
        <div id="exporTransactions" class="relative overflow-visible pb-6">
            <!--OCULTTRANSACTIONS-->
            <div class="mt-4 py-2 px-4">
                <div>
                    <div id="reportInfo">
                        <h1 class="text-md p-2 text-center">Movimentações</h1>
                        <div class="text-center">
                            <span id="reportInfoDate">Perído: dd/mm/yyyy - dd/mm/yyyy</span>
                        </div>
                        <div class="text-left">
                            <span id="balancebefore">Saldo Anterior: {{ Dinero({ amount: balances.balance?.previous_balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                        </div>
                        <hr class="mt-5" style="border-top: 5px solid #bbb;border-radius: 2px;"/>
                    </div>
                    <div class="table w-full mt-3">
                        <div class="table-row-group">
                            <div style="display:grid;grid-template-columns: 1.5fr 1fr 2.5fr 3fr 2.5fr 2fr 1fr;">
                                <div><span>Tipo</span></div>
                                <div><span>Data</span></div>
                                <div><span>Descrição</span></div>
                                <div><span>Conta</span></div>
                                <div><span>Categoria</span></div>
                                <div><span>Valor</span></div>
                                <div><span>Situação</span></div>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-5" style="border-top: 5px solid #bbb;border-radius: 2px;"/>
                    <div v-for="{transactions}, date, index in transactionsGrouped">
                        <div class="flex flex-col flex-1 text-xs">
                            <div v-for="transaction in transactions" :key="transaction.id" style="display:grid;grid-template-columns: 1.5fr 1fr 2.5fr 3fr 2.5fr 2fr 1fr;" class="flex relative justify-between w-min-full py-2">
                                <div class="flex w-24 justify-start items-center" title="Tipo">
                                    <span class="text-md">{{ translateKind(transaction.kind as string) }}</span>
                                </div>

                                <div class="flex flex-col items-start justify-center" title="Data">
                                    <span class="text-md">{{ dayjs(transaction.billing_date).format("DD/MM/YYYY") }}</span>
                                </div>

                                <div class="flex flex-col items-start justify-center" title="Descrição">
                                    <span class="">{{ transaction.description }}</span>
                                    <span v-if="transaction.is_recurring && transaction.recurrence?.limit_by == 'COUNT'" class="text-xs">
                                        ({{ transaction.recurrence_index }}/{{ transaction.recurrence_total }})
                                    </span>
                                </div>
                                
                                <div class="flex flex-col items-start justify-center" title="Conta">
                                    <span v-if="transaction.origin_account && transaction.target_account" style="white-space: nowrap;">
                                        De: {{ transaction.origin_account.name }}
                                        <br/>
                                        Para: {{ transaction.target_account.name }}
                                    </span>
                                    <span v-else-if="transaction.origin_account">
                                        {{ transaction.origin_account.name }}
                                    </span>
                                    <span v-else-if="transaction.target_account">
                                        {{ transaction.target_account.name }}
                                    </span>
                                </div>

                                <div class="flex flex-col items-start justify-center" title="Categoria do Usuário">
                                    <span>{{ transaction.category?.name }}</span>
                                </div>

                                <div class="flex flex-col items-start justify-center" title="Valor">
                                    <span>{{ Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}</span>
                                </div>

                                <div class="flex flex-col items-start justify-start">
                                    <div v-if="isLoadingPaid && selectedTransaction.id == transaction.id">
                                        <span>--</span>
                                    </div>
                                    <div v-else>
                                        <span>{{ transaction.paid ? 'Pago' : 'Não pago' }}</span>
                                    </div>
                                </div>
                            </div>
                            <hr v-if="index != Object.keys(transactionsGrouped).length - 1" class="mt-5 mb-5 w" style="border-top: 2px solid #bbb;border-radius: 2px;"/>
                        </div>
                    </div>
                    <div class="mt-8">
                        <div class="flex flex-col items-end">
                            <div>
                                <span class="text-right">
                                    Saldo
                                </span>
                                <span class="text-right w-32 inline-block">
                                    <span :class="[balances.balance?.consolidated < 0 ? 'text-red-500' : 'text-blue-500']">
                                        {{ Dinero({ amount: (balances.balance?.previous_balance + balances.balance?.consolidated),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                    </span>
                                </span>
                            </div>
                            <div>
                                <span class="text-right">
                                    Previsto
                                </span>
                                <span class="text-right w-32 inline-block">
                                    {{ Dinero({ amount: (balances.balance?.previous_balance + balances.balance?.predicted),  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                </span>
                            </div>
                        </div>
                        <h1 class="text-left" style="color: #999999; font-size: 1.5vh" id="reportInfoIssue">Relatório emitido em: dd/mm/yyy</h1>
                    </div>
                </div>
            <!--/OCULTTRANSACTIONS-->
            </div>
        </div>
    </div>
</div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref, shallowRef } from 'vue';
import { computed } from '@vue/reactivity';
import { useElementVisibility, useRefHistory, whenever } from '@vueuse/core';

import { 
    Menu, 
    MenuButton, 
    MenuItem, 
    MenuItems, 
} from '@headlessui/vue';

import { 
    ThumbUpIcon,
    ExclamationIcon,
    PlusIcon,
    XIcon
} from '@heroicons/vue/solid';

import { 
    ThumbDownIcon,
    ExclamationIcon as ExclamationIconOutline,
    FilterIcon,
    DownloadIcon
} from '@heroicons/vue/outline';

import dayjs from 'dayjs'
import Dinero from 'dinero.js';
import { groupBy, mapValues, sumBy, filter as filterBy, cloneDeep } from 'lodash';

import TransactionService from '@/services/TransactionService';
import TransactionType from '@/types/Transaction/TransactionType';
import Account from '@/types/Account';
import AccountService from '@/services/AccountService';
import { Toast } from '@/constants/swal-mixins';

import Spinner from '@/components/Spinner.vue';
import Overlay from '@/components/Overlay.vue';
import CategoryBadge from '@/components/Categories/CategoryBadge.vue';
import TransactionDetailOverlay from '@/components/Transactions/TransactionDetailOverlay.vue';
import CreateExpense from '@/components/Transactions/CreateExpense.vue';
import CreateIncome from '@/components/Transactions/CreateIncome.vue';
import CreateTransference from '@/components/Transactions/CreateTransference.vue';
import Modal from '@/components/Modal.vue';
import RecurringIcon from '@/components/RecurringIcon.vue';
import Category from '@/types/Category/Category';
import CategorySelect from '@/components/Categories/CategorySelect.vue';
import BalanceService from '@/services/BalanceService';
import Transaction from '@/types/Transaction';
import TransactionKindBadge from '@/components/Transactions/TransactionKindBadge.vue';
import { useRoute } from 'vue-router';
import TransactionFilters from '@/components/Transactions/TransactionFilters.vue';
import { IImageToCompile, combine } from '../../../modules/Export';
import * as htmlToImage from 'html-to-image';
import headerImage from '/src/assets/logo-menu.png';
import {store} from '@/store';

interface Filter{ 
    date_start: Date,
    date_end: Date,
    description?: string|undefined,
    kind?: string|undefined
    paid?: boolean|undefined
    account?: AccountSelect,
    accounts?: Account[]
    limit_by?: string,
    category_id?: number|undefined,
    category?: Category,
    limit?: number,
    page?: number|undefined,
    last_page?: number|undefined,
    from?: number|undefined,
    to?: number|undefined,
    per_page?: number|undefined,
    total?: number|undefined,
}

interface ModalComponentsType {
    key: string,
    component: any
}

interface AccountSelect {
    id?: string|null,
    name?: string
}

const route = useRoute();
const isLoading = ref(false);
const isLoadingMore = ref(false);
const isLoadingBalance = ref(false);
const isLoadingPaid = ref(false);
const isDetailOpen = ref(false);
const isFiltered = ref(false);

const reportInfo = ref<string>("");

const filter = ref<Filter>({
    date_start: dayjs().startOf('month').toDate(),
    date_end:  dayjs().endOf('month').toDate(),
    description: '',
    category: {} as Category,
    kind: undefined,
    paid: undefined,
    account: undefined,
    accounts: [] as Account[],
    limit_by: undefined,
    limit: 10,
    page: 1,
    last_page: undefined,
    from: undefined,
    to: undefined,
    per_page: 15,
    total: 25,
});

const { history, commit, clear} = useRefHistory<Filter>(filter, { clone: cloneDeep })

const filterBetweenYears = computed(() => {
    return dayjs(filter.value.date_start).year() !== dayjs(filter.value.date_end).year()
})

const transactions = ref<Transaction[]>([]);
const transactionsGrouped = computed(() => {
    return Object.assign({}, groupByDate(transactions.value)); 
});
const showBalanceDetails = ref(false);

const page = ref(1);
const total = ref(0);
const totalLoaded = computed( () => transactions.value.length );
const hasMore = computed( () => totalLoaded.value < total.value);

const triggerHeader = ref<HTMLElement|null>(null);
const triggerFooter = ref<HTMLElement|null>(null);

const headerIsVisible = useElementVisibility(triggerHeader)
const footerIsVisible = useElementVisibility(triggerFooter)

const loadMore = computed( () => hasMore.value && footerIsVisible.value)

whenever(loadMore, async () => await loadTransactions())

const initialDate = ref(new Date());
const endDate = ref(new Date());

const balances = ref({
    balance: {
        predicted: 0,
        consolidated: 0,
        previous_balance: 0
    },
    incomes: {
        predicted: 0,
        consolidated: 0
    },
    expenses: {
        predicted: 0,
        consolidated: 0
    }
});

const modalComponents: ModalComponentsType[] = [
    { key: "expense-create", component: CreateExpense },
    { key: "income-create", component: CreateIncome },
    { key: "transference-create", component: CreateTransference },
];

const modalComponent = shallowRef(CreateExpense);

const modalOpen = ref(false);

const selectedTransaction = ref<TransactionType>({} as TransactionType);

const toggleBalanceDetails = () => {
    showBalanceDetails.value = !showBalanceDetails.value;
}

const closeDetail = () => {
    isDetailOpen.value = false;
}

const openDetail = (transaction: TransactionType) => {
    selectedTransaction.value = transaction;
    isDetailOpen.value = true;
}

const setModalIsOpen = (value: boolean) => {
    modalOpen.value = value
}

const setModalComponent = (value: string) => {
    modalComponent.value = modalComponents.find(component => component.key == value)?.component
}

const openModal = (value: string) => {
    setModalComponent(value);
    setModalIsOpen(true);
}

const onCreated = async () => {
    Toast.fire({
        icon: 'success',
        title: `Transação criada com sucesso`,
        iconColor: 'white',
    })
    await loadAllAmounts(true);
}

const onUpdated = async () => {
    Toast.fire({
        icon: 'success',
        title: `Atualizado com sucesso`,
        iconColor: 'white',
    })
    await loadAllAmounts(true);
}

const onDeleted = async () => {
    await loadAllAmounts(true);
}

const onCanceled = () => {
    //loadAll()
}

const onError = (error: any) => {
    Toast.fire({
        icon: 'error',
        title: `Erro ao criar a transação`,
        iconColor: 'white',
    })
}

const loadTransactions = async () => {
    isLoadingMore.value = true;

    try {
        let response = await TransactionService.list({
            billing_date_start: dayjs(filter.value.date_start).format('YYYY-MM-DD'),
            billing_date_end: dayjs(filter.value.date_end).format('YYYY-MM-DD'),
            description: filter.value.description,
            kind: filter.value.kind,
            paid: filter.value.paid,
            account_id: filter.value.account?.id,
            category_id: filter.value.category?.id,
            limit_by: filter.value.limit_by,
            load_category: true,
            load_account: true,
            load_recurrence: true,
            sort: 'billing_date',
            paginate: true,
            page: page.value, 
            per_page: 10
        });
        
        transactions.value.push(...response.data.data);
        total.value = response.data.meta.total;
        setTimeout(() => {
            const divToResize = document.getElementById('reportInfoMain');
            const divUserInfo = document.getElementById('divUserInfo');
            if (divUserInfo && divToResize) {
                if (transactions.value.length) {
                    const toSum = divToResize.clientHeight;
                    divToResize.style.cssText = `clip-path: inset(0px 100% 0px 0px); margin-top: -${toSum}px;`;
                } else {
                    divToResize.style.cssText = 'clip-path: inset(0px 100% 0px 0px); margin-top: -40vh;';
                }
            }
        }, 150);

        if(totalLoaded.value <= total.value) {
            page.value++;
        }
        isLoadingMore.value = false;
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao carregar os lançamentos!',
            iconColor: 'white',
        })
        isLoadingMore.value = false;
    }
}

const groupByDate = (transactions: Transaction[]) => {
    let grouped = groupBy(transactions, (item) => dayjs(item.billing_date).format('YYYY-MM-DD'))
    let previous_balance = balances.value.balance.previous_balance;

    return mapValues(grouped, (item) => {
        let paid = filterBy(item, { paid: true });
        let balance = 0;

        paid.forEach(transation => {
            if (isFiltered.value) {
                if (filter.value.account) {
                    if (transation.kind == 'transference' && transation.origin_account_id == filter.value.account.id) {
                        balance -= transation.amount;
                    } else {
                        balance += transation.amount;
                    }
                } else {
                    if (transation.kind != 'transference') {
                        balance += transation.amount;
                    }
                }
            } else {
                if (transation.kind != 'transference') {
                    balance += transation.amount;
                }
            }
        });
        previous_balance += balance;
        return { transactions: item, balance: previous_balance }
    });
}

const loadBalances = async () => {
    isLoadingBalance.value = true;
    try {
        let response = await BalanceService.resume({
            date_start: dayjs(filter.value.date_start).format('YYYY-MM-DD'),
            date_end: dayjs(filter.value.date_end).format('YYYY-MM-DD'),
            account_id: filter.value.account?.id, 
            paid: filter.value.paid, 
            //limit: filter.limit
        });
        balances.value = response.data.data;

        let currentCompany = store.getters['user/currentCompany'];
        reportInfo.value = `Relatorio-Movimentacoes-${currentCompany.name}-${dayjs(filter.value.date_start).format('DD/MM/YYYY')}-${dayjs(filter.value.date_end).format('DD/MM/YYYY')}`;
        isLoadingBalance.value = false;
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao carregar o saldo!',
            iconColor: 'white',
        })
        isLoadingBalance.value = false;
    }
}

const setPaid = async(transaction: TransactionType, pay: boolean) => {
    isLoadingPaid.value = true;
    selectedTransaction.value = transaction;
    try {
        if(transaction.id) {
            let response = (pay) ? await TransactionService.pay(transaction.id) : await TransactionService.unpay(transaction.id);
            transaction.paid = pay;
            transaction.is_overdue = response.data.data.is_overdue
        }

        Toast.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Lançamento atualizado com sucesso!',
            iconColor: 'white',
        })

        await loadAllAmounts(false);
        isLoadingPaid.value = false;
    } catch( error ) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao atualizar o lançamento!',
            iconColor: 'white',
        })
        isLoadingPaid.value = false;
    }
}

const loadAccounts = async () => {
    try {
        let response = await AccountService.list({paginate: false})
        let accountParam = route.query.account;
        let accounts = response.data.data;
        
        filter.value.accounts = accounts;
        
        if(accountParam) {
            filter.value.account = filter.value.accounts?.find((item) => item.id === accountParam)
        }
        
    } catch(error) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao carregar as contas!',
            iconColor: 'white',
        })
    }
}

const setPeriod = async (period: any) => {
    filter.value.date_start = period.date_start;
    filter.value.date_end = period.date_end;
    initialDate.value = period.date_start;
    endDate.value = period.date_end;
    
    await filterResults(false);
}

const filterResults = async (filtered = false) => 
{
    isFiltered.value = filtered;

    await loadAllAmounts(true)
}

const clearFilter = async () => 
{
    isFiltered.value = false;
    clear();

    filter.value = history.value[0].snapshot;
    filter.value.date_start = initialDate.value;
    filter.value.date_end = endDate.value;

    await loadAllAmounts(true)
}

const loadAllAmounts = async (refresh?: boolean) => {
    page.value = 1;
    total.value = 0;

    if(refresh) {
        isLoading.value = true;
        transactions.value = [];
        await loadTransactions();
    }

    await loadBalances();
    isLoading.value = false;
}

function translateKind(name: string): string {
    switch (name) {
        case 'transference':
            return 'Transferência';
        case 'expense':
            return 'Despesa';
        case 'income':
            return 'Receita';
    }

    return name;
}

async function exportAsCSV() {
    const data: {}[] = [];

    transactions.value.forEach(transaction => {
        let description = transaction.description;
        if (transaction.is_recurring && transaction.recurrence?.limit_by == 'COUNT') {
            description += `(${transaction.recurrence_index}/${transaction.recurrence_total})`;
        }

        let accountFormat = '--';
        if (transaction.origin_account && transaction.target_account) {
            accountFormat = `"De: ${transaction.origin_account.name}\rPara: ${transaction.target_account.name}"`;
        } else if (transaction.origin_account) {
            accountFormat = transaction.origin_account.name
        } else if (transaction.target_account) {
            accountFormat = transaction.target_account.name
        }

        data.push({
            "Data": dayjs(transaction.billing_date).format('DD/MM/YYYY'),
            "Tipo": translateKind(transaction.kind as string),
            "Descrição": description,
            "Conta": accountFormat,
            "Categoria": transaction.category?.name,
            "Valor (R$)": Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat(),
            "Situação": (isLoadingPaid && selectedTransaction.value.id == transaction.id) ? '--' : transaction.paid ? 'Pago' : 'Não pago'
        });
    });

    let csvHeader = Object.keys(data[0]).join(';') + '\n';
    let csvBody = data.map(row => Object.values(row).join(';')).join('\n');
    csvBody = csvBody + "\n" +
    `;;;;Saldo anterior;${Dinero({ amount: balances.value.balance.previous_balance, currency: 'BRL' }).setLocale('pt-BR').toFormat()}
    ;;;;Saldo previsto;${Dinero({ amount: (balances.value.balance.previous_balance + balances.value.balance.predicted),  currency: 'BRL' }).setLocale('pt-BR').toFormat()}`;

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvHeader + csvBody);
    hiddenElement.target = '_blank';
    hiddenElement.download = reportInfo.value + '.csv';
    hiddenElement.click();
}

async function exportAsPng() {
    try {
        const imagesToMerge: IImageToCompile[] = [];
        const reportInfoDateDiv = document.getElementById('reportInfoDate');
        const reportInfoIssueDiv = document.getElementById('reportInfoIssue');
        const exporTransactionsDiv = document.getElementById('exporTransactions');
    
        let xPosition = 420;
        let yPosition = 0;
    
        imagesToMerge.push({ src: headerImage, x: xPosition, y: yPosition });
        xPosition = 0;
        yPosition += 150;
    
        if (reportInfoDateDiv && reportInfoIssueDiv) {
            reportInfoIssueDiv.innerHTML = `Relatório emitido em: ${dayjs().format('DD/MM/YYYY')}`;
            reportInfoDateDiv.innerHTML = `Perído: ${dayjs(filter.value.date_start).format('DD/MM/YYYY')} - ${dayjs(filter.value.date_end).format('DD/MM/YYYY')}`;
        }
    
        if (exporTransactionsDiv) {
            await htmlToImage.toPng(exporTransactionsDiv, { backgroundColor: '#fff', pixelRatio: 1, width: 1220, height: exporTransactionsDiv.clientHeight + 35}).then(function (dataUrl) {
                imagesToMerge.push({ src: dataUrl, x: xPosition, y: yPosition });
                if (exporTransactionsDiv) {
                    yPosition += Math.floor(exporTransactionsDiv.clientHeight) + 318;
                }
            }).catch(function (error: any) {
                Toast.fire({
                    icon: 'error',
                    title: `Erro ao exportar a movimentações como PNG(2).`,
                    iconColor: 'white',
                });
            });
        }
    
        const reportImage = await combine(imagesToMerge, {backgroundColor: '#fff', quality: 1, width: 1220, height: yPosition});
    
        var a = document.createElement("a");
        a.href = reportImage;
        a.download = `${reportInfo.value}.png`;
        a.click();
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: `Erro ao exportar a movimentações como PNG(3).`,
            iconColor: 'white',
        });
    }
}

onMounted( async () => {
    isLoading.value = true;
    await loadAccounts();
    commit();
    isLoading.value = false;
})
</script>

<style>
.shadow-up {
    filter: box-shadow(0 -2px 3px rgba(155, 155, 155, 0.25));
}

.shadow-down {
    @apply shadow-lg;
}

.shadow-down > *{
    filter: none;
}

.no-shadow {
    filter: none;
}
</style>