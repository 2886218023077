<template>
<div class="">
    <div class="mb-2">
        <div v-if="isRecurring">
            <RadioGroup v-model="limitBy" class="mt-1">
                <div class="space-y-1">
                    <RadioGroupOption as="template" value="DATE" v-slot="{ checked, active }">
                        <div :class="['relative block bg-white py-1 cursor-pointer focus:outline-none']">
                            <span class="flex flex-col">
                                <span class="text-sm flex justify-start">
                                    <CheckCircleIcon :class="[!checked ? 'text-gray-200' : '', 'text-indigo-600', 'h-5 w-5']" aria-hidden="true" />
                                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-600' : 'text-gray-400']" class="font-medium">Fixo</RadioGroupLabel>
                                </span>
                            </span>
                            <!--<RadioGroupDescription as="span" class="mt-1 flex items-center text-xs text-gray-300">O lançamento se repetirá por um período fixo</RadioGroupDescription>-->
                        </div>
                    </RadioGroupOption>

                    <RadioGroupOption as="template" value="COUNT" v-slot="{ checked, active }">
                        <div :class="['relative block bg-white  py-1 cursor-pointer focus:outline-none']">
                            <span class="flex items-col">
                                <span class="text-sm flex justify-start">
                                    <CheckCircleIcon :class="[!checked ? 'text-gray-200' : '', 'text-indigo-600', 'h-5 w-5']" aria-hidden="true" />
                                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-600' : 'text-gray-400']" class="font-medium">Parcelado</RadioGroupLabel>
                                </span>
                            </span>
                            <!--<RadioGroupDescription as="span" class="mt-1 flex items-center text-xs text-gray-300">O lançamento se repetirá por um período fixo</RadioGroupDescription>-->
                        </div>
                    </RadioGroupOption>

                    <div v-if="limitBy=='DATE'">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">Se repete a cada</label>
                            <div class="sm:grid sm:grid-cols-2 sm:gap-4">
                                <input type="number" :min="1" :max="5" v-model="interval" class="mt-1 w-full rounded-md border-gray-300 py-2 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
                                <select id="location" v-model="frequency" name="location" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                    <option value="YEARLY">{{ $t('count.year', interval) }}</option>
                                    <option value="MONTHLY">{{ $t('count.month', interval) }}</option>
                                    <option value="WEEKLY">{{ $t('count.week', interval) }}</option>
                                    <option value="DAILY">{{ $t('count.day', interval) }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="mt-2">
                            <label class="block text-sm font-medium text-gray-700">Repetir até</label>
                            <v-date-picker v-model="limitDate"  mode="date" :masks="{ input: 'DD/MM/YYYY' }" is-required :input-debounce="100">
                                <template v-slot="{ inputValue, togglePopover, inputEvents }">
                                    <div class="mt-1 relative rounded-md shadow-sm">
                                        <button v-on="inputEvents" @click.prevent="togglePopover()" class="absolute inset-y-0 left-0 pl-3 flex items-center">
                                            <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </button>
                                        <input 
                                            v-on="inputEvents"
                                            :value="inputValue" type="text" name="email" id="email" 
                                            class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md text-gray-700" placeholder=""                                    
                                        />
                                    </div>
                                </template>
                            </v-date-picker>
                        </div>
                    </div>

                    <div v-if="limitBy=='COUNT'">
                        <div>
                            <label class="block text-sm font-medium text-gray-700">É parcelado em </label>
                            <div class="sm:grid sm:grid-cols-2 sm:gap-4">
                                <input type="number" v-model="limitCount" :min="2" :max="120" class="mt-1 w-full rounded-md border-gray-300 py-2 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
                                
                                <select v-model="frequency" class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                                    <option value="YEARLY">{{ $t('count.year', limit_count) }}</option>
                                    <option value="MONTHLY">{{ $t('count.month', limit_count) }}</option>
                                    <option value="WEEKLY">{{ $t('count.week', limit_count) }}</option>
                                    <option value="DAILY">{{ $t('count.day', limit_count) }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </RadioGroup>
        </div>
    </div>

    <div>
        <Switch
            v-model="isRecurring"
            class="flex flex-col items-center text-gray-400"
        >
            <span 
                :class="{'text-gray-400 bg-white': !isRecurring, 'text-white bg-gray-400': isRecurring }"
                class="p-2 border-gray-300 border-2 rounded-full "
            >
                <RefreshIcon 
                    :class="{'text-gray-400': !isRecurring, 'text-white': isRecurring }"
                    class="pointer-events-none h-8 w-8 rounded-lg"  
                />
            </span>
            <span class="text-xs">Repetir</span>
        </Switch>
    </div>
</div>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue'
import { RefreshIcon } from '@heroicons/vue/outline';
import { CheckCircleIcon, CalendarIcon } from '@heroicons/vue/solid'
import {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
} from '@headlessui/vue'
import dayjs from 'dayjs';
import { computed } from '@vue/reactivity';

const emit = defineEmits([
    'update:is_recurring',
    'update:frequency',
    'update:interval',
    'update:started_at',
    'update:limit_by', 
    'update:limit_date', 
    'update:limit_count', 
])


const props = defineProps({
    is_recurring: {
        type: Boolean, 
        default: true
    },
    frequency: {
        type: String, 
        default: 'MONTHLY' 
    },
    interval: {
        type: Number, 
        default: 1 
    },
    started_at: {
        type: Date,
    },
    limit_by: {
        type: String, 
        default: 'DATE'
    },
    limit_date: {
        type: Date,
        default: dayjs(new Date()).add(2, 'year')
    },
    limit_count: {
        type: Number, 
        default: 2
    }
});

const isRecurring = computed({
    get: () => props.is_recurring,
    set: (value) => emit('update:is_recurring', value),
})

const frequency = computed({
    get: () => props.frequency,
    set: (value) => emit('update:frequency', value),
})

const interval = computed({
    get: () => props.interval,
    set: (value) => emit('update:interval', value),
})

const startedAt = computed({
    get: () => props.started_at,
    set: (value) => emit('update:started_at', value),
})

const limitBy = computed({
    get: () => props.limit_by,
    set: (value) => emit('update:limit_by', value),
})

const limitDate = computed({
    get: () => props.limit_date,
    set: (value) => emit('update:limit_date', value),
})

const limitCount = computed({
    get: () => props.limit_count,
    set: (value) => emit('update:limit_count', value),
})

</script>