import { AxiosResponse } from "axios";
import api from "./http/api";
import OAuthClient from "@/types/OAuthClient";

type CreateClient = Pick<OAuthClient, 'name'>;
type UpdateClient = Pick<OAuthClient, 'name'>;

class OAuthClientService {
    list() {
        return api({ auth: true, company: true })
            .get<OAuthClient[]>('/backoffice/v1/integration/clients');
    }

    update(id: string, data: UpdateClient) {
        return api({ auth: true, company: true })
            .put<OAuthClient>(`/backoffice/v1/integration/clients/${id}`, {
                name: data.name,
            });
    }

    create(client: CreateClient) {
        return api({ auth: true, company: true })
            .post<OAuthClient>('/backoffice/v1/integration/clients', {
                name: client.name,
            });
    }

    delete(id: string) {
        return api({ auth: true, company: true })
            .delete<AxiosResponse>(`/backoffice/v1/integration/clients/${id}`);
    }

}

export default new OAuthClientService();