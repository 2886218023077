import dayjs from "dayjs";
import Period from "../Period";
import INavigator from "./INavigator";
import DayFormatter from "../Formatters/DayFormatter";

export default class DayNavigator implements INavigator {
    
    private currentPeriod: Period;
    private label: string = 'Hoje';
    private key: string = 'today';
    private periodicity: string = 'daily';

    constructor(
        private initial: Date,
        private interval: number = 1
    ) {
        this.currentPeriod = {
            start: this.initial,
            end: this.initial
        }
    }

    setPeriod(start: Date, end: Date): void {
        
    }
        
    reset() {
        this.currentPeriod = {
            start: this.initial,
            end: this.initial
        }

        return this.currentPeriod;
    }

    getCurrent(): Period {
        return this.currentPeriod;
    }

    getKey(): string {
        return this.key;
    }

    getLabel(): string {
        return this.label;
    }

    getPeriodicity(): string {
        return this.periodicity;
    }

    getInterval(): number {
        return this.interval;
    }

    getPrevious(): Period {
        const prev = dayjs(this.currentPeriod.start).subtract(this.interval, 'day').toDate();

        this.currentPeriod = { start: prev, end: prev }

        return this.currentPeriod;
    }

    getNext(): Period {
        const next = dayjs(this.currentPeriod.start).add(this.interval, 'day').toDate();

        this.currentPeriod = { start: next, end: next }

        return this.currentPeriod;
    }

    format(): string {
        return new DayFormatter().format(this.currentPeriod);
    }
}