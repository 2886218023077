import { AxiosResponse } from "axios";
import UserCredential from "@/types/Auth/Credential";
import UserRegister from "@/types/Auth/Register";
import Token from "@/types/Auth/Token";
import api from "./http/api";

class AuthService {

    login(user: UserCredential): Promise<AxiosResponse<Token>> {
        return api({ auth:false }).post('/backoffice/v1/auth/login', {
            username: user.username,
            password: user.password
        });
    }

    logout(): Promise<void> {
        return api({ auth: true }).delete('/backoffice/v1/auth/logout');
    }

    register(user: UserRegister): Promise<AxiosResponse> {
        return api({ auth: false }).post('/backoffice/v1/auth/register', {
            name: user.name,
            email: user.email,
            password: user.password,
            password_confirmation: user.password_confirmation
        });
    }

    sendVerificationEmail(email: string) {
        return api({ auth: false }).post('/backoffice/v1/auth/email/send', {
            email
        });
    }

    sendResetPasswordEmail(email: string) {
        return api({ auth: false }).post('/backoffice/v1/auth/password/link', {
            email
        });
    }

    checkName(name: string): Promise<AxiosResponse> {
        return api({ auth: false }).post('/backoffice/v1/auth/register/check-name', {
            name
        });
    }

    checkEmail(email: string): Promise<AxiosResponse> {
        return api({ auth: false }).post('/backoffice/v1/auth/register/check-email', {
            email
        });
    }
}

export default new AuthService();