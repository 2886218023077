<template>
    <div class="flex">
        <a href="#" @click="handlePrevious" class="text-gray-300 hover:text-gray-500 transition">
            <ChevronLeftIcon class="h-8 w-8" />
        </a>

        <div class="flex flex-col self-start items-center justify-start px-6">
            <span class="text-gray-500 text-md font-normal">
                {{ currentPeriodFormated }}
            </span>

            <Listbox as="div" v-model="selectedNavigator">
                <div class="relative z-30">
                <ListboxButton class="w-64 cursor-pointer py-1 text-center text-gray-300 hover:text-gray-500 transition">
                    <span class="pointer-events-none text-center">
                        <ChevronDownIcon class="h-4 w-4 inline-block" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute w-64 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs text-center">
                        <ListboxOption as="template" v-for="navigator in props.navigators" :key="navigator.getLabel()" :value="navigator" v-slot="{ active, selected }">
                            <li @click="() => handleCurrent(navigator)" :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'relative cursor-pointer select-none py-2 pl-3 pr-9']">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block']">{{ navigator.getLabel() }}</span>

                                <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
                </div>
            </Listbox>
        </div>

        <a href="#" @click="handNext" class="text-gray-300 hover:text-gray-500 transition">
            <ChevronRightIcon class="h-8 w-8"/>
        </a>
    </div>

    <Modal :show="modalOpen" @close="() => modalOpen = false" :static="true" #default="{slotProps}">
        <div class="flex items-end sm:items-start p-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Período personalizado</h3>

            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="() => slotProps.close()" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>
        <div class="p-2 w-96">
            <div class="flex gap-2 p-4">
                <div class="">
                    <label for="" class="block text-sm font-medium text-gray-700">Data inicial</label>
                    <v-date-picker 
                        v-model="customDate.start" 
                        mode="date" 
                        :masks="{ input: 'DD/MM/YYYY' }" 
                        is-required 
                        :input-debounce="100"
                        :attributes="[{popover: {visibility: 'hover'}}]"
                        >
                        <template v-slot="{ inputValue, togglePopover, inputEvents }">
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <button @click.prevent="togglePopover()" class="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>

                                <input 
                                    v-on="inputEvents"
                                    :value="inputValue"
                                    type="text" 
                                    class="block w-full pl-10 sm:text-sm rounded-md text-gray-700 border-gray-300 focus:border-indigo-500 focus:ring-indigo-50" placeholder=""
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div class="">
                    <label for="" class="block text-sm font-medium text-gray-700">Data final</label>
                    <v-date-picker 
                        v-model="customDate.end" 
                        mode="date" 
                        :masks="{ input: 'DD/MM/YYYY' }" 
                        is-required 
                        :input-debounce="100"
                        :min-date="customDate.start"
                    >
                        <template v-slot="{ inputValue, togglePopover, inputEvents }">
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <button @click.prevent="togglePopover()" class="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                                <input 
                                    v-on="inputEvents"
                                    :value="inputValue"
                                    type="text"
                                    class="block w-full pl-10 sm:text-sm rounded-md text-gray-700 border-gray-300 focus:border-indigo-500 focus:ring-indigo-50" placeholder=""
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
            </div>
            <div class="flex justify-center">
                <button type="button" @click.stop="() => { modalOpen = false; slotProps.close()}" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                <button type="button" @click.stop="setCustomPeriod" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Filtrar
                </button>
            </div>
        </div>
            
    </Modal>
</template>

<script setup lang="ts">
import { onMounted, ref, toRef } from 'vue';
import { computed } from '@vue/reactivity';
import { useRefHistory } from '@vueuse/core';
import dayjs from 'dayjs';
import { 
    ChevronLeftIcon, 
    ChevronRightIcon, 
    CheckIcon, 
    ChevronDownIcon, 
    CalendarIcon 
} from '@heroicons/vue/outline';

import { XIcon } from '@heroicons/vue/solid';

import { 
    Listbox, 
    ListboxButton, 
    ListboxOption, 
    ListboxOptions 
} from '@headlessui/vue';

import Modal from '@/components/Modal.vue';
import INavigator from './Navigators/INavigator';
import Period from './Period';
import DayNavigator from './Navigators/DayNavigator';
import MonthNavigator from './Navigators/MonthNavigator';

export interface PeriodEventProp {
    periodicity: string;
    interval: number;
    start: string;
    end: string;
};

const emit = defineEmits<{
    (event: 'updated', value: Period): void,
    (event: 'changed', value: PeriodEventProp): void
}>();

const props = defineProps({
    initial: {
        type: String,
    },
    navigators: {
        type: Array<INavigator>,
        require: false,
        default: [
            DayNavigator,
            MonthNavigator
        ]
    }
});

const customDate = ref({
    start: dayjs().startOf("month").toDate(),
    end: dayjs().endOf("month").toDate(),
});
const modalOpen = ref(false);
const navigators = toRef(props, 'navigators');
const selectedNavigator = ref<INavigator>( navigators.value[0])
const navigatorToSelect = ref<INavigator>( {} as INavigator );
const currentPeriodFormated = computed<string>( () => ("getLabel" in selectedNavigator.value) ? selectedNavigator?.value.format() : '' );

function handlePrevious() {
    const period = selectedNavigator?.value.getPrevious();
    
    emit('changed', {
        periodicity: selectedNavigator.value.getPeriodicity(),
        interval: selectedNavigator.value.getInterval(),
        start: dayjs(period.start).format('YYYY-MM-DD'),
        end: dayjs(period.end).format('YYYY-MM-DD')
    });
}

function handNext() {
    
    const period = selectedNavigator?.value.getNext();
    
    emit('changed', {
        periodicity: selectedNavigator.value.getPeriodicity(),
        interval: selectedNavigator.value.getInterval(),
        start: dayjs(period.start).format('YYYY-MM-DD'),
        end: dayjs(period.end).format('YYYY-MM-DD')
    });
}

function handleCurrent(navigator: INavigator) {
    
    if(navigator.getExternal) {
        selectedNavigator.value = {} as INavigator;
        navigatorToSelect.value = navigator;
        modalOpen.value = true;
        return;
    }

    selectedNavigator.value = navigator;

    const period = selectedNavigator.value.reset();

    emit('changed', {
        periodicity: navigator.getPeriodicity(),
        interval: navigator.getInterval(),
        start: dayjs(period.start).format('YYYY-MM-DD'),
        end: dayjs(period.end).format('YYYY-MM-DD')
    });

}

function setCustomPeriod() {
    selectedNavigator.value = navigatorToSelect.value;

    const {start, end} = customDate.value;
    
    selectedNavigator.value.setPeriod(start, end);

    const period = selectedNavigator.value.getCurrent();
    
    emit('changed', {
        periodicity: selectedNavigator.value.getPeriodicity(),
        interval: selectedNavigator.value.getInterval(),
        start: dayjs(period.start).format('YYYY-MM-DD'),
        end: dayjs(period.end).format('YYYY-MM-DD')
    });
    
    modalOpen.value = false;
}

</script>