<template>
<div class="justify-left h-2 w-2 rounded-full bg-gray-200" :class="badgeClass"></div>
</template>

<script setup lang="ts">
import TransactionKindEnum from '@/types/Transaction/TransactionKindEnum';
import { computed } from '@vue/reactivity';

const props = defineProps({
    kind: {
        type: String,
        default: TransactionKindEnum.TRANSFERENCE
    }
})

const badgeClass = computed( () => {
    return {
        'bg-green-400': props.kind == TransactionKindEnum.INCOME,
        'bg-red-400': props.kind == TransactionKindEnum.EXPENSE,
        'bg-gray-400': props.kind == TransactionKindEnum.TRANSFERENCE || props.kind == 'none',
    }
})

</script>