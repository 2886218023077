<template>
<div class="relative overflow-visible p-0 w-96">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="p-4">
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg font-medium text-gray-900 flex-1">Editar conta</h3>

            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="cancel" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>

        <form  @submit.prevent="onSubmit" novalidate>

            <div class="mt-2" v-if="state.type!='other'">
                <label for="bank_id" class="block text-sm font-medium text-gray-700" focus>Banco / Instituição financeira</label>
                <div class="mt-1">
                    <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <BankSelect v-model="selectedBank" />
                    </div>
                </div>
            </div>

            <div class="space-y-4 mt-2">
                <div :class="[v$.name.$error ? 'text-red-500' : 'text-gray-400']">
                    <label for="name" class="block text-sm font-medium" :class="[v$.name.$error ? 'text-red-500' : 'text-gray-700']">Nome da conta</label>
                    <div class="mt-1">
                        <p class="mb-1 text-xs">Informe um nome para identificar sua conta.</p>
                        <input id="name" 
                            name="name" 
                            v-model="state.name" 
                            type="text" autocomplete="off"
                            :required="true" placeholder="Nome da conta"
                            :class="[
                                v$.name.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                            ]"
                        />
                    </div>
                    <div v-if="v$.name.$error" class="mt-1 text-xs text-red-600">
                        <div>{{ v$.name.$errors[0].$message }}</div>
                    </div>
                </div>
                <!--Opening Balance-->
                <div :class="[v$.opening_balance.$error ? 'text-red-500' : 'text-gray-400']">
                    <label for="opening_balance" class="block text-sm font-medium" :class="[v$.opening_balance.$error ? 'text-red-500' : 'text-gray-700']">Saldo inicial</label>
                    <div class="mt-1">
                        <p class="mb-1 text-xs">Para saldo negativo, use o sinal de '-', antes do valor.</p>
                        <CurrencyInput v-model="state.opening_balance" name="opening_balance" :options="{ 
                                currency: 'BRL', 
                                currencyDisplay: 'hidden', 
                                autoDecimalDigits: true, 
                                valueScaling: 'precision',
                                hideGroupingSeparatorOnFocus: false
                            }" 
                            :class="[
                                v$.opening_balance.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                            ]"
                        />
                    </div>
                    <div v-if="v$.opening_balance.$error" class="mt-1 text-xs text-red-600">
                        <div>{{ v$.opening_balance.$errors[0].$message }}</div>
                    </div>
                </div>
                <!--Details-->
                <div>
                    <label for="description" class="block text-sm font-medium text-gray-700">Descrição</label>
                    <div class="mt-1">
                        <textarea id="description" name="name" v-model="state.description" type="text" autocomplete="off"
                            :required="true" placeholder="Descrição"
                            class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
                        <p class="mt-2 text-xs text-gray-400"></p>
                    </div>
                    <div v-if="v$.description.$error" class="text-sm text-red-600">
                        <div>{{ v$.description.$errors[0].$message }}</div>
                    </div>
                </div>
            </div>

            <!--Action buttons-->
            <div class="pt-5">
                <div class="flex justify-center">
                    <button type="reset" @click="cancel" :disabled="isLoading" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                    <button type="submit" :disabled="isLoading" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <Spinner class="mr-2" v-if="isLoading"/> Atualizar conta
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script setup lang="ts">
import { onBeforeMount, reactive, ref } from 'vue';
import { computed } from '@vue/reactivity';
import useVuelidate from '@vuelidate/core'
import { AxiosError } from 'axios';
import { required, helpers, maxLength, numeric } from '@vuelidate/validators';
import { XIcon  } from '@heroicons/vue/outline';

import AccountService from '@/services/AccountService';
import BankSelect from '@/components/Accounts/BankSelect.vue'
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import CurrencyInput from "@/components/CurrencyInput.vue";
import Bank from '@/types/Bank';
import { Toast } from '@/constants/swal-mixins';

const props = defineProps({
    account: {
        type: String,
        required: true
    },
})

const emit = defineEmits(['created', 'updated', 'canceled'])

const isLoading = ref(false);

const selectedBank = ref<Bank>({
    id: '',
    code: '',
    name: ''
})

interface StateType {
    id: string|null
    name: string,
    description: string,
    opening_balance: number,
    bank_id: string|null,
    type: string
}

const state = ref<StateType>({
    id: null,
    name: '',
    description: '',
    opening_balance: 0,
    bank_id: null,
    type: ''
});

const rules = computed(() => ({
    name: {
        required: helpers.withMessage('Obrigatório', required),
        maxLength: helpers.withMessage('Tamanho máximo 50 caracteres', maxLength(50)),
    },
    description: {
        maxLength: helpers.withMessage('Tamanho máximo 150 caracteres', maxLength(150)),
    },
    opening_balance: {
        required: helpers.withMessage('Obrigatório', required),
    },
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const loadAccount = async () => {
    isLoading.value = true;

    try {
        let response = await AccountService.get(props.account);

        state.value = response.data.data;
        selectedBank.value = response.data.data.bank

        isLoading.value = false;
    } catch (error: any | AxiosError) {
        isLoading.value = false;

        Toast.fire({
            icon: 'error',
            title: 'Falha ao obter os dados da conta'
        });
    }
}

const updateAccount = async () => {
    isLoading.value = true;

    const { name, description, opening_balance } = state.value;

    try {
        await AccountService.update(props.account, {
            name,
            description,
            opening_balance,
            bank_id: selectedBank.value.id
        });

        isLoading.value = false;

        emit('updated');
    } catch (error: any | AxiosError) {
        $externalResults.value = { ...error?.response.data.errors };
        isLoading.value = false;
    }
}

const cancel = () => {
    emit('canceled');
}


const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await updateAccount();
}

onBeforeMount( async () => {
    await loadAccount();
})
</script>