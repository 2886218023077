import TransactionTypeEnum from "@/types/Transaction/TransactionTypeEnum";
import { AxiosResponse } from "axios";
import api from "./http/api";


interface BalanceFilter {
    date_start?: string,
    date_end?: string,
    paid?: boolean,
    account_id?: string|null,
}

class BalanceService {
    resume(params: BalanceFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>(`/backoffice/v1/balances/resume`, {
            params
        });
    }

    period(params: BalanceFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>(`/backoffice/v1/balances/period`, {
            params
        });
    }

    total() {
        return api({ auth: true, company: true }).get<AxiosResponse>(`/backoffice/v1/balances/total`);
    }
}

export default new BalanceService();