import UserCredential from "@/types/Auth/Credential"
import UserRegister from "@/types/Auth/Register"
import AuthService from "@/services/AuthService"
import { ActionTree } from "vuex"
import ActionEnum from "./action-enum"
import MutationEnum from "./mutation-enum"
import { ActionsTypes, StateType, Status } from "./types"
import { IRootState } from "@/store/interfaces"

export const actions: ActionTree<StateType, IRootState> & 
    ActionsTypes = {
        [ActionEnum.AUTH_LOGIN]({commit}: any, user: UserCredential) {
            return new Promise((resolve, reject) => {
                commit(MutationEnum.REMEMBER_SAVE, user.remember);
                commit(MutationEnum.LOGIN_STATUS, Status.LOGIN_PROGRESS);
                AuthService.login(user).then( response => {
                    commit(MutationEnum.LOGIN_STATUS, Status.LOGIN_SUCCESS);
                    const { data } = response;
                    commit(MutationEnum.TOKEN_STORE, data);
                    resolve(response);
                }).catch(error => {
                    commit(MutationEnum.LOGIN_STATUS, Status.LOGIN_ERROR);
                    commit(MutationEnum.TOKEN_CLEAR);
                    reject(error);
                });
            });
        },

        [ActionEnum.AUTH_LOGOUT]({commit}: any, user: UserCredential) {
            return new Promise((resolve, reject) => {
                commit(MutationEnum.LOGOUT_STATUS, Status.LOGOUT_PROGRESS);
                 
                AuthService.logout().then( response => {
                    commit(MutationEnum.LOGOUT_STATUS, Status.LOGOUT_SUCCESS);
                    commit(MutationEnum.TOKEN_CLEAR);
                    commit('user/CLEAR_COMPANIES', null, {root: true});
                    commit(MutationEnum.REMEMBER_SAVE, false);
                    resolve(response);
                }).catch(error => {
                    commit(MutationEnum.LOGIN_STATUS, Status.LOGIN_ERROR);
                    commit(MutationEnum.TOKEN_CLEAR);
                    commit('user/CLEAR_COMPANIES', null, {root: true});
                    commit(MutationEnum.REMEMBER_SAVE, false);
                    reject(error);
                });
            });
        },

        [ActionEnum.AUTH_REGISTER]({commit}: any, user: UserRegister) {
            return new Promise((resolve, reject) => {
                commit(MutationEnum.REGISTER_STATUS, Status.REGISTER_PROGRESS);
                AuthService.register(user).then( response => {
                    commit(MutationEnum.REGISTER_STATUS, Status.REGISTER_SUCCESS);
                    resolve(response);
                }).catch(error => {
                    commit(MutationEnum.REGISTER_STATUS, Status.REGISTER_ERROR);
                    reject(error);
                });
            });
        }
}