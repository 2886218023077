import RecurrenceType from "@/types/Transaction/RecurrenceType";
import { AxiosResponse } from "axios";
import api from "./http/api";
import Transaction from "@/types/Transaction";

export interface TransactionResponse {
    data: Transaction
}

export interface TransactionPaginatedResponse {
    data: Transaction[];
    
    links: {
        first: string|null,
        last: string|null,
        next: string|null,
        prev: string|null,
    };

    meta: {
        current_page: number|null, 
        from: number|null,
        last_page: number|null,
        path: string,
        per_page: number,
        to: number,
        total: number
    };
}

export interface TransactionFilter {
    type?: string,
    kind?: string,
    description?: string,
    amount_start?: number,
    amount_end?: number,
    billing_date_start?: string,
    billing_date_end?: string,
    payment_date_start?: string,
    payment_date_end?: string,
    paid?: boolean
    account_id?: string|null,
    limit_by?: string,
    category_id?: string,
    load_category?: boolean,
    load_account?: boolean,
    load_recurrence?: boolean,
    conciliated?: boolean,
    sort?: string,
    order?: string
    cursor?: string|null
    paginate_count?: number,
    paginate?: boolean,
    page?: number
    last_page?: number,
    from?: number,
    to?: number,
    per_page?: number,
    total?: number,
    limit?: number,
}

interface TransactionCreate {
    type: string,
    kind: string,
    description?: string,
    origin_account_id?: string|null,
    target_account_id?: string|null,
    billing_date: string,
    payment_date?: string|null,
    amount: number,
    paid: boolean,
    category_id?: string,
    is_recurring: boolean,
    limit_date?: string|null,
    recurrence?: RecurrenceType
}

interface TransactionUpdate {
    description?: string,
    origin_account_id?: string|null,
    target_account_id?: string|null,
    billing_date: string,
    due_date?: string,
    amount: number,
    paid: boolean,
    category_id?: string,
    update_mode?: string
}

interface TransactionConciliate {
    ofx_reference?: string
}

interface TransactionDelete {
    delete_mode?: string
}

class TransactionService {

    get(id: string, params: TransactionFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>(`/backoffice/v1/transactions/${id}`, {
            params
        });
    }

    list(params: TransactionFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse<TransactionPaginatedResponse>['data']>(`/backoffice/v1/transactions`, {
            params
        });
    }

    update(id: string, params: TransactionUpdate) {
        return api({ auth: true, company: true }).put<AxiosResponse>(`/backoffice/v1/transactions/${id}`, params)
    }

    create(params: TransactionCreate) {
        return api({ auth: true, company: true }).post<AxiosResponse>(`/backoffice/v1/transactions`, {
            ...params
        });
    }   

    delete(id: string, params: TransactionDelete ) {
        return api({ auth: true, company: true }).delete<AxiosResponse>(`/backoffice/v1/transactions/${id}`, {params: params});
    }

    pay(id: string) {
        return api({ auth: true, company: true }).put<AxiosResponse>(`/backoffice/v1/transactions/${id}/pay`);
    }

    unpay(id: string) {
        return api({ auth: true, company: true }).put<AxiosResponse>(`/backoffice/v1/transactions/${id}/unpay`);
    }

    conciliate(id: string, params: TransactionConciliate) {
        return api({ auth: true, company: true }).patch<AxiosResponse>(`/backoffice/v1/transactions/${id}/conciliate`, params)
    }

    unlink(id: string) {
        return api({ auth: true, company: true }).patch<AxiosResponse>(`/backoffice/v1/transactions/${id}/unlink`)
    }
}

export default new TransactionService();