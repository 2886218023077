import dayjs from "dayjs";
import Period from "../Period";
import IPeriodFormatter from "./IPeriodFormatter";

export default class LastMonthFormatter implements IPeriodFormatter {

    format (interval: Period): string  {
        const startMonth = dayjs(interval.start).format('MMM');
        const startyear = dayjs(interval.start).format('YYYY');

        const endMonth = dayjs(interval.end).format('MMM');
        const endYear = dayjs(interval.end).format('YYYY');

        return `
           ${startMonth.charAt(0).toUpperCase()}${startMonth.slice(1)} ${startyear} - 
           ${endMonth.charAt(0).toUpperCase()}${endMonth.slice(1)} ${endYear}
        `;
    }

    
}