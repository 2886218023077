import { 
    LibraryIcon, 
    CurrencyDollarIcon, 
    ChartPieIcon 
} from '@heroicons/vue/outline'
import { Component } from 'vue'

export interface AccountTypeInterface {
    name: string,
    value: string,
    icon: Component,
}

const AccountTypes: AccountTypeInterface[] = [
    { name: 'Conta Corrente', value: 'checking', icon: LibraryIcon },
    { name: 'Conta Poupança/Investimentos', value: 'saving', icon: ChartPieIcon},
    { name: 'Outros', value: 'other', icon: CurrencyDollarIcon },
]

export default AccountTypes