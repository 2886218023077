<template>
<div class="relative overflow-visible p-2">
    <div class="text-left rounded-lg pb-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Detalhes da atividade</h3>
    </div>
   
    <div class="mx-auto max-w-full border-t border-gray-200 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Data/hora:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">{{ dayjs(audit.created_at).format('DD/MM/YYYY [às] HH:mm:ss') }}</dd>
            </div>
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Usuário:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">{{ audit.user?.name}} <span class="text-xs text-gray-400">{{audit.user?.email}}</span></dd>
            </div>
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Ação:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">{{ $t(`actions.${audit.event}`) }} {{ $t(`${audit.auditable_type}`, 1) }}</dd>
            </div>
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Endereço IP:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ audit.ip_address}}
                </dd>
            </div>
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Navegador:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ audit.user_agent}}
                </dd>
            </div>
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-2 sm:gap-1 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Antes da alteração:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                    <div class="text-xs">
                        <SshPre language="json">
                            {{ audit.old_values }}
                        </SshPre>
                    </div>
                </dd>
            </div>
            <div class="py-2 sm:py-2 sm:grid sm:grid-cols-2 sm:gap-1 sm:px-2">
                <dt class="text-xs font-medium text-gray-500">Depois da alteração:</dt>
                <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                    <div class="text-xs">
                        <SshPre language="json" >
                            {{ audit.new_values }}
                        </SshPre>
                    </div>
                </dd>
            </div>
            
        </dl>
    </div>
    <div class="pt-5">
        <div class="flex justify-center">
            <button type="reset" @click="cancel" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Fechar
            </button>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import { PropType, toRef } from 'vue';
import Audit from '@/types/Audit/Audit';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import SshPre from 'simple-syntax-highlighter'
import 'simple-syntax-highlighter/dist/sshpre.css'

dayjs.extend(UTC)
dayjs.extend(Timezone)

dayjs.tz.setDefault("America/Sao_Paulo")

const emit = defineEmits(['canceled'])

const props = defineProps({
    audit: {
        type: Object as PropType<Audit>,
        required: true
    }
})

const cancel = () => {
    emit('canceled');
}

const audit = toRef(props, 'audit');

</script>