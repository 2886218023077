<template>
<div class="relative overflow-visible pb-6">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="bg-white px-4 py-5  sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Usuários</h3>
                <p class="mt-2 max-w-4xl text-sm text-gray-400"></p>
            </div>
            <div class="ml-4 mt-2 flex-shrink-0">
                <button type="button" @click.stop="createInviteModal" class="inline-block items-center px-2 py-2 border border-transparent text-xs rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Novo usuário
                </button>
            </div>
        </div>
    </div>

    <div class="mx-6">
        <div class="overflow-hidden bg-white sm:rounded-md border-2">
            <ul role="list" class="divide-y divide-gray-200">
                <li v-for="user of users" :key="user.id">
                    <div class="group block hover:bg-gray-50">
                        <div class="flex items-center px-2 py-4 sm:px-4">
                            <div class="flex min-w-0 flex-1 items-center">
                                <div class="flex-shrink-0">
                                    <UserCircleIcon class="h-10 w-10 text-gray-400" />
                                </div>
                                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4 items-center justify-center">
                                    <div>
                                        <p class="truncate text-sm font-medium text-gray-600">{{ user.name }}</p>
                                        <p class="mt-1 flex items-center text-sm text-gray-500">
                                            <span class="truncate">{{ user.email }}</span>
                                        </p>
                                    </div>
                                    <div class="items-center justify-center">
                                        <span v-for="role in user.roles" :key="role.id" class="text-xs text-gray-600 bg-gray-200 rounded-lg p-1 mr-2">
                                            {{ role.display_name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="group-hover:opacity-100 opacity-40 flex items-center justify-center gap-2">
                                <EyeIcon class="h-4 w-4 text-gray-600" aria-hidden="true" />
                                <PencilAltIcon class="h-4 w-4 text-gray-600" aria-hidden="true" />
                                <a href="#" @click.stop="handleDeleteUser(user)" title="Excluir usuário da empresa">
                                    <TrashIcon class="h-4 w-4 text-red-600" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="mx-6 mt-6" v-if="invites.length">
        <h2 class="text-md leading-4 font-medium text-gray-600">Convites</h2>
        <p class="mt-2 max-w-4xl text-sm text-gray-400">Convites enviados para usuários.</p>
        <div class="overflow-hidden bg-white sm:rounded-md border-2 mt-4">
            <ul role="list" class="divide-y divide-gray-200">
                <li v-for="invite of invites" :key="invite.id">
                    <div class="block group items-center hover:bg-gray-50 p-2">
                        <div class="flex px-2 py-2 sm:px-4">
                            <div class="flex-1">
                                <div class="flex flex-1 items-center gap-4">
                                    <div class="truncate text-sm text-gray-600 flex-1">
                                        <UserAddIcon class="h-6 w-6 text-gray-400 inline mr-2" /> {{ invite.email }}
                                    </div>
                                    
                                    <div class="flex items-center justify-center w-48">
                                        <span class="text-xs text-gray-600 bg-gray-200 rounded-lg p-1 mr-2">
                                            {{ invite.role?.display_name }}
                                        </span>
                                    </div>

                                    <span class="text-xs mx-2 text-gray-400 mr-4">
                                        Enviado em {{ dayjs(invite.created_at).format('DD/MM/YYYY') }} por {{ invite.inviter?.name }}
                                    </span>
                                </div>
                            </div>

                            <div class="group-hover:opacity-100 opacity-40 flex items-center justify-between gap-2">
                                <a href="#" @click.stop="handleSend(invite)" title="Enviar convite">
                                    <MailIcon class="h-4 w-4 text-gray-600" aria-hidden="true" />
                                </a>
                                <!--<a href="#" title="Alterar convite">
                                    <PencilAltIcon class="h-4 w-4 text-gray-600" aria-hidden="true"  />
                                </a>-->
                                <a href="#" @click.stop="handleDeleteInvite(invite)" title="Excluir convite">
                                    <TrashIcon class="h-4 w-4 text-red-600" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <Modal :show="modalOpen" :static="false" @close="() => modalOpen = false" #default="{slotProps}">
        <Component 
            :is="componentsModal[modalMode]"
            @canceled="slotProps.close();" 
            @created="() => { slotProps.close(); handleCreated() }"
            @updated="() => { slotProps.close(); }"
        />
    </Modal>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { 
    UserCircleIcon, 
    MailIcon, 
    PencilAltIcon, 
    TrashIcon, 
    EyeIcon, 
    UserAddIcon 
} from '@heroicons/vue/outline';
import dayjs from 'dayjs';
import CompanyUserService from '@/services/CompanyUserService';
import CompanyInviteService from '@/services/CompanyInviteService';
import CompanyInvite from '@/types/CompanyInvite';
import User from '@/types/User/User';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import Modal from '@/components/Modal.vue';
import CreateInviteForm from '@/components/Invites/CreateInviteForm.vue';
import { DangerSwal, Toast, ConfirmSwal } from '@/constants/swal-mixins';
import Swal from 'sweetalert2';

const isLoading = ref(false);
const users = ref<User[]>([]);
const invites = ref<CompanyInvite[]>([]);

enum ModalModeEnum {
    CREATE_INVITE, 
    UPDATE_INVITE
};

const componentsModal = { 
    [ModalModeEnum.CREATE_INVITE] : CreateInviteForm,
    [ModalModeEnum.UPDATE_INVITE] : CreateInviteForm,
}

const modalOpen = ref(false);
const modalMode = ref(ModalModeEnum.CREATE_INVITE);

function createInviteModal() {
    modalOpen.value = true
    modalMode.value = ModalModeEnum.CREATE_INVITE
}

async function getUsers(): Promise<void> {
    isLoading.value = true;
    try {
        let response = await CompanyUserService.list();
        users.value = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

async function getInvites(): Promise<void> {
    isLoading.value = true;
    try {
        let response = await CompanyInviteService.list();
        invites.value = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

async function handleCreated(): Promise<void> {
    await getInvites();
}

async function handleDeleteUser(user: User) {
    await DangerSwal.fire({
        icon: 'question',
        title: `Excluir o usuário`,
        html: `Deseja realmente excluir o usuário <strong>${user.name}</strong>?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    await CompanyUserService.delete(user.id);
                    Toast.fire({
                        icon: 'success',
                        title: 'Usuário excluído com sucesso!',
                        iconColor: 'white',
                    })
                    await getUsers();
                    resolve(true)
                } catch(error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erro ao excluir o usuário',
                        iconColor: 'white',
                    })
                    reject(error)
                }
            })
        }
    })
}

async function handleDeleteInvite(invite: CompanyInvite)  {
    await DangerSwal.fire({
        icon: 'question',
        title: `Excluir o convite`,
        html: `Deseja realmente excluir o convite para <strong>${invite.email}</strong>?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    await CompanyInviteService.delete(invite.id);
                    Toast.fire({
                        icon: 'success',
                        title: 'Convite excluído com sucesso!',
                        iconColor: 'white',
                    })
                    await getInvites();
                    resolve(true)
                } catch(error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erro ao excluir o convite',
                        iconColor: 'white',
                    })
                    reject(error)
                }
            })
        }
    })
}

async function handleSend(invite: CompanyInvite)  {
    await ConfirmSwal.fire({
        icon: 'info',
        title: `Enviar o convite`,
        html: `Deseja reenviar o convite para <strong>${invite.email}</strong>?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, enviar',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    await CompanyInviteService.send(invite.id);
                    Toast.fire({
                        icon: 'success',
                        title: 'Convite foi enviado com sucesso!',
                        iconColor: 'white',
                    })
                    await getInvites();
                    resolve(true)
                } catch(error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erro ao enviar o convite',
                        iconColor: 'white',
                    })
                    reject(error)
                }
            })
        }
    })
}

onMounted( async () => {
    await getUsers();
    await getInvites();
})

</script>