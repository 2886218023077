<template>
    <div class="max-w-7xl mx-auto h-full px-4 sm:px-6 lg:px-8 container">
        <div class="mt-8">
            <router-view v-slot="{ Component }">
                <Transition appear name="fade" mode="out-in">
                    <component :is="Component" />
                </Transition>
            </router-view>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useStore } from '@/store';
import { onBeforeMount } from 'vue';


const store = useStore();
const changeLayout = (layout: string) => {
    store.commit('layout/SET_LAYOUT', layout);
};

onBeforeMount(() => {
    if(store.getters['user/hasCompanies']) {
        //store.commit('layout/SET_LAYOUT', 'dashboard-user');
    }
});

</script>