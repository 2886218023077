import { store } from "@/store";
import { IRootState } from "@/store/interfaces";
import { Store } from "vuex";
import UserCredential from "@/types/Auth/Credential"
import AuthActionEnum from '@/store/modules/auth/action-enum';
import UserActionEnum from '@/store/modules/user/action-enum';
import router from "@/router";

class userLogin {

    public async execute (user: UserCredential) {
        
        try{
            await store.dispatch(`auth/${AuthActionEnum.AUTH_LOGIN}`, {
                username: user.username,
                password: user.password,
                remember: user.remember
            });
    
            await store.dispatch(`user/${UserActionEnum.GET_INFO}`);

            await store.dispatch(`user/${UserActionEnum.GET_COMPANIES}`);
            
            if (! store.getters['user/currentCompany'] ) {
                await router.push({ name: 'user.dashboard' });
                return;
            }

            await router.push({ name: 'company.dashboard' });
        } catch(error: any) {
            throw Error(error);
        }
    }
}

export default new userLogin();