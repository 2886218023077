import dayjs from "dayjs";
import Period from "../Period";
import IPeriodFormatter from "./IPeriodFormatter";

export default class DayFormatter implements IPeriodFormatter {

    format (interval: Period): string  {
        const day = dayjs(interval.start).format('DD');
        const month = dayjs(interval.start).format('MMMM');
        const year = dayjs(interval.start).format('YYYY');
        
        return `
            ${day} de 
            ${month.charAt(0).toUpperCase()}${month.slice(1)} 
            ${year}
        `;
    }

    
}