import AuthToken from "@/types/Auth/Token";
import http from "../http/http";
import { store } from '@/store';
import Company from "@/types/Company";
import router from "@/router";

http.interceptors.request.use( config => {
    config.headers = config.headers ?? {};

    config.headers['Accept'] = 'application/json';
    config.headers['Content-Type'] = 'application/json';

    return config;
});

http.interceptors.response.use(
    response => response,
    error => {
        const originalRequest = error;
    
        if (error.response?.status === 401) {
            store.commit('auth/auth.token.clear');
            router.push({name: 'login'});
        }

        return Promise.reject(error);
    }
);

interface ApiConfig {
    auth: boolean,
    company?: boolean
}

const api = ( config: ApiConfig | null ) => {
    if ( config?.auth ) {

        http.interceptors.request.use( config => {
            config.headers = config.headers ?? {};

            let authToken: AuthToken = store.getters['auth/token'];

            if ( authToken?.access_token ) {
                config.headers['Authorization'] = `${authToken.token_type} ${authToken.access_token}`;
            }

            return config;
        });
    }

    if ( config?.company ) {

        http.interceptors.request.use( config => {
            config.headers = config.headers ?? {};

            let currentCompany: Company = store.getters['user/currentCompany'];

            if ( currentCompany?.id ) {
                config.headers['X-Company'] = currentCompany.id;
            }
            return config;
        });
    }

    return http;
}

export default api;