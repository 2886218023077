enum TransactionKindEnum {
    INCOME = 'income',
    EXPENSE = 'expense',
    TRANSFERENCE = 'transference',
}

namespace TransactionKindEnum {
    export function toObject(mode: TransactionKindEnum) {
        const obj = {
            [TransactionKindEnum.INCOME]: {
                name: TransactionKindEnum.INCOME,
                label: 'Receita'
            },
            [TransactionKindEnum.EXPENSE]: {
                name: TransactionKindEnum.EXPENSE,
                label: 'Despesa'
            },
            [TransactionKindEnum.TRANSFERENCE]: {
                name: TransactionKindEnum.TRANSFERENCE,
                label: 'Transferencia'
            }
        }

        return obj[mode];
    }
}

export default TransactionKindEnum