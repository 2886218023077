import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import { IRootState } from './interfaces'
import auth, { AuthModule } from './modules/auth'
import user, { UserModule } from './modules/user'
import layout, { LayoutModule } from './modules/layout'
import PersistStore from './plugins/persist'
import CryptoJs from 'crypto-js';

const state: IRootState = {
    root: true,
    version: '1',
    isLoading: false,
}
export const key: InjectionKey<Store<IRootState>> = Symbol()

export const store = createStore<IRootState & AuthModule & UserModule & LayoutModule>({
    modules: {
        auth,
        user,
        layout
    },
    state,
    plugins: [
        PersistStore({
            key: '@fluxous',
            items: [
                {
                    path: 'auth.token',
                },
                {
                    path: 'user.user',
                },
                {
                    path: 'user.company',
                },
                {
                    path: 'user.companies',
                },
                {
                    path: 'layout.showAmount'
                }
            ],
            driver: (store) => {
                return store.state.auth.remember ? localStorage : sessionStorage
            },
            onSave: (value) => {
                return CryptoJs.AES.encrypt(value, 'teste').toString();
            },
            onRestore: (value) => {
                var bytes  = CryptoJs.AES.decrypt(value, 'teste');
                var originalText = bytes.toString(CryptoJs.enc.Utf8);

                return originalText;
            }
        })
    ]
})

// define your own `useStore` composition function
export function useStore () {
    return baseUseStore(key)
}