import { NavigationGuard } from "vue-router";
import {store} from '@/store';
import ActionEnum from '@/store/modules/user/action-enum'

const companyGuard: NavigationGuard = async (to, from, next) => {

    if (to.matched.some( record => record.meta.company)) {

        let hasCompanies = store.getters['user/hasCompanies'];
        
        if(!hasCompanies) 
            await store.dispatch(`user/${ActionEnum.GET_COMPANIES}`)

        hasCompanies = store.getters['user/hasCompanies'];
        
        if (!hasCompanies) {
            next({
                name: 'user.new-company',
            });

            return;
        }
    }

    next();
}

export default companyGuard;