<template>
 <div class="relative overflow-visible p-0 w-96">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <form  class="w-full p-4 space-y-2" @submit.prevent="onSubmit" novalidate>
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Editar cliente</h3>

            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="cancel" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>
        <div :class="[v$.name.$error ? 'text-red-500' : 'text-gray-400']">
            <label for="name" class="block text-sm font-medium" :class="[v$.name.$error ? 'text-red-500' : 'text-gray-700']">Nome do cliente</label>
            <div class="mt-1">
                <input id="name" 
                    name="name" 
                    ref="name"
                    v-model="state.name" 
                    type="text" autocomplete="off"
                    :required="true" placeholder="Informe um nome"
                    :class="[
                        v$.name.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                    ]"
                />
            </div>
            <div v-if="v$.name.$error" class="mt-1 text-xs text-red-600">
                <div>{{ v$.name.$errors[0].$message }}</div>
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-center">
                <button type="reset" @click="cancel" :disabled="isLoading" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                <button type="submit" :disabled="isLoading" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                     <Spinner class="mr-2" v-if="isLoading"/> Salvar
                </button>
            </div>
        </div>
    </form>
</div>
</template>

<script setup lang="ts">
import { computed } from '@vue/reactivity';
import { nextTick, onMounted, PropType, reactive, ref, toRef } from 'vue';
import { AxiosError } from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxLength } from '@vuelidate/validators';
import OAuthClientService from '@/services/OAuthClientService';
import { XIcon } from '@heroicons/vue/solid';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import OAuthClient from '@/types/OAuthClient';

const emit = defineEmits<{
    (event: 'canceled'): void
    (event: 'updated', value: OAuthClient): void
}>();

const props = defineProps({
    client: {
        type: Object as PropType<OAuthClient>,
        required: true,
    }
})

const isLoading = ref(false);
const name = ref<HTMLElement>()

const state = toRef(props, 'client')

const rules = computed(() => ({
    name: {
        required: helpers.withMessage('Obrigatório', required),
        maxLength: helpers.withMessage('Tamanho máximo 50 caracteres', maxLength(50)),
    }    
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

async function updateClient(): Promise<void> {
    isLoading.value = true;
    try {
        const response = await OAuthClientService.update(state.value.id, {
            name: state.value.name
        })
        isLoading.value = false;
        emit('updated', response.data);
    } catch(error: any | AxiosError) {
        isLoading.value = false;

        if(error.response.data.errors.name) {
            $externalResults.value = {
                name: error.response.data.errors.name[0],
            }
        }
    }
}

function cancel(): void {
    emit('canceled');
}


async function onSubmit(): Promise<void> {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await updateClient();
}

onMounted(() => {
    nextTick(() => {
       name.value?.focus();
    });
})


</script>