import { Axios, AxiosResponse } from "axios";
import api from "./http/api";
class OFXService {
    
    process(params: FormData) {
        return api({ auth: true, company: true }).post<AxiosResponse>(`/backoffice/v1/ofx`, 
            params
        );
    }

}

export default new OFXService();