<template>
<div class="relative overflow-visible rounded-lg sm:overflow-hidden bg-white rounded-lg shadow divide-gray-200 mb-4">
    
    <!--<Overlay :show="isLoading">
        <Spinner :size="8" :border-size="4" border-color="indigo-900" class="duration-10"/>
    </Overlay>-->

    <div class="z-0 bg-white px-4 py-5 mx-2">
        <div class="flex justify-between">
            <h3 class="text-md font-medium text-gray-500">Minhas Contas</h3>
            <div>
                <router-link :to="{name: 'company.accounts.index' }" class="text-sm text-gray-400 hover:text-gray-500">Ver todas</router-link>
            </div>
        </div>
        
        <div class="grid grid-cols-1 divide-y mt-2">
            <div v-if="noResults">
                <div class="text-center p-4">
                    <h3 class="mt-2 text-sm font-medium text-gray-500">Sem contas cadastradas</h3>
                    <p class="mt-1 text-sm text-gray-500">Crie uma conta e comece a registrar seus lançamentos</p>
                    <div class="mt-4">
                        <router-link tag="a" :to="{ name: 'company.accounts.index'}" class="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <PlusIcon class="-ml-1 mr-1 h-4 w-4" aria-hidden="true" />
                            Criar conta
                        </router-link>
                    </div>
                </div>
            </div>

            <router-link v-else v-for="account in state.accounts"
                :to="{name: 'company.accounts.detail', params: {id: account.id}}"
                custom
                v-slot="{ href, navigate }"
            >
                <a :href="href" @click="navigate" class="hover:bg-gray-100 px-2 py-2 ease-in duration-300">
                    <div class="flex items-center items-stretch py-2">
                        <div class="flex-1 w-1/2">
                            <h2 class="text-sm font-normal text-gray-900">{{ account.name }}</h2>
                            <p class="text-xs text-gray-400 truncate">{{ $t('account_types_list.' + account.type) }}</p>
                            <!--<p v-if="account.bank" class="text-xs text-gray-300 truncate">{{ account.bank.name }}</p>-->
                        </div>
                        <div class="flex-none items-center">
                            <span class="text-md font-medium" :class="{ 'text-red-500' : account.balance < 0, 'text-blue-500': account.balance >= 0, 'blur-sm': !showAmount }">
                                {{ !showAmount ? '---' : Dinero({ amount: account.balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </span>
                        </div>
                    </div>
                </a>
            </router-link>
            
            <AccountsWidgetSkeleton v-if="isLoading" class="mt-2" />
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { computed } from '@vue/reactivity';
import Dinero from 'dinero.js'
import AccoutService from '@/services/AccountService';
import Account from '@/types/Account';
import AccountsWidgetSkeleton from '@/components/Accounts/AccountsWidgetSkeleton.vue'
import { PlusIcon } from '@heroicons/vue/outline'
import { useStore } from '@/store';

const store = useStore();

const showAmount= computed(() => store.getters['layout/showAmount'])

const state = reactive({
    accounts: [] as Account[],
    loaded: false
});

const isLoading = ref(false);
const noResults = computed( () => state.loaded && !state.accounts.length)

onMounted(async () => {
    isLoading.value = true
    try {
        let response = await AccoutService.list({paginate: true, paginate_count:3, sort: 'balance', order: 'desc'});
        state.accounts = response.data.data;
        state.loaded = true;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
        state.loaded = false;
    }
    
})
</script>