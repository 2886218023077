enum MutationEnum {
    INIT = 'init',
    LOGIN_STATUS = 'auth.login.status',
    LOGOUT_STATUS = 'auth.logout.status',
    TOKEN_STORE = 'auth.token.store',
    TOKEN_CLEAR = 'auth.token.clear',
    REGISTER_STATUS = 'auth.register.status',
    REMEMBER_SAVE = 'auth.remember.save',
}

export default MutationEnum