<template>
<div class="relative">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="pb-5">
        <h3 class="text-md leading-6 font-normal text-gray-500">Contas e Carteiras</h3>
        <!--<p class="mt-2 max-w-4xl text-sm text-gray-400">Criar contas é a melhor maneira de organizar seus lançamentos.</p>-->
    </div>    
    
    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
        <li class="flex col-span-1 rounded-lg border-2 border-gray-300 border-dashed hover:border-gray-400 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500">
            <button type="button" @click.prevent="() => openModal('account-create')" class="relative block w-full rounded-lg p-12 text-center text-gray-400 hover:text-gray-500">
                <span class="mt-2 block text-sm font-medium"> + Nova conta </span>
            </button>
        </li>
        <li v-for="account in state.accounts" :key="account.id" class="flex flex-col justify-between bg-white rounded-lg shadow space-y-4">
            
            <div class="sm:flex justify-end mt-2 mr-2">
                <Menu as="div" class="relative inline-block text-left">
                    <div>
                        <MenuButton class="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                            <span class="sr-only">Abrir opções</span>
                            <DotsVerticalIcon class="h-4 w-4" aria-hidden="true" />
                        </MenuButton>
                    </div>

                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                        <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div class="py-1">
                            <MenuItem v-slot="{ active }">
                                <a href="#"  @click.prevent="() => { selectedAccount = account; openModal('account-edit') }" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Editar</a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }">
                                <a href="#" @click.prevent="() => deleteAccount(account.id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Excluir</a>
                            </MenuItem>
                            </div>
                        </MenuItems>
                    </transition>
                </Menu>
            </div>
            
            <div class="text-center">
                <div class="w-full flex items-center justify-center">
                    <LibraryIcon class="h-6 w-6 overflow-hidden text-gray-300 " />
                </div>

                <div v-if="account.bank" class="text-xs text-gray-300 truncate uppercase">{{ account.bank.name }}</div>
                <div class="text-xs text-gray-300 truncate">{{ $t('account_types_list.' + account.type) }}</div>
            </div>

            <div class="w-full flex items-center justify-between space-x-6 text-center">
                <div class="flex-1">
                    <h2 class="text-2xl font-medium text-center" :class="[account.balance < 0 ? 'text-red-500' : 'text-blue-500']">
                        {{ Dinero({ amount: account.balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                    </h2>
                    <div class="text-sm text-gray-400" title="Saldo previsto">
                        ( {{ Dinero({ amount: account.predicted_balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }} )
                    </div>
                </div>
            </div>

            <div class="w-full flex items-center justify-between text-center">
                <div class="flex-1 truncate">
                    <div class="space-x-3 text-center">
                        <h3 class="text-gray-500 text-md font-bold truncate">{{ account.name }}</h3>
                    </div>
                    <!--<p class="mt-1 text-gray-500 text-xs truncate italic"> {{ account.description }} </p>-->
                </div>
            </div>
            
            <div class="w-full bg-gray-50 rounded-b-lg text-center">
                <router-link tag="#" :to="{name: 'company.accounts.detail', params: {id: account.id}}" class="flex-1 inline-flex items-center justify-center py-1 text-xs text-gray-400 font-normal border border-transparent  hover:text-gray-500">
                    + Detalhes
                </router-link>
            </div>
        </li>
    </ul>


    <Modal :show="modalOpen" @close="() => modalOpen = false" :static="true" #default="{slotProps}">
        <Component
            :is="modalComponent"
            :account="selectedAccount?.id"
            @created="() => { slotProps.close(); loadAccounts()}"
            @updated="() => { slotProps.close(); onUpdated(); }"
            @canceled="slotProps.close()" 
        />
    </Modal>
</div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, shallowRef } from 'vue';
import Dinero from 'dinero.js'
import Swal from 'sweetalert2';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { LibraryIcon } from '@heroicons/vue/outline'
import { DotsVerticalIcon } from '@heroicons/vue/solid'

import { DangerSwal, Toast } from '@/constants/swal-mixins';
import AccountService from '@/services/AccountService';
import Account from '@/types/Account';

import Modal from '@/components/Modal.vue';
import CreateAccount from '@/components/Accounts/CreateAccount.vue';
import EditAccountForm from '@/components/Accounts/EditAccountForm.vue';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

interface ModalComponentsType {
    key: string,
    component: any
}

const modalComponents: ModalComponentsType[] = [
    { key: "account-create", component: CreateAccount },
    { key: "account-edit", component: EditAccountForm }
];

const state = reactive({
    accounts: [] as Account[],
    loaded: false
});

const selectedAccount = ref<Account>();
const modalComponent = shallowRef(CreateAccount);
const isLoading = ref(false);
const modalOpen = ref(false);

const setModalIsOpen = (value: boolean) => {
    modalOpen.value = value
}

const setModalComponent = (value: string) => {
    modalComponent.value = modalComponents.find(component => component.key == value)?.component
}

const openModal = (value: string) => {
    setModalComponent(value);
    setModalIsOpen(true);
}

const loadAccounts = async() => {
    isLoading.value = true
    try {
        let response = await AccountService.list({paginate:false});
        state.accounts = response.data.data;
        state.loaded = true;
        isLoading.value = false;
    } catch( error ) {
        isLoading.value = false;
        state.loaded = false;
    }
}

const deleteAccount = async (id: string) => {
    await DangerSwal.fire({
        icon: 'question',
        title: `Excluir conta?`,
        html: `Todos os lançamento serão excluídos. Deseja realmente excluir essa conta?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                isLoading.value = true;
                try {
                    await AccountService.delete(id);
                    Toast.fire({
                        icon: 'success',
                        title: 'Conta excluída com successo',
                        iconColor: 'white',
                    })
                    isLoading.value = false;
                    resolve(true)
                } catch(error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erro ao excluir a conta',
                        iconColor: 'white',
                    })
                    isLoading.value = false;
                    reject(error)
                }
            })
        }
    })

    await loadAccounts();
}

const onUpdated = () => {
    Toast.fire({
        icon: 'success',
        title: `Atualizado com sucesso`,
        iconColor: 'white',
    })

    loadAccounts();
}


onMounted(async () => {
   await loadAccounts();
})
</script>