<template>
<div>
    <div class="flex gap-6 align-center justify-between">
        <div class="basis-3/4 text-lg text-gray-500">
            Bem-vindo, <span class="font-bold">{{ userData.name }}</span>
        </div>
        <div class="basis-1/4">
            <!--<QuickActions />-->
        </div>
    </div>

    <div class="pt-2">
        <ToggleAmount />
    </div>

    <Stats />

    <div class="mt-4 flex flex-row gap-6">
        <div class="basis-1/2">
            <AccountsWidget />
        </div>

        <div class="basis-1/2">
            <div class="bg-white rounded-lg shadow divide-gray-200 relative block w-full border-2 rounded-lg p-6 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <h3>Primeiros passos</h3>
                <div class="flow-root">
                    <ul role="list" class="-mb-8">
                        <li>
                            <div class="relative pb-8">
                                <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                <div class="relative flex space-x-3">
                                    <div>
                                    <span class="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                        <component :is="UserIcon" class="h-5 w-5 text-white" aria-hidden="true" />
                                    </span>
                                    </div>
                                    <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                        <p class="text-sm text-gray-500">
                                        Você criou sua conta <a href="#" class="font-medium text-gray-900"></a>
                                        </p>
                                    </div>
                                    <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                        <time datetime="2020-09-22">22 Set</time>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="relative pb-8">
                                <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                <div class="relative flex space-x-3">
                                    <div>
                                    <span class="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                        <component :is="CheckIcon" class="h-5 w-5 text-white" aria-hidden="true" />
                                    </span>
                                    </div>
                                    <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                        <p class="text-sm text-gray-500">
                                        Você confirmou seu email<a href="#" class="font-medium text-gray-900"></a>
                                        </p>
                                    </div>
                                    <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                        <time datetime="2020-09-22">22 Set</time>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="relative pb-8">
                                <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                <div class="relative flex space-x-3">
                                    <div>
                                    <span class="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                        <component :is="CheckIcon" class="h-5 w-5 text-white" aria-hidden="true" />
                                    </span>
                                    </div>
                                    <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                        <p class="text-sm text-gray-500">
                                        Você criou sua primeira conta <a href="#" class="font-medium text-gray-900"></a>
                                        </p>
                                    </div>
                                    <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                        <time datetime="2020-09-22">22 Set</time>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="relative pb-8">
                                <div class="relative flex space-x-3">
                                    <div>
                                    <span class="bg-gray-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
                                        <component :is="QuestionMarkCircleIcon" class="h-5 w-5 text-white" aria-hidden="true" />
                                    </span>
                                    </div>
                                    <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                    <div>
                                        <p class="text-sm text-gray-500">
                                        Você ainda não criou nenhuma transação <a href="#" class="font-medium text-gray-900"></a>
                                        </p>
                                    </div>
                                    <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                        <time datetime="2020-09-22">22 Set</time>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import Stats from '@/components/Dashboard/Stats.vue';
import AccountsWidget from '@/components/Accounts/AccountsWidget.vue';
import { CheckIcon, QuestionMarkCircleIcon, UserIcon } from '@heroicons/vue/solid'
import { useStore } from '@/store';
import Company from '@/types/Company';
import { computed } from '@vue/reactivity';
import Info from '@/types/User/Info';
import ToggleAmount from '@/components/Dashboard/ToggleAmount.vue';
import QuickActions from '@/components/Dashboard/QuickActions.vue';
import { ref } from 'vue'

const store = useStore();

const companyData= computed<Company>( () => store.getters['user/currentCompany'])
const userData= computed<Info>( () => store.getters['user/userInfo'])

</script>