<template>
<div class="relative overflow-visible pb-6">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <div class="bg-white px-4 py-5  sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Auditoria</h3>
                <p class="mt-2 max-w-4xl text-sm text-gray-400">Registro das últimas atividades no sistema.</p>
            </div>
        </div>
    </div>
    <div class="mx-6">
        <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="p-3 text-left text-xs font-semibold text-gray-400 sm:pl-2">Usuário</th>
                    <th scope="col" class="p-3 text-left text-xs font-semibold text-gray-400">Ação</th>
                    <th scope="col" class="p-3 text-left text-xs font-semibold text-gray-400">Data</th>
                    <th scope="col" class="p-3 text-left text-xs font-semibold text-gray-400">Ações</th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="audit of state.audits" :key="audit.id" class="group hover:bg-gray-100">
                    <td class="whitespace-nowrap py-2 text-sm text-gray-900">
                        <div class="flex items-center">
                            <div class="p-0 text-gray-400">
                                <UserCircleIcon class="h-6 w-6" v-if="audit.user_type == 'user'" />
                                <ChipIcon v-else class="h-6 w-6" />
                            </div> 
                            <div class="ml-4">
                                <div class="font-medium text-xs text-gray-900">{{ audit.user?.name }}</div>
                                <div class="text-gray-400 text-xs">{{ audit.user?.email }}</div>
                            </div>
                        </div>
                    </td>
                    <td class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">{{ $t(`actions.${audit.event}`) }} {{ $t(`${audit.auditable_type}`, 1) }}</td>
                    <td class="whitespace-nowrap px-3 py-2 text-xs text-gray-500">{{ dayjs(audit.created_at).format('DD/MM/YYYY [às] HH:mm:ss') }}</td>
                    <td class="whitespace-nowrap py-2 pl-3 pr-4 text-right text-xs font-medium group-hover:opacity-100 opacity-20">
                        <a href="#" @click="openModalDetail(audit)" class="text-indigo-600 hover:text-indigo-700 flex">
                            <EyeIcon class="h-4 w-4 mr-2" /> Detalhes
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

     <Modal :show="modalOpen" @close="() => modalOpen = false" #default="{slotProps}">
        <AuditDetail 
            :audit="selectedAudit"
            @canceled="slotProps.close()" 
        />
     </Modal>
</div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import UTC from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';

import { EyeIcon, UserCircleIcon, ChipIcon } from '@heroicons/vue/outline'
import AuditService from '@/services/AuditService';
import Audit from '@/types/Audit/Audit'
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import Modal from '@/components/Modal.vue';
import AuditDetail from '@/components/Audits/AuditDetail.vue';

dayjs.extend(UTC)
dayjs.extend(Timezone)

dayjs.tz.setDefault("America/Sao_Paulo")

const isLoading = ref(false);
const modalOpen = ref(false);

const state = reactive({
    audits: [] as Audit[],
});

const selectedAudit = ref<Audit>({} as Audit);

const openModalDetail = (selected: Audit) => {
    selectedAudit.value = selected;
    modalOpen.value = true;
}

const closeModalDetail = () => {
    selectedAudit.value = {} as Audit;    
    modalOpen.value = true;
}

const loadAudits = async () => {
    isLoading.value = true;
    try {
        let response = await AuditService.list({paginate: true, sort: 'created_at', order: 'desc'});
        state.audits = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

onMounted(async () => {
    await loadAudits();
})
</script>