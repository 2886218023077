<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/fluxous-logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Entrar</h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Informe seus dados para acessar sua conta.
            </p>
        </div>
        
        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            
            <div v-if="state.loginError" class="rounded-md bg-red-50 p-4 mb-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">Não foi possível entrar na sua conta</h3>

                        <div class="mt-2 text-sm text-red-700">
                            Verifique se seus dados estão corretos e tente novamente.
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form class="space-y-6" @submit.prevent="onSubmit" novalidate>
                    <div>
                        <label for="username" class="block text-sm font-medium text-gray-700"> Email ou nome de usuário
                        </label>
                        <div class="mt-1">
                            <input id="username" name="username" v-model="state.username" type="text"
                                autocomplete="username" :required="true" placeholder="Email ou nome de usuário"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                        <div v-if="v$.username.$error" class="text-sm text-red-600">
                            <div>{{ v$.username.$errors[0].$message }}</div>
                        </div>
                    </div>

                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">Senha</label>
                        <div class="mt-1">
                            <input id="password" name="password" v-model="state.password" type="password"
                                autocomplete="current-password" :required="true"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Informe sua senha" />
                        </div>
                        <div v-if="v$.password.$error" class="text-sm text-red-600">
                            <div>{{ v$.password.$errors[0].$message }}</div>
                        </div>
                    </div>

                    <div class="flex items-center justify-between">
                        <!--<div class="flex items-center">
                            <input id="remember-me" name="remember-me" v-model="state.remember" type="checkbox"
                                class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                            <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Lembrar-me </label>
                        </div>-->

                        <div class="text-sm">
                            <router-link to="/forgot-password"
                                class="font-medium text-indigo-600 hover:text-indigo-500"> Esqueceu sua
                                senha?</router-link>
                        </div>
                    </div>

                    <div>
                        <button :disabled="isLoading" type="submit"
                            class="disabled:opacity-75 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <Spinner v-if="isLoading" border-color="bg-indigo-600" size="w-4 h-4" border-size="border-2" class="mr-2" />
                            Entrar
                        </button>
                    </div>
                </form>
            </div>

            <p class="mt-6 text-center text-sm text-gray-400">
                Ainda não possui cadastro?
                {{ ' ' }}
                <router-link tag="a" to="/register" class="link-effect text-indigo-600 hover:text-indigo-500 font-bold">
                    Crie
                    sua conta
                </router-link>
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, computed, ref } from 'vue';
import { useStore } from '@/store';
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import Spinner from '@/components/Spinner.vue'
import userLogin from '@/useCases/userLogin';
import { XCircleIcon } from '@heroicons/vue/solid';

const store = useStore();

const state = reactive({
    username: '',
    password: '',
    remember: true,
    loginError: false
});

const isLoading = computed( () => store.getters['auth/loginInProgress']);

const rules = computed(() => ({
    username: {
        required: helpers.withMessage('Informe seu e-mail ou nome de usuário', required)
    },
    password: {
        required: helpers.withMessage('Informe sua senha', required),
    }
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const onSubmit = async () => {
    v$.value.$clearExternalResults();
    const isValid = await v$.value.$validate();
    state.loginError = false;

    if (!isValid) return;

    const { username, password, remember } = state;

    try {
        await userLogin.execute({
            username,
            password, 
            remember
        });
    } catch (error: any | AxiosError) {
        console.log(error)
        $externalResults.value = {
            username: 'Credenciais inválidas',
            password: 'Credenciais inválidas'
        }
        state.loginError = true;
    }
}
</script>