<template>
    <div class="relative sm:max-w-3xl mx-auto">
        <Overlay :show="isLoading">
            <Spinner border-color="border-gray-500" />
        </Overlay>

        <div class="flex items-center">
            <router-link tag="a" :to="{name: 'company.accounts.index'}" replace class="ml-4 text-sm font-normal text-gray-400 hover:text-gray-700">
                <span class="text-sm flex items-center">
                    <ArrowNarrowLeftIcon class="flex-shrink-0 h-6 w-6 text-gray-400 text-sm mr-2" aria-hidden="true" /> 
                    Voltar
                </span>
            </router-link>
        </div>
        <!--ACCOUNT RESUME-->
        <div class="mt-4 rounded-lg bg-white shadow px-2 py-2 border-b border-gray-200 sm:px-2 ">
            <div>
                <h2 class="sr-only" id="profile-overview-title">Detalhes da Conta</h2>
                <div class="bg-white">
                    <div class="flex justify-end">
                        <Menu as="div" class="relative inline-block text-left">
                            <div>
                                <MenuButton class="rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                    <span class="sr-only">Abrir opções</span>
                                    <DotsVerticalIcon class="h-4 w-4" aria-hidden="true" />
                                </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div class="py-1">
                                    <MenuItem v-slot="{ active }">
                                        <a href="#" @click.prevent="() => openModal('account-edit')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Editar</a>
                                    </MenuItem>
                                    <MenuItem v-slot="{ active }">
                                        <a href="#" @click.prevent="() => deleteAccount()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Excluir</a>
                                    </MenuItem>
                                    </div>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>

                    <div class="text-center space-y-4 sm:space-y-6">
                        <div>
                            <div class="flex justify-center">
                                <LibraryIcon class="h-8 w-8 overflow-hidden text-gray-300 " />
                            </div>

                            <div v-if="state.account.bank" class="text-xs text-gray-300 truncate uppercase">{{ state.account.bank.name }}</div>
                            <div v-if="state.account.type" class="text-xs text-gray-300 truncate">{{ $t('account_types_list.' + state.account.type) }}</div>
                        </div>

                        <div>
                            <div class="text-3xl sm:text-3xl text-md font-medium" :class="[state.account.balance < 0 ? 'text-red-500' : 'text-blue-500']">
                                {{ Dinero({ amount: state.account.balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                            </div>

                            <div class="text-md text-gray-400" title="Saldo previsto">
                                ( {{ Dinero({ amount: state.account.predicted_balance,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }} )
                            </div>
                        </div>

                        <div class="pb-2 space-y-1">
                            <h3 class="text-gray-500 text-lg font-bold truncate">{{ state.account.name }}</h3>
                            <p v-if="state.account.description" class="text-xs text-gray-400 px-8">{{ state.account.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--LAST TRANSACTIONS-->
        <div class="mt-4 rounded-lg bg-white shadow px-4 py-5 border-b border-gray-200 sm:px-6">
            <div>
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto item-justify">
                        <h1 class="text-xl font-medium text-gray-500">Últimos Lançamentos</h1>
                        <router-link tag="a" :to="{name: 'company.transactions.index', query: {account: id}}" replace>
                            <span class="text-xs text-indigo-500 hover:text-indigo-600">Ver mais lançamentos</span>
                        </router-link>
                    </div>
                    <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                       
                        <div class="sm:flex sm:space-x-5 justify-end">
                            <Menu as="div" class="relative inline-block text-left">
                                <div>
                                    <MenuButton class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-xs font-normal text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                                        <span class="sr-only">Abrir opções</span>
                                        <span class="text-xs">+ Novo lançamento</span>
                                    </MenuButton>
                                </div>

                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                    <MenuItems class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div class="py-1">
                                        <MenuItem v-slot="{ active }">
                                            <a href="#" @click.prevent="() => openModal('expense-create')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                Despesa
                                            </a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <a href="#" @click.prevent="() => openModal('income-create')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                Receita
                                            </a>
                                        </MenuItem>
                                        <MenuItem v-slot="{ active }">
                                            <a href="#"  @click.prevent="() => openModal('transference-create')" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">
                                                Transferência
                                            </a>
                                        </MenuItem>
                                        </div>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div class="mt-4 flex flex-col overflow-x-auto overflow-hidden">
                    <table class="min-w-full divide-y divide-gray-100">
                        <thead>
                            <tr>
                                <th scope="col" class="py-3.5 pr-1 text-left text-sm font-semibold text-gray-400"></th>
                                <th scope="col" class="py-3.5 pr-1 text-left text-sm font-semibold text-gray-400">Data</th>
                                <th scope="col" class="py-3.5 pr-1 max-w-lg text-left text-sm font-semibold text-gray-400">Descrição</th>
                                <th scope="col" class="py-3.5 pr-1 text-left text-sm font-semibold text-gray-400">Categoria</th>
                                <th scope="col" class="py-3.5 pr-1 text-right text-right text-sm font-semibold text-gray-400">Valor</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction in state.transactions" :key="transaction.id" 
                                class="group border-b border-gray-100 cursor-pointer hover:bg-gray-50 transition duration-200"
                                :class="{'bg-yellow-50': transaction.is_overdue }"
                                @click="openDetail(transaction)"
                            >
                                <td class="text-center pr-1">
                                    <span v-if="transaction.is_overdue"  title="Lançamento vencido">
                                        <ExclamationIcon class="h-4 w-4 inline text-orange-200" /> 
                                    </span>
                                </td>
                                <td class="py-2 pr-1">
                                    <div class="font-normal text-gray-400 text-xs">{{ dayjs(transaction.billing_date).format("DD/MM/YYYY") }}</div>
                                </td>
                                <td class="py-2 pr-1 text-sm max-w-sm  text-gray-600 ">
                                    <p class="truncate text-sm">{{ transaction.description }}</p>
                                </td>
                                <td class="py-2 pr-1 text-sm text-gray-600">
                                    <p v-if="transaction.category" class=" truncate text-ellipsis overflow-hidden">
                                        <CategoryBadge :kind="transaction.kind" :text="transaction.category.name" />
                                    </p>
                                </td>
                                <td class="py-2 pr-1 text-sm text-gray-400 text-right whitespace-nowrap">
                                    <span class="text-md" :class="[transaction.amount < 0 ? 'text-red-500' : 'text-blue-500']">
                                        {{ Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                    </span>
                                </td>
                                <td class="py-2 px-3 sm:pr-0 text-sm">
                                    <div v-if="isLoadingPaid && selectedTransaction.id == transaction.id">
                                        <Spinner border-color="border-gray-500" size="h-4 w-4" border-size="border-2" />
                                    </div>
                                    <div v-else>
                                        <div v-if="transaction.is_payable">
                                            <a href="#" @click.prevent.stop="setPaid(transaction, false)" v-if="transaction.paid" title="Marcar como não-paga">
                                                <ThumbUpIcon class="h-4 w-4 inline text-green-500" aria-hidden="true" />
                                            </a>
                                            <a href="#" @click.prevent.stop="setPaid(transaction, true)" v-else title="Marcar como paga">
                                                <ThumbDownIcon class="h-4 w-4 inline text-gray-400" aria-hidden="true" />
                                            </a>
                                        </div>
                                        <div v-else title="Não se pode mudar status de saldo inicial" >
                                            <ThumbUpIcon class="h-4 w-4 inline text-gray-100" aria-hidden="true" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <TransactionDetailOverlay
            :open.sync="isDetailOpen"
            :transaction="selectedTransaction"
            v-show="isDetailOpen"
            @close="closeDetail"
            @updated="() => { closeDetail(); onUpdatedTransaction(); }"
            @deleted="() => { closeDetail(); onDeletedTransaction(); }"
            class="z-50"
        />

        <Modal :show="modalOpen" @close="() => modalOpen = false" :static="true" #default="{slotProps}">
            <Component
                :is="modalComponent" 
                :account="id"
                @created="() => { slotProps.close(); onCreated(); }"
                @updated="() => { slotProps.close(); onUpdated(); }"
                @canceled="slotProps.close(); onCanceled()" 
                @error="(error: any) => { slotProps.close(); onError(error); }" 
            />
        </Modal>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, reactive, shallowRef } from 'vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ArrowNarrowLeftIcon, LibraryIcon } from '@heroicons/vue/outline'
import { DotsVerticalIcon, ThumbUpIcon, ExclamationIcon } from '@heroicons/vue/solid'
import { ThumbDownIcon } from '@heroicons/vue/outline';
import Dinero from 'dinero.js';
import dayjs from 'dayjs'
import AccountService from '@/services/AccountService';
import AccountTransactionService from '@/services/AccountTransactionService';
import TransactionService from '@/services/TransactionService';
import Account from '@/types/Account';

import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import CreateExpense from '@/components/Accounts/CreateExpense.vue';
import CreateIncome from '@/components/Accounts/CreateIncome.vue';
import CreateTransference from '@/components/Accounts/CreateTransference.vue';
import Modal from '@/components/Modal.vue';
import { DangerSwal, Toast } from '@/constants/swal-mixins';
import AccountTransactionType from '@/types/Transaction/AccountTransactionType';
import CategoryBadge from '@/components/Categories/CategoryBadge.vue';
import TransactionDetailOverlay from '@/components/Transactions/TransactionDetailOverlay.vue';
import EditAccountForm from '@/components/Accounts/EditAccountForm.vue';
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';
import TransactionType from "@/types/Transaction/TransactionType";

const props = defineProps(['id']);
const isLoading = ref(false);
const isLoadingPaid = ref(false);
const isDetailOpen = ref(false);
const modalOpen = ref(false);
const modalComponent = shallowRef(CreateExpense);
const router = useRouter();

interface ModalComponentsType {
    key: string,
    component: any
}

const modalComponents: ModalComponentsType[] = [
    { key: "expense-create", component: CreateExpense },
    { key: "income-create", component: CreateIncome },
    { key: "transference-create", component: CreateTransference },
    { key: "account-edit", component: EditAccountForm }
];

interface StateType {
  account: any
  loaded: boolean,
  transactions: AccountTransactionType[]
}

const state: StateType = reactive({
    account: {
        balance: 0,
        predicted_balance: 0,
        type: ''
    } as Account,
    loaded: false,
    transactions: []
});

const selectedTransaction = ref<TransactionType>({} as TransactionType);

const closeDetail = () => {
    isDetailOpen.value = false;
}

const openDetail = (accountTransaction: AccountTransactionType) => {
    if (accountTransaction.transaction_id != null) {
        selectedTransaction.value = accountTransaction;
        
        selectedTransaction.value.id = accountTransaction.transaction_id;

        isDetailOpen.value = true;
    }
}

const setModalIsOpen = (value: boolean) => {
    modalOpen.value = value
}

const setModalComponent = (value: string) => {
    modalComponent.value = modalComponents.find(component => component.key == value)?.component
}

const openModal = (value: string) => {
    setModalComponent(value);
    setModalIsOpen(true);
}

const loadAccount = async(id: string) => {
    isLoading.value = true
    try {
        let response = await AccountService.get(id);
        state.account = response.data.data;
        state.loaded = true;
        isLoading.value = false;
    } catch( error ) {
        isLoading.value = false;
        state.loaded = false;
    }
}

const deleteAccount = async () => {
    await DangerSwal.fire({
        icon: 'question',
        title: `Excluir conta?`,
        html: `Todos os lançamento serão excluídos. Deseja realmente excluir essa conta?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                isLoading.value = true;
                try {
                    await AccountService.delete(props.id);
                    Toast.fire({
                        icon: 'success',
                        title: 'Conta excluída com successo',
                        iconColor: 'white',
                    })
                    isLoading.value = false;
                    resolve(true)
                } catch(error) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Erro ao excluir a conta',
                        iconColor: 'white',
                    })
                    isLoading.value = false;
                    reject(error)
                }
            })
        }
    })

    router.push({name: 'company.accounts.index'});
}

const onCreated = () => {
    Toast.fire({
        icon: 'success',
        title: `Transação criada com sucesso`,
        iconColor: 'white',
    })

    loadAll()
}

const onUpdated = () => {
    Toast.fire({
        icon: 'success',
        title: `Atualizado com sucesso`,
        iconColor: 'white',
    })

    loadAll()
}

const onUpdatedTransaction = async () => {
    Toast.fire({
        icon: 'success',
        title: `Atualizado com sucesso`,
        iconColor: 'white',
    })
    await loadAll();
}

const onDeletedTransaction = async () => {
    await loadAll();
}

const onCanceled = () => {
    loadAll()
}

const onError = (error: any) => {
    Toast.fire({
        icon: 'error',
        title: `Erro ao criar a transação`,
        iconColor: 'white',
    })
}

const setPaid = async(transaction: AccountTransactionType, pay: boolean) => {
    isLoadingPaid.value = true
    selectedTransaction.value = transaction;
    try {
        if(transaction.transaction_id) {

            let response = (pay) ? await TransactionService.pay(transaction.transaction_id) : await TransactionService.unpay(transaction.transaction_id);

            transaction.paid = pay;
            transaction.is_overdue = response.data.data.is_overdue
        }

        Toast.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Lançamento atualizado com sucesso!',
            iconColor: 'white',
        })
        

        await loadAccount(props.id);
        //state.transactions = response.data.data;
        //state.loaded = true;
        isLoadingPaid.value = false;
    } catch( error ) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao atualizar o lançamento!',
            iconColor: 'white',
        })

        isLoadingPaid.value = false;
        state.loaded = false;
    }
}

const loadTransactions = async(id: string) => {
    isLoading.value = true
    try {
        let response = await AccountTransactionService.get(id, {
            sort: 'billing_date', 
            order: 'asc', 
            load_category: true,
            limit: 10
        });
        state.transactions = response.data.data;
        state.loaded = true;
        isLoading.value = false;
    } catch( error ) {
        isLoading.value = false;
        state.loaded = false;
    }
}

const loadAll = async () => {
    await loadAccount(props.id)
    await loadTransactions(props.id)
}

onMounted( async () => {
    await loadAll();
});
</script>
