<template>
<div class="relative overflow-visible p-0 w-96">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <form  class="w-full p-4 space-y-4" @submit.prevent="onSubmit" novalidate>
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Convidar usuário</h3>
            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="cancel" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>

        <div :class="[v$.email.$error ? 'text-red-500' : 'text-gray-400']">
            <label for="name" class="block text-sm font-medium" :class="[v$.email.$error ? 'text-red-500' : 'text-gray-700']">E-mail do usuário</label>
            <div class="mt-1">
                <input id="email" 
                    name="email" 
                    ref="email"
                    v-model="state.email" 
                    type="text" autocomplete="off"
                    :required="true" placeholder="Informe um e-mail válido"
                    :class="[
                        v$.email.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                    ]"
                />
            </div>
            <div v-if="v$.email.$error" class="mt-1 text-xs text-red-600">
                <div>{{ v$.email.$errors[0].$message }}</div>
            </div>
        </div>
        
        <div :class="[v$.role.$error ? 'text-red-500' : 'text-gray-400']">
        <RadioGroup v-model="state.role">
            <RadioGroupLabel class="block text-sm font-medium" :class="[v$.role.$error ? 'text-red-500' : 'text-gray-700']">Perfil</RadioGroupLabel>
            <div class="-space-y-px rounded-md bg-white mt-1">
                <RadioGroupOption as="template" v-for="(role, roleIdx) in roles" :key="role.id" :value="role" v-slot="{ checked, active }">
                    <div :class="[roleIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', roleIdx === roles.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                    <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                        <span class="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <span class="ml-3 flex flex-col">
                        <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', , v$.role.$error ? 'text-red-500' : '', 'block text-sm font-medium']">{{ role.display_name }}</RadioGroupLabel>
                        <RadioGroupDescription as="span" :class="[checked ? 'text-indigo-700' : 'text-gray-500', v$.role.$error ? 'text-red-500' : '', 'block text-xs']">{{ role.description }}</RadioGroupDescription>
                    </span>
                    </div>
                </RadioGroupOption>
            </div>
            <div v-if="v$.role.$error" class="mt-1 text-xs text-red-600">
                <div>{{ v$.role.$errors[0].$message }}</div>
            </div>
        </RadioGroup>
        </div>

        <div class="pt-5">
            <div class="flex justify-center">
                <button type="reset" @click="cancel" :disabled="isLoading" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                <button type="submit" :disabled="isLoading" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                     <Spinner class="mr-2" v-if="isLoading"/> Convidar
                </button>
            </div>
        </div>
    </form>
</div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, nextTick } from 'vue';
import { computed } from '@vue/reactivity';
import useVuelidate from '@vuelidate/core'
import { required, helpers, email as emailRule } from '@vuelidate/validators';
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/solid';

import RoleService from '@/services/RoleService';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import Role from '@/types/Role';
import CompanyInviteService from '@/services/CompanyInviteService';
import { Toast } from '@/constants/swal-mixins';

const emit = defineEmits<{
    (event: 'canceled'): void
    (event: 'created'): void
}>();

interface ServerValid {
    $message: string,
    $valid: boolean
}

const isLoading = ref(false);

const state = reactive({
    email: '',
    role: {} as Role
});

const roles = ref<Array<Role>>([]);

const email = ref<HTMLElement>()

const rules = computed(() => ({
    email: {
        required: helpers.withMessage('Obrigatório. Informe um e-mail válido.', required),
        email: helpers.withMessage('E-mail inválido', emailRule),
    },
    role: {
        required: helpers.withMessage('Obrigatório. Selecione um perfil.', required),
    }
}));

const $externalResults = ref({});
const v$ = useVuelidate(rules, state, { $externalResults });

function cancel(): void {
    emit('canceled');
}

async function getRoles(): Promise<void> {
    isLoading.value = true;
    try {
        let response = await RoleService.list();
        roles.value = response.data.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;

        Toast.fire({
            icon: 'error',
            title: 'Falha ao recuperar os perfis',
            iconColor: 'white',
        });
    }
}

async function createInvite() {
    isLoading.value = true;
    try {
        await CompanyInviteService.create({
            email: state.email,
            role_id: state.role.id
        });
        emit('created');

        Toast.fire({
            icon: 'success',
            title: 'Convite enviado com sucesso.',
            iconColor: 'white',
        });

        isLoading.value = false;
    } catch(error: any) {
        isLoading.value = false;

        Toast.fire({
            icon: 'error',
            title: 'Falha ao enviar o convite',
            iconColor: 'white',
        });

        if(error.response.data.errors.email) {
            $externalResults.value = {
                email: error.response.data.errors.email[0]
            };
        }
    }
}

async function onSubmit(): Promise<void> {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await createInvite();
}

onMounted(async () => {
    nextTick(() => {
       email.value?.focus();
    });

    await getRoles();
})


</script>