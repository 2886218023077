import dayjs from "dayjs";
import Period from "../Period";
import IPeriodNavigator from "./INavigator";
import YearFormatter from "../Formatters/YearFormatter";

export default class YearNavigator implements IPeriodNavigator {

    private currentPeriod: Period;
    private label: string = 'Este ano';
    private key: string = 'year';
    private periodicity: string = 'yearly';

    constructor(
        private initial: Date,
        private interval: number = 1
    ) {
        this.currentPeriod = {
            start: dayjs(this.initial).startOf('year').toDate(),
            end: dayjs(this.initial).endOf('year').toDate()
        }
    }

    setPeriod(start: Date, end: Date): void {
        
    }

    reset() {
        this.currentPeriod = {
            start: dayjs(this.initial).startOf('year').toDate(),
            end: dayjs(this.initial).endOf('year').toDate()
        }

        return this.currentPeriod;
    }

    getCurrent(): Period {
        return this.currentPeriod;
    }

    getKey(): string {
        return this.key;
    }

    getLabel(): string {
        return this.label;
    }

    getPeriodicity(): string {
        return this.periodicity;
    }

    getInterval(): number {
        return this.interval;
    }

    getPrevious(): Period {
        const start = dayjs(this.currentPeriod.start).subtract(this.interval, 'year').startOf('year').toDate();
        const end = dayjs(this.currentPeriod.end).subtract(this.interval, 'year').endOf('year').toDate();
        
        this.currentPeriod = { start, end };
        return this.currentPeriod;
    }

    getNext(): Period {
       const start = dayjs(this.currentPeriod.start).add(this.interval, 'year').startOf('year').toDate();
       const end = dayjs(this.currentPeriod.end).add(this.interval, 'year').endOf('year').toDate();

       this.currentPeriod = { start, end };
       return this.currentPeriod;
    }

    format(): string {
        return new YearFormatter().format(this.currentPeriod);
    }
}