<template>
<main class="max-w-7xl mx-auto pb-10 lg:py-4 lg:px-8">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside class="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
            <nav class="space-y-1">
                <router-link v-for="item in subNavigation" :key="item.name" :to="item.to" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
                    <a :href="href" @click="navigate" :class="[isExactActive ? 'bg-gray-50 text-indigo-600 hover:bg-white' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="isExactActive ? 'page' : undefined">
                        <component :is="item.icon" :class="[isExactActive ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
                        <span class="truncate">
                            {{ item.name }}
                        </span>
                    </a>
                </router-link>
            </nav>
        </aside>
        <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <section>
                <div class="shadow sm:rounded-md sm:overflow-hidden bg-white">
                    <router-view></router-view>
                </div>
            </section>
        </div>
    </div>
</main>
</template>

<script setup lang="ts">

import {
    OfficeBuildingIcon,
    TagIcon,
    ChartBarIcon,
    ChipIcon,
    ShieldExclamationIcon,
    UserGroupIcon
} from '@heroicons/vue/outline'


const subNavigation = [
    { name: 'Empresa', to: { name: 'company.settings.company' }, icon: OfficeBuildingIcon, current: true },
    { name: 'Usuários', to: { name: 'company.settings.users' }, icon: UserGroupIcon, current: true },
    { name: 'Categorias', to: { name: 'company.settings.categories' }, icon: TagIcon, current: true },
    { name: 'Relatórios', to: { name: 'company.settings.reports' }, icon: ChartBarIcon, current: true },
    { name: 'Integrações', to: { name: 'company.settings.integrations' }, icon: ChipIcon, current: true },
    { name: 'Auditoria', to: { name: 'company.settings.audits' }, icon: ShieldExclamationIcon, current: true }
]
</script>