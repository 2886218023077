import Notification from "@/types/Notification";
import { AxiosResponse } from "axios";
import api from "./http/api";

interface NotificationFilter {
    readed?: boolean;
    limit?: number;
    paginate?: boolean;
    sort?: string;
    order?: string;
}

interface NotificationListResponse {
    data: Array<Notification>
}

class NotificationService {

    list(params: NotificationFilter) {
        return api({ auth: true })
            .get<NotificationListResponse>('/backoffice/v1/user/notifications', {
                params
            });
    }

    get(id: string) {
        return api({ auth: true })
            .put<Notification>(`/backoffice/v1/user/notifications/read/${id}`);
    }
}

export default new NotificationService();