<template>
<div class="relative overflow-visible">
     <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" border-size="border-2" size="h-4 w-4"/>
    </Overlay>
    <Listbox as="div" v-model="selectedCategory">
        <div class="mt-1 relative">
            <ListboxButton 
                class="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                :class="props.class"
            >
                <span class="flex items-center">
                    <TransactionKindBadge :kind="selectedCategory?.kind" class="block p-1 mr-2"/>
                    <span class="ml-3 block truncate text-gray-600" v-if="selectedCategory?.name" :class="[textSize]">{{ selectedCategory.name}}</span>
                    <span v-else :class="[selectedCategory?.id ? 'text-gray-700' : 'text-gray-400', 'truncate', textSize]">Selecione uma categoria</span>
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    <ListboxOption v-if="props.showEmpty" as="template" :value="{ id:null, name: 'Todas' }" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <div class="flex items-center">
                                <TransactionKindBadge kind="none" class="block p-1" />
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate', textSize]">
                                    Todas
                                </span>
                            </div>
                            <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                    <ListboxOption as="template" v-for="category in state.categories" :key="category.id" :value="category" v-slot="{ active, selected }">
                        <li :class="[active ? 'text-white bg-indigo-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                            <div class="flex items-center">
                                <TransactionKindBadge :kind="category.kind" class="block p-1" />
                                <!--<span :class="[person.online ? 'bg-green-400' : 'bg-gray-200', 'flex-shrink-0 inline-block h-2 w-2 rounded-full']" aria-hidden="true" />-->
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate', textSize]">
                                    {{ category.name }}
                                    <!--<span class="sr-only"> is {{ person.online ? 'online' : 'offline' }}</span>-->
                                </span>
                            </div>

                            <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</div>
</template>

<script setup lang="ts">
import { PropType, ref, reactive, onMounted, watch, toRef } from 'vue';
import { computed } from '@vue/reactivity';

import { 
    Listbox, 
    ListboxButton, 
    ListboxLabel, 
    ListboxOption, 
    ListboxOptions 
} from '@headlessui/vue'

import { 
    CheckIcon, 
    SelectorIcon 
} from '@heroicons/vue/solid'

import CategoryService from '@/services/CategoryService';
import Category from '@/types/Category/Category';

import TransactionKindBadge from '@/components/Transactions/TransactionKindBadge.vue';
import Spinner from '@/components/Spinner.vue';
import Overlay from '@/components/Overlay.vue';


const isLoading = ref(false);

const emit = defineEmits(['update:modelValue', 'change', 'input'])

const props = defineProps({
    modelValue: {
        type: Object as PropType<Category> | null
    },
    kind: {
        type: String
    },
    class: {
        type: String
    },
    textSize: {
        type: String,
        defaut: 'text-sm'
    },
    showEmpty: {
        type: Boolean,
        required: false,
        default: false
    }
})

const selectedCategory = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value),
})

const state = reactive({
    categories: [] as Category[]
})

const kind = toRef(props, 'kind')

watch( kind, value => {
    selectedCategory.value = {} as Category;
    loadCategories();
})

const loadCategories = async () => {
    isLoading.value = true;
    try {        
        let response = await CategoryService.list({paginate: false, sort: 'kind', kind: props.kind})
        state.categories = response.data.data;
        isLoading.value = false;
    } catch(error) {
        console.log(error);
        isLoading.value = false;
    }
}

onMounted( async () => {
    await loadCategories();
})


</script>