<template>
<div class="relative">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <form class="space-y-6" @submit.prevent="onSubmit" novalidate>
        <div :class="[v$.name.$error ? 'text-red-500' : 'text-gray-700']">
            <label for="name" class="block text-sm font-medium text-gray-700" :class="[v$.name.$error ? 'text-red-500' : '']">Nome de usuário</label>
            <div class="mt-1">
                <input id="name" name="name" v-model="state.name" type="text" autocomplete="off"
                    :required="true" placeholder="Nome de usuário"
                    :class="[
                        v$.name.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                        'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                    ]" />
                <p class="mt-2 text-xs text-gray-400">Letras, números e ponto</p>
            </div>
            <div v-if="v$.name.$error" class="text-sm text-red-600">
                <div>{{ v$.name.$errors[0].$message }}</div>
            </div>
        </div>
        <div class="text-gray-700">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
                <input id="email" disabled name="email" v-model="state.email" type="text" autocomplete="off"
                    :required="true" placeholder="Informe um email válido"
                    class="bg-gray-100 border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-500" />
                <p class="mt-2 text-xs text-gray-400">Email em formato válido</p>
            </div>
        </div>
        <div>
            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Salvar
            </button>
        </div>
    </form>
</div>
</template>

<script setup lang="ts">

import { reactive, computed, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import { useStore } from '@/store';
import { Toast } from '@/constants/swal-mixins';
import UserService from '@/services/UserService'
import UserActionEnum from '@/store/modules/user/action-enum';

import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

const store = useStore();
const user = store.getters['user/userInfo'];
const isLoading = ref(false);

const state = reactive({
    name: user.name,
    email: user.email,
});

const rules = computed(() => ({
    name: {
        required: helpers.withMessage('Obrigatório', required)
    }
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });

const updateProfile = async () => {
    isLoading.value = true;

    const { name, email } = state;

    try {
        await UserService.updateProfile({
            name,
            email,
        });
        
        isLoading.value = false;
    } catch (error: any | AxiosError) {
        $externalResults.value = { ...error?.response.data.errors };
        isLoading.value = false;
        throw Error(error);
    }
}

const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    try {
        await updateProfile();
        Toast.fire({
            icon: 'success',
            title: `Seus dados foram atualizados`,
            iconColor: 'white',
        })
        await store.dispatch(`user/${UserActionEnum.GET_INFO}`);
    } catch( error ) {
        Toast.fire({
            icon: 'error',
            title: `Não foi possível atualizar seus dados`,
            iconColor: 'white',
        })
    }
}
</script>