import dayjs from "dayjs";
import Period from "../Period";
import IPeriodFormatter from "./IPeriodFormatter";

export default class MonthFormatter implements IPeriodFormatter {

    format (interval: Period): string  {
        const month = dayjs(interval.start).format('MMMM');
        const year = dayjs(interval.start).format('YYYY');

        return `
           ${month.charAt(0).toUpperCase()}${month.slice(1)} de 
           ${year}
        `;
    }

    
}