import dayjs from "dayjs";
import Period from "../Period";
import IPeriodNavigator from "./INavigator";
import MonthFormatter from "../Formatters/MonthFormatter";

export default class MonthNavigator implements IPeriodNavigator {

    private currentPeriod: Period;
    private label: string = 'Este mês';
    private key: string = 'month';
    private periodicity: string = 'monthly';


    constructor(
        private initial: Date,
        private interval: number = 1
    ) {
        this.currentPeriod = {
            start: dayjs(this.initial).startOf('month').toDate(),
            end: dayjs(this.initial).endOf('month').toDate()
        }
    }

    setPeriod(start: Date, end: Date): void {
        
    }

    reset() {
        this.currentPeriod = {
            start: dayjs(this.initial).startOf('month').toDate(),
            end: dayjs(this.initial).endOf('month').toDate()
        }

        return this.currentPeriod;
    }

    getCurrent(): Period {
        return this.currentPeriod;
    }

    getKey(): string {
        return this.key;
    }

    getLabel(): string {
        return this.label;
    }

    getPeriodicity(): string {
        return this.periodicity;
    }

    getInterval(): number {
        return this.interval;
    }

    getPrevious(): Period {
        const start = dayjs(this.currentPeriod.start).subtract(this.interval, 'month').startOf('month').toDate();
        const end = dayjs(this.currentPeriod.end).subtract(this.interval, 'month').endOf('month').toDate();

        this.currentPeriod = { start, end };
        return this.currentPeriod;
    }

    getNext(): Period {
       const start = dayjs(this.currentPeriod.start).add(this.interval, 'month').startOf('month').toDate();
       const end = dayjs(this.currentPeriod.end).add(this.interval, 'month').endOf('month').toDate();

       this.currentPeriod = { start, end };
       return this.currentPeriod;
    }

    format(): string  {
        return new MonthFormatter().format(this.currentPeriod);
    }
}