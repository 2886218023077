import { MutationTree } from 'vuex'
import _ from 'lodash'

import Token from '@/types/Auth/Token'
import { REMEMBER_KEY } from '@/constants/storage'

import { StateType, Status } from './types'
import MutationEnum from './mutation-enum'
import { Mutations } from "./types"

export const mutations: MutationTree<StateType> & Mutations = {
    [MutationEnum.LOGIN_STATUS](state, payload: Status) {
        state.status = payload;
    },

    [MutationEnum.LOGOUT_STATUS](state, payload: Status) {
        state.status = payload
    },
    
    [MutationEnum.REGISTER_STATUS](state, payload: Status) {
        state.status = payload
    },

    [MutationEnum.TOKEN_STORE](state, payload: Token) {
        state.token = payload;
    },

    [MutationEnum.TOKEN_CLEAR](state) {
        state.token = null;
    },

    [MutationEnum.REMEMBER_SAVE](state, payload) {
        state.remember = payload;
        localStorage.setItem(REMEMBER_KEY, JSON.stringify(payload));
    },
}