<template>
<div class="relative overflow-visible p-0">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <form  class="space-y-4 w-96 p-4" @submit.prevent="onSubmit" novalidate>
        <div class="flex items-end sm:items-start">
            <h3 class="text-lg leading-6 font-medium text-gray-900 flex-1">Nova despesa</h3>

            <div class="ml-4 flex flex-shrink-0">
                <a href="#" @click="cancel" class="inline-flex text-right align-end rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span class="sr-only">Close</span>
                    <XIcon class="h-4 w-4" aria-hidden="true" />
                </a>
            </div>
        </div>

        <div :class="[v$.description.$error ? 'text-red-500' : 'text-gray-400']">
            <label for="description" class="block text-sm font-medium"  :class="[v$.description.$error ? 'text-red-500' : 'text-gray-700']">Descrição</label>
            <div class="mt-1">
                <input id="description" name="description" v-model="state.description" type="text" autocomplete="off"
                    :required="true" 
                    placeholder="Ex: conta de luz, almoço, etc."
                    class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700"
                    :class="[
                        v$.description.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                    ]"
                />    
            </div>
            <div v-if="v$.description.$error" class="mt-1 text-xs text-red-600">
                <div>{{ v$.description.$errors[0].$message }}</div>
            </div>
        </div>

        <div class="sm:grid sm:grid-cols-2 sm:gap-4">
            <div :class="[v$.amount.$error ? 'text-red-500' : 'text-gray-400']">
                <label for="amount"  class="block text-sm font-medium"  :class="[v$.amount.$error ? 'text-red-500' : 'text-gray-700']">Valor</label>
                <div class="mt-1">
                    <CurrencyInput 
                        v-model="state.amount" 
                        name="amount" 
                        :options="{ 
                            currency: 'BRL', 
                            currencyDisplay: 'hidden', 
                            autoDecimalDigits: true, 
                            valueScaling: 'precision',
                            hideGroupingSeparatorOnFocus: false
                        }" 
                        class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-700" 
                        :class="[
                            v$.amount.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                        ]"
                    />
                </div>
                <div v-if="v$.amount.$error" class="mt-1 text-xs text-red-600">
                    <div>{{ v$.amount.$errors[0].$message }}</div>
                </div>
            </div>

            <div :class="[v$.billing_date.$error ? 'text-red-500' : 'text-gray-400']">
                <label for="billing_date" class="block text-sm font-medium"  :class="[v$.billing_date.$error ? 'text-red-500' : 'text-gray-700']">Data</label>
                <div class="mt-1">
                <v-date-picker v-model="state.billing_date" mode="date" :masks="{ input: 'DD/MM/YYYY' }" is-required :input-debounce="100" >
                        <template v-slot="{ inputValue, togglePopover, inputEvents }">
                            <div class="mt-1 relative rounded-md shadow-sm">
                                <button @click.prevent="togglePopover()" class="absolute inset-y-0 left-0 pl-3 flex items-center">
                                    <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                                <input 
                                    v-on="inputEvents"
                                    :value="inputValue"
                                     type="text" 
                                     name="email" 
                                     id="email" 
                                    class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md text-gray-700" placeholder=""
                                    :class="[
                                        v$.billing_date.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                    ]"
                                />
                            </div>
                        </template>
                    </v-date-picker>
                </div>
                <div v-if="v$.billing_date.$error" class="mt-1 text-xs text-red-600">
                    <div>{{ v$.billing_date.$errors[0].$message }}</div>
                </div>
            </div>
        </div>

        <div class="mt-3" :class="[v$.category.$error ? 'text-red-500' : 'text-gray-400']">
            <label for="category" class="block text-sm font-medium"  :class="[v$.category.$error ? 'text-red-500' : 'text-gray-700']">Categoria</label>
            <div class="mt-1">
                <CategorySelect 
                    v-model="state.category" 
                    kind="expense" 
                    :class="v$.category.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : ''"
                />
            </div>
            <div v-if="v$.category.$error" class="mt-1 text-xs text-red-600">
                <div>{{ v$.category.$errors[0].$message }}</div>
            </div>
        </div>

        <div class="mt-3">
            <label for="paid" class="block text-sm font-medium text-gray-700">Lançamento pago?</label>
            <div class="flex mt-2">
                <Switch id="paid" v-model="state.paid" :class="[state.paid ? 'bg-green-500' : 'bg-gray-400', 'mt-1 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2']">
                    <span class="sr-only">Lançamento pago?</span>
                    <span aria-hidden="true" :class="[state.paid ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                </Switch>
                <div class="ml-2">
                    <Transition name="fade" mode="out-in" :duration="400">
                    <ThumbDownIcon v-if="!state.paid" class="h6 w-6 inline text-gray-400" aria-hidden="true" />
                    <ThumbUpIcon v-else class="h6 w-6 inline text-green-500" aria-hidden="true" />
                    </Transition>
                </div>
            </div>
        </div>

        <div class="mt-3">
            <RecurrenceSubForm 
                v-model:is_recurring="state.is_recurring"
                v-model:frequency="recurrence.frequency"
                v-model:interval="recurrence.interval"
                v-model:limit_by="recurrence.limit_by"
                v-model:limit_date="recurrence.limit_date"
                v-model:limit_count="recurrence.limit_count"
            />
        </div>

        <div class="pt-5">
            <div class="flex justify-center">
                <button type="reset" @click="cancel" :disabled="isLoading" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancelar</button>
                <button type="submit" :disabled="isLoading" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <Spinner class="mr-2" v-if="isLoading"/> Criar despesa
                </button>
            </div>
        </div>
    </form>
</div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import { computed } from '@vue/reactivity';
import useVuelidate from '@vuelidate/core'
import { required, helpers, maxLength, minValue } from '@vuelidate/validators';
import { Switch } from '@headlessui/vue'
import { CalendarIcon, ThumbUpIcon } from '@heroicons/vue/solid';
import { ThumbDownIcon } from '@heroicons/vue/outline';
import dayjs from 'dayjs';

import { XIcon } from '@heroicons/vue/outline';

import TransactionService from '@/services/TransactionService';
import TransactionTypeEnum from '@/types/Transaction/TransactionTypeEnum';
import TransactionKindEnum from '@/types/Transaction/TransactionKindEnum';

import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import CurrencyInput from "@/components/CurrencyInput.vue";
import CategorySelect from '@/components/Categories/CategorySelect.vue';
import Category from '@/types/Category/Category';
import RecurrenceSubForm from '../Transactions/RecurrenceSubForm.vue';

const emit = defineEmits(['created', 'canceled', 'error'])

const props = defineProps({
    account: String
})

const format = (date: any) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

const state = reactive({
    type: TransactionTypeEnum.DEBIT,
    kind: TransactionKindEnum.EXPENSE,
    description: '',
    origin_account_id: props.account,
    billing_date: new Date(),
    payment_date: null,
    amount: 0,
    paid: true,
    category: {} as Category,
    is_recurring: false    
});

const recurrence = reactive({
    frequency: 'MONTHLY',
    interval: 1,
    start_date: dayjs(new Date()).toDate(),
    limit_by: 'DATE',
    limit_date: dayjs(new Date()).add(2, 'year').toDate(),
    limit_count: 2,
});

const isLoading = ref(false);

const rules = computed(() => ({
    description: {
        required: helpers.withMessage('Obrigatório', required),
        maxLength: helpers.withMessage('Tamanho máximo 150 caracteres', maxLength(150)),
    },
    billing_date: {
        required: helpers.withMessage('Obrigatório', required),
    },
    amount: {
        required: helpers.withMessage('Obrigatório', required),
        minValue: helpers.withMessage('Valor tem que ser maior que zero', minValue(1)),
    },
    category: {
        required: helpers.withMessage('Obrigatório', required),
    },
}));

const $externalResults = ref({});

const v$ = useVuelidate(rules, state, { $externalResults });


const cancel = () => {
    state.category = {} as Category
    emit('canceled');
}

const createExpense = async () => {
    try {
        isLoading.value = true;
        await TransactionService.create({
            ...state,
            recurrence: {
                ...recurrence,
                limit_date: recurrence.limit_date ? dayjs(recurrence.limit_date ).format('YYYY-MM-DD') : null,
                start_date: recurrence.start_date ? dayjs(recurrence.start_date ).format('YYYY-MM-DD') : null
            },
            billing_date: dayjs(state.billing_date).format('YYYY-MM-DD'),
            payment_date: state.payment_date ? dayjs(state.payment_date).format('YYYY-MM-DD') : null,
            category_id: state.category.id
        })
        isLoading.value = false;
        emit('created');
    } catch(error) {
        isLoading.value = false;
        emit('error', error)
    }
}

const onSubmit = async () => {
    v$.value.$clearExternalResults();

    const isValid = await v$.value.$validate();

    if (!isValid) return;

    await createExpense();
}
</script>