<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/fluxous-logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Crie sua conta</h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Informe seus dados abaixo para criar uma conta.
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                 <div v-if="state.registered">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        Agora falta pouco!
                    </h3>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Você precisa confirmar seu email.</p><br>
                        <p>Enviamos para <strong>{{ state.email }}</strong> um link de confirmação. Verifique sua caixa de email.</p>
                        <br>
                        <p><strong>O email ainda não chegou?</strong> Aguarde alguns minutos ou envie o link novamente.</p>
                        <button 
                            @click="sendVerificationEmail"
                            :disabled="isLoading"
                            class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <Spinner v-if="isLoading" />
                            Envie o link novamente
                        </button>
                    </div>
                </div>
                <form  v-else class="space-y-6" @submit.prevent="onSubmit" novalidate>
                    <div :class="[v$.name.$errors.length ? 'text-red-500' : 'text-gray-700']">
                        <label for="name" class="block text-sm font-medium">Nome de usuário</label>
                        <div class="mt-1">
                            <input id="name" name="name"  @blur="v$.name.$touch" v-model.lazy="state.name" type="text" autocomplete="off"
                                :required="true" placeholder="Nome de usuário"
                                :class="[
                                    v$.name.$errors.length ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                                ]" />
                            <p class="mt-2 text-xs text-gray-400">Letras, números e ponto</p>
                        </div>
                        <div v-if="v$.name.$error && v$.name.$errors.length" class="text-sm text-red-600">
                            <div>{{ v$.name.$errors[0]?.$message }}</div>
                        </div>
                    </div>
                    <div :class="[v$.email.$errors.length ? 'text-red-500' : 'text-gray-700']">
                        <label for="email" class="block text-sm font-medium">Email</label>
                        <div class="mt-1">
                            <input id="email" name="email"  :readonly="!!route.query.email" @blur="v$.email.$touch" v-model.lazy="state.email" type="text" autocomplete="off"
                                :required="true" placeholder="Informe um email válido"
                                :class="[
                                    v$.email.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                                ]" />
                            <p class="mt-2 text-xs text-gray-400">Email em formato válido</p>
                        </div>
                        <div v-if="v$.email.$error" class="text-sm text-red-600">
                            <div>{{ v$.email.$errors[0]?.$message }}</div>
                        </div>
                    </div>

                    <div :class="[v$.password.$errors.length ? 'text-red-500' : 'text-gray-700']">
                        <label for="password" class="block text-sm font-medium">Senha</label>
                        <div class="mt-1">
                            <input id="password" name="password" v-model="state.password" type="password"
                                autocomplete="off" :required="true" placeholder="Informe sua senha"
                                :class="[
                                    v$.password.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                                ]" />
                            <p class="mt-2 text-xs text-gray-400">Tamanho mínimo de 8 caracteres</p>
                        </div>
                        <div v-if="v$.password.$error" class="text-sm text-red-600">
                            <div>{{ v$.password.$errors[0].$message }}</div>
                        </div>
                    </div>

                    <div>
                        <label for="password-confirmation" class="block text-sm font-medium text-gray-700">Confirme sua
                            senha</label>
                        <div class="mt-1">
                            <input id="password_confirmation" name="password_confirmation"
                                v-model="state.password_confirmation" type="password" autocomplete="off"
                                :required="true" placeholder="Confirme sua senha"
                                :class="[
                                    v$.password_confirmation.$error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500', 
                                    'appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm text-gray-700'
                                ]" />
                            <p class="mt-2 text-xs text-gray-400">Tamanho mínimo de 8 caracteres</p>
                        </div>
                        <div v-if="v$.password_confirmation.$error" class="text-sm text-red-600">
                            <div>{{ v$.password_confirmation.$errors[0].$message }}</div>
                        </div>
                    </div>

                    <div>
                        <button 
                            type="submit"
                            class="w-full disabled:opacity-40 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Criar minha conta
                        </button>
                    </div>
                </form>
            </div>

            <p class="mt-6 text-center text-sm text-gray-400">
                Já possuo uma conta. <router-link tag="a" to="/login"
                    class="link-effect text-indigo-600 hover:text-indigo-500 font-bold">
                    Quero entrar
                </router-link>
            </p>
        </div>
    </div>
</template>


<script setup lang="ts">
import { reactive, computed, ref } from 'vue';
import useVuelidate, { ValidationRuleWithoutParams } from '@vuelidate/core'
import { required, email, helpers, sameAs, minLength, not, MessageProps } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import { useStore } from '@/store';
import ActionEnum from '@/store/modules/auth/action-enum';
import AuthService from '@/services/AuthService';
import Spinner from '@/components/Spinner.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const store = useStore();

const state = reactive({
    name: '',
    email: route.query.email ? route.query.email : '',
    password: '',
    password_confirmation: '',
    registered: false
});

const isLoading = ref(false);

const $externalResults = ref({});

interface ServerValid {
    $message: string,
    $valid: boolean
}

const isNameTaken = async (value:string) => { 
    if (value === '') return true
    
    try {
        await AuthService.checkName(value)
        return {
            $valid: true,
            $message: '',
            $pending: false,
        }
    } catch( error: any ) {
        return {
            $valid: false,
            $message: error.response.data.errors.name[0],
            $pending: false,
        }
    }
}

const isEmailTaken = async (value:string) => { 
    if (value === '') return true
    
    try {
        await AuthService.checkEmail(value)
        return {
            $valid: true,
            $message: '',
            $pending: false,
        }
    } catch( error: any ) {
        return {
            $valid: false,
            $message: error.response.data.errors.email[0],
            $pending: false,
        }
    }
}

const rules = computed(() => ({
    name: {
        required: helpers.withMessage('Obrigatório', required),
        isUnique: helpers.withMessage((params: MessageProps) => { 
            const { $message } = params.$response as ServerValid;
            return $message;
        }, helpers.withAsync(isNameTaken)),
        $autoDirty: true
    },
    email: {
        required: helpers.withMessage('Obrigatório', required),
        email: helpers.withMessage('Utilize um e-mail válido', email),
        isUniqueEmail: helpers.withMessage((params: MessageProps) => {
            console.log(params); 
            const { $message } = params.$response as ServerValid;
            return $message;
        }, helpers.withAsync(isEmailTaken)),
        $autoDirty: true
    },
    password: {
        required: helpers.withMessage('Obrigatório', required),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
        $autoDirty: true
    },
    password_confirmation: {
        required: helpers.withMessage('Obrigatório', required),
        sameAsPassword: helpers.withMessage('Senhas não coincidem', sameAs(state.password)),
        minLength: helpers.withMessage('Mínimo de 8 caracteres', minLength(8)),
        $autoDirty: true
    }
}));

const v$ = useVuelidate(rules, state, { $externalResults, $lazy: true, $autoDirty: true });

const isValid = ref<boolean>(!v$.value.$invalid && !v$.value.$anyDirty );

const register = async () => {
    isLoading.value = true;

    const { name, email, password, password_confirmation } = state;

    try {
        await store.dispatch(`auth/${ActionEnum.AUTH_REGISTER}`, {
            name,
            email,
            password,
            password_confirmation
        });

        state.registered = true;
        isLoading.value = false;
    } catch (error: any | AxiosError) {
        $externalResults.value = { ...error?.response.data.errors };
        state.registered = false;
        isLoading.value = false;
    }
}

const sendVerificationEmail = async () => {
    isLoading.value = true;

    try {
        await AuthService.sendVerificationEmail(String(state.email));
        
        isLoading.value = false;
    } catch (error: any | AxiosError) {
        console.log(error?.response.data.message)
        isLoading.value = false;
    }
}


const onSubmit = async () => {
    v$.value.$clearExternalResults();

    isValid.value = await v$.value.$validate();

    if (!isValid.value) return;

    await register();
}
</script>