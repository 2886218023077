import { NavigationGuard } from "vue-router";
import {store} from '@/store';

const currentCompanyGuard: NavigationGuard = async (to, from, next) => {

    if (to.matched.some( record => record.meta.currentCompany)) {

        let currentCompany = store.getters['user/currentCompany'];

        if (! currentCompany) {
            next({
                name: 'user.dashboard',
            });

            return;
        }
    }

    next();
}

export default currentCompanyGuard;