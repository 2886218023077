<template>
<div class="relative sm:max-w-3xl mx-auto">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="self-start">
        <h3 class="text-xl font-normal text-gray-600">Notificações</h3>
    </div>

    <div v-if="notifications.length" class="mt-4 rounded-lg shadow border-b bg-white border-gray-200 p-1 ">
        <div class="divide-y divide-gray-200">
            <div v-for="notification in notifications" :key="notification.id" 
                class="px-4 py-2 transition-opacity" :class="[notification.read_at ? 'opacity-60 hover:opacity-100' : 'hover:bg-gray-50' ]"
            >
                <router-link 
                    tag="a" 
                    :to="{name: 'user.notifications.detail', params: {id: notification.id }}" 
                    class="text-gray-700 flex items-center justify-between"
                >
                    <span class="inline-block transform h-2 w-2 rounded-full flex-shrink-0 mr-4 ring-2 ring-white" 
                        :class="[!notification.read_at ? 'bg-red-600' : 'bg-gray-300']" 
                    />

                    <div class="truncate text-ellipsis overflow-hidden flex flex-col flex-1 text-md" :class="[notification.read_at ? 'text-gray-500' : '']">
                        <span class="truncate text-ellipsis overflow-hidden" :class="[notification.read_at ? '' : 'font-medium']">
                            {{ notification.data.title }}
                        </span>
                        <span class="truncate text-ellipsis overflow-hidden">
                            {{ notification.data.resume }}
                        </span>
                        <span class="truncate text-ellipsis overflow-hidden text-xs mt-2" :title="dayjs(notification.created_at).format('DD/MM/YYYY HH:mm:ss')">
                            {{ dayjs().to(dayjs(notification.created_at)) }}
                        </span>
                    </div>
                    <div>
                        <ChevronRightIcon class="w-6 h-6 text-gray-400"/>
                    </div>
                </router-link>
            </div>            
        </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center rounded-lg mt-4 p-24 text-gray-400 border-2 border-gray-200">
        <InboxIcon class="w-20 h-20 text-gray-300" />
        <span class="text-lg">Você não possui notificações.</span>
    </div>
</div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue';
import Notification from '@/types/Notification';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import { InboxIcon } from '@heroicons/vue/outline';
import NotificationService from '@/services/NotificationService';

import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

dayjs.extend(relativeTime);

const isLoading = ref(false);
const notifications = ref<Notification[]>([]);

async function listNotifications(): Promise<void> {
    isLoading.value = true;
    try {
       const response = await NotificationService.list({ limit: 10, paginate: true, sort: 'created_at', order: 'desc'});
       notifications.value = response.data.data;
       isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

onBeforeMount( async() => {
    await listNotifications();
});
</script>