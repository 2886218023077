import dayjs from "dayjs";
import Period from "../Period";
import IPeriodFormatter from "./IPeriodFormatter";

export default class WeekFormatter implements IPeriodFormatter {

    format (interval: Period): string  {
        const startYear = dayjs(interval.start).format('YYYY');
        const endYear = dayjs(interval.end).format('YYYY');

        return `
            ${dayjs(interval.start).format('DD MMM')} ${startYear} à 
            ${dayjs(interval.end).format('DD MMM')} ${endYear}
        `;
    }

    
}