import { AxiosResponse } from "axios";
import api from "./http/api";

interface AccountTransactionFilter {
    type?: string,
    description?: string,
    amount_start?: number,
    amount_end?: number,
    billing_date_start?: string,
    billing_date_end?: string,
    payment_date_start?: string,
    payment_date_end?: string,
    paid?: boolean,
    category_id?: string,
    load_category?: boolean,
    limit?: number
    sort?: string,
    order?: string,
    paginate?: boolean,
}

class AccountTransactionService {

    get(id: string, params: AccountTransactionFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>(`/backoffice/v1/accounts/${id}/transactions`, { params });
    }
}

export default new AccountTransactionService();