import { Axios, AxiosResponse } from "axios";
import api from "./http/api";

interface BankFilter {
    name?: string,
    description?: string,
    kind?: string,
    sort?: string,
    order?: string
    paginate?: boolean
}


class BankService {

    list(params: BankFilter) {
        return api({ auth: true, company: true }).get<AxiosResponse>('/backoffice/v1/banks', {
            params
        });
    }

}

export default new BankService();