<template>
<div>
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>

    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md sm:w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/fluxous-logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Convite</h2>
            <div class="mt-2 max-w-xl text-sm text-gray-500 text-center"></div>
        </div>

        <div class="mt-8 sm:mx-auto sm:max-w-xl sm:w-full">
            <div v-if="!accepted" class="bg-white shadow sm:rounded-lg p-6">
                <div v-if="invite.status === 'PENDING' || invite.status === 'ACCOUNT_REQUIRED'" class="flex-col">
                    <p class="mb-4">Você foi convidado por <strong>{{ invite.invite.inviter.name }} - {{ invite.invite.inviter.email }}</strong>
                    para fazer parte da empresa <strong>{{  invite.invite.company }}.</strong></p>

                    <div v-if="invite.status === 'PENDING'" class="flex items-center justify-center">
                        <a href="#" @click="accept" class="justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Aceitar convite
                        </a>
                    </div>
                    <div v-else class="flex items-center justify-center">
                        <router-link tag="a" :to="`/register?email=${invite.invite.email}`" class="justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Criar minha conta
                        </router-link>
                    </div>
                </div>
                <div v-else-if="invite.status === 'EXPIRED'">
                    <h2 class="text-lg font-semibold">Convite Expirado!</h2> 
                </div>
                <div v-else-if="invite.status === 'INVALID'">
                    <h2 class="text-lg font-semibold">Convite Inválido!</h2> 
                    <p class="mt-4">Talvez ele tenha sido excluído. Entre em contato com o usuário que te convidou.</p>
                </div>
                <div v-else-if="invite.status === 'ALREADY_INVITED'">
                    <h2 class="text-lg font-semibold">Convite já foi aceito!</h2> 
                    <p class="mt-4">Você já aceitou este convite.</p>
                    <p class="mt-4 py-2">
                        <router-link 
                            tag="a" 
                            :to="{ name: 'user.index'}"
                            class="justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Acesse sua conta
                        </router-link>
                    </p>
                </div>
                <div v-else-if="invite.status === 'ALREADY_MEMBER'">
                    <h2 class="text-lg font-semibold">Você já está na organização!</h2> 
                    <p class="mt-4">Você já faz parte dessa organização.</p>
                    <p class="mt-4 py-2">
                        <router-link 
                            tag="a" 
                            :to="{ name: 'user.index'}"
                            class="justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Acesse sua conta
                        </router-link>
                    </p>
                </div>
            </div>
            <div v-else class="bg-white shadow sm:rounded-lg p-6">
                <h2 class="text-lg font-semibold">Sucesso!</h2>
                <p class="mt-4">Agora você faz parte da empresa <strong>{{  invite.invite.company }}.</strong></p>
                <p class="mt-4 py-2">
                    <router-link 
                        tag="a" 
                        :to="{ name: 'user.index'}"
                        class="justify-center py-2 px-4 border border-transparent rounded-md shadow-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Acesse sua conta
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import CompanyInviteService from '@/services/CompanyInviteService';

import Spinner from '@/components/Spinner.vue';
import Overlay from '@/components/Overlay.vue';

enum Status {
    PENDING = 'PENDING',
    EXPIRED = 'EXPIRED',
    INVALID = 'INVALID',
    ACCOUNT_REQUIRED = 'ACCOUNT_REQUIRED',
    ALREADY_INVITED = 'ALREADY_INVITED',
};

interface InviteResponse {
    invite: {
        company: string;
        inviter: {
            name: string;
            email: string;
        },
        email: string;
    },
    status: Status;
}

const route = useRoute();
const isLoading = ref(false);
const invite = ref<InviteResponse>({} as InviteResponse);
const accepted = ref(false);

const state = ref({
    id: String(route.query.id),
    expires: String(route.query.expires),
    hash: String(route.query.hash),
    signature: String(route.query.signature)
});

async function verify(): Promise<void> {
    isLoading.value = true;
    try {
        const response = await CompanyInviteService.verify(state.value);
        invite.value = response.data;
        isLoading.value = false;
    } catch (error: any) {
        invite.value.status = Status.INVALID
        isLoading.value = false;
    }
}

async function accept(): Promise<void> {
    isLoading.value = true;
    try {
        const response = await CompanyInviteService.accept(state.value)
        isLoading.value = false;
        accepted.value = true;
    } catch (error: any) {
        if(error.response.data.message) {

        }
        isLoading.value = false;
        accepted.value = false;
    }
}

onMounted(async () => {
   await verify();
})
</script>