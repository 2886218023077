<template>
    <div>
        <div 
            style="border-top-color:transparent"
            class="border-solid rounded-full animate-spin opacity-100"
            :class="[size, borderSize, borderColor]">
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from '@vue/reactivity';
import { onBeforeUpdate, onMounted, watch } from 'vue';

const props = defineProps({
    size: {
        type: String,
        required: false,
        default: 'w-8 h-8'
    },
    borderSize: {
        type: String,
        required: false,
        default: 'border-4'
    },
    borderColor: {
        type: String,
        required: false,
        default: 'border-indigo-900'
    }
});

const spinProps = ref(toRefs(props))
</script>