import { createApp, ref, InjectionKey, Ref, reactive } from 'vue';
import VCalendar from 'v-calendar';
import AppLayout from '@/layouts/AppLayout.vue';
import App from './App.vue';
import router from './router';
import { store, key } from './store'
import { createI18n } from 'vue-i18n'
import './index.css'
import 'v-calendar/dist/style.css';
import dayjs from 'dayjs'
import UTC from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/pt-br';

import enUS from './locales/en-US.json'
import ptBR from './locales/pt-BR.json'
import { IFilter } from './types/IFilter';

const i18n = createI18n({
    legacy: false,
    locale: 'pt-BR',
    fallbackLocale: 'en-US',
    messages: {
        'en-US': enUS,
        'pt-BR': ptBR
    },
})

dayjs.extend(UTC)
dayjs.extend(Timezone)
dayjs.tz.setDefault("UTC")
dayjs.locale('pt-br');

const Filter = reactive<IFilter>({});

createApp(App)
    .use(router)
    .use(store, key)
    .use(VCalendar, {masks: { input: ['YYYY-MM-DD', 'YYYY/MM/DD']}})
    .use(i18n)
    .provide('Filter', Filter)
    .component('AppLayout', AppLayout)
    .mount('#app');
