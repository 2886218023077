import { reject } from "lodash";

export interface IIMageGeneralOptions {
    backgroundColor: string;
    quality: number;
    width: number;
    height: number;
}

export interface IImageToCompile {
    src: string;
    y: number;
    x: number;
}

export async function combine(imgSources: IImageToCompile[], options: IIMageGeneralOptions) {
    return Promise.all(imgSources.map(function(image) {
        return new Promise(function(resolve) {
			var img = new Image();
            img.onload = function() { resolve({image: img, origin: image}); };
			img.onerror = () => reject(new Error('Couldn\'t load image'));
            img.src = image.src;
            return {image: img, options: options};
        });
    })).then(function(images: any[]) {
        var canvas = document.createElement('canvas');
        canvas.width = options.width;
        canvas.height = options.height;

        var ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.fillStyle = options.backgroundColor;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            images.forEach((image: any) => {
                if (ctx) {
                    ctx.drawImage(image.image, image.origin.x, image.origin.y);
                }
            });
        }

        return canvas.toDataURL('image/png');
    });
}
