import { Module } from 'vuex';
import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { StateType } from './types';
import { IRootState } from '@/store/interfaces';

const auth: Module<StateType, IRootState> = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}

export default auth;
export type AuthModule = typeof auth;
