import { MutationTree } from "vuex"
import MutationEnum from "./mutation-enum"
import { MutationType, StateType } from "./types"
import Company from "@/types/Company"
import Info from "@/types/User/Info"

export const mutations: MutationTree<StateType> & MutationType = {
    [MutationEnum.ADD_INFO](state, payload: Info) {
        state.user = payload;
    },
    [MutationEnum.ADD_COMPANY](state, payload: Company) {
        state.companies.push(payload);
    },
    [MutationEnum.SET_CURRENT_COMPANY](state, payload: Company) {
        state.company = payload;
    },
    [MutationEnum.CLEAR_COMPANIES](state) {
        state.companies = [];
    },
    [MutationEnum.CLEAR_ALL](state) {
        state.user = null;
        state.company = null;
        state.companies = [];
    },
}