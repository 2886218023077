import { NavigationGuard } from "vue-router";
import {store} from '@/store';
import ActionEnum from '@/store/modules/user/action-enum'

const authGuard: NavigationGuard = async (to, from, next) => {

    if (to.matched.some( record => record.meta.auth)) {

        let isAuthenticated = store.getters['auth/isAuthenticated'];

        if(!isAuthenticated) {
            await store.dispatch(`user/${ActionEnum.GET_INFO}`)
        }

        isAuthenticated = store.getters['auth/isAuthenticated'];

        if (!isAuthenticated) {
            next({
                name: 'login',
                query: { 
                    redirect: to.name?.toString()
                }
            });

            return;
        }
    }

    next();
}

export default authGuard;