import { store } from "@/store";
import UserActionEnum from '@/store/modules/user/action-enum';
import router from "@/router";
import CompanyService from "@/services/CompanyService";
import Company from "@/types/Company";

class createUserCompany {

    public async execute (name: string) {
        
        try{
            
            let response = await CompanyService.store(name);

            const company: Company = response.data.data;

            store.commit('user/ADD_COMPANY', company)
            store.commit('user/SET_CURRENT_COMPANY', company)

            await router.push({ name: 'company.dashboard' });

        } catch(error: any) {
            throw error;
        }
    }
}

export default new createUserCompany();