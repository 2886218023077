import { AxiosResponse } from "axios";
import api from "./http/api";
import Role from "@/types/Role";

class RoleService {
    list() {
        return api({ auth: true, company: true })
        .get<AxiosResponse<Role[]>>(`/backoffice/v1/roles`);
    }
}

export default new RoleService();