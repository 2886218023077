<template>
<div class="relative overflow-visible">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" border-size="border-2" size="h-4 w-4"/>
    </Overlay>
    <Combobox v-model="selectedBank">
        <div class="relative mt-1">
            <ComboboxInput 
                class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" 
                @change="onChange($event.target.value)"
                :displayValue="(bank: any) => bank ? `${bank.name}` : ''"
                placeholder="Selecione um banco"
            />
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions v-if="filteredBanks.length > 0" 
                class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
                <ComboboxOption v-for="bank in filteredBanks" :key="bank.id" :value="bank" as="template" v-slot="{ active, selected }">
                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                        <div class="flex items-center justify-start">
                        <!--<img :src="person.imageUrl" alt="" class="h-6 w-6 flex-shrink-0 rounded-full" />-->
                            <span class="text-xs text-gray-500">
                                {{ bank.code }}
                            </span>
                            <span :class="['ml-2 truncate', selected && 'font-semibold']">
                                {{ bank.name }} 
                            </span>
                            
                        </div>

                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>

                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
        <p class="block w-96"></p>
    </Combobox>
</div>
</template>

<script setup lang="ts">
import { ref, toRef, computed, reactive, onMounted, watch, PropType } from 'vue'
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    ComboboxButton
} from '@headlessui/vue'
import { debounce } from 'lodash'
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid'
import BankService from '@/services/BankService';
import Bank from '@/types/Bank';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';

const emit = defineEmits(['update:modelValue', 'change', 'input'])

const props = defineProps({
    modelValue: {
        type: Object as PropType<Bank>,
        default: () => ({})
    }
})

const state = reactive({
    banks: [] as Bank[]
})

const selectedBank = computed({
    get: () => props.modelValue,
    set: (value) => { 
        emit('update:modelValue', value)
        emit('change', value)
        emit('input', value)
    }
});

const query = ref('')

const filteredBanks = computed(() =>
  query.value === ''
    ? state.banks
    : state.banks.filter((bank: Bank) => {
        return (bank.name) ? bank.name.toLowerCase().includes(query.value.toLowerCase()) : null
    })
)

const isLoading = ref(false);

const onChange = async (event: string) => {
    query.value = event;
    //debouncedFilter(event)
    
}

const loadBanks = async () => {
    isLoading.value = true;
    try {
        let response = await BankService.list({paginate: false, sort: 'code', name: query.value})
        state.banks = response.data.data;
        isLoading.value = false;
    } catch(error) {
        console.log(error);
        isLoading.value = false;
    }
}

const debouncedFilter = debounce(loadBanks, 200) // 250ms delay
onMounted(async() => {
    await loadBanks()
})
</script>