<template>
    <div class="relative">
        <Overlay :show="isLoading">
            <Spinner border-color="border-gray-500" />
        </Overlay>

        <div class="mx-auto max-w-none">
            <div class="overflow-hidden bg-white sm:rounded-lg sm:shadow">
                <div class="border-b border-gray-100 bg-white px-4 py-4 sm:px-6">
                    <h3 class="text-sm text-gray-400">Minhas empresas</h3>
                    <!--<p class="mt-1 text-sm text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit quam corrupti consectetur.</p>-->
                </div>

                <ul role="list" class="divide-y divide-gray-100">
                    <li v-for="company in state.companies" :key="company.id" class="pr-4 text-sm">
                        <div class="flex items-center justify-between">
                            <div class="px-4 py-4">
                                <div class="flex items-center justify-between">
                                    <OfficeBuildingIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    <span class="ml-2 truncate text-sm font-medium text-gray-600">{{ company.name }}</span>
                                    <span title="Empresa atual" v-if="currentCompany?.id == company.id">
                                        <CheckCircleIcon class="flex-shrink-0 ml-2 h-4 w-4 text-green-400" aria-hidden="true"/>
                                    </span>
                                </div>
                            </div>
                            <div class="ml-4 flex-shrink-0">
                                <a href="#" @click="switchCompany(company.id)" class="font-medium text-indigo-600 hover:text-indigo-500"> Acessar </a>
                            </div>
                        </div>
                        
                    </li>
                    <li class="px-4 py-4 flex items-center justify-between text-sm">
                        <a href="#" @click="() => router.push({name: 'user.new-company'})" class="w-0 flex-1 flex items-center text-gray-300 hover:text-gray-500">
                            <PlusIcon class="flex-shrink-0 h-4 w-4 " aria-hidden="true" />
                            <span class="ml-2 flex-1 w-0 truncate">Nova</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { computed } from '@vue/reactivity';
import { store } from '@/store';
import { OfficeBuildingIcon, PlusIcon } from '@heroicons/vue/outline'
import { CheckCircleIcon } from '@heroicons/vue/solid'
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import UserService from '@/services/UserService';
import Company from '@/types/Company';
import ActionEnum from '@/store/modules/user/action-enum'
import { Toast } from '@/constants/swal-mixins';

const isLoading = ref(false);

const router = useRouter();

const state = reactive({
    companies: [] as Company[]
});

const currentCompany = computed<Company>(() => store.getters['user/currentCompany'])

const loadCompanies = async () => {
    try {
        let result = await UserService.companies();
        state.companies = result.data.data;
    } catch( error ) {

    }
}

const switchCompany = async (companyId: string) => {
    isLoading.value = true

    try {
        await UserService.changeCurrentCompany(companyId);
        await store.dispatch(`user/${ActionEnum.GET_INFO}`)
        await Toast.fire({
            icon: 'success',
            title: 'Você mudou de empresa',
            iconColor: 'white',
        })
        await router.push({name: 'company.dashboard'});
        
    } catch (error) {
        isLoading.value = false;
    }
}

onMounted(async () => {
    isLoading.value = true;
    await loadCompanies();
    isLoading.value = false;
});
</script>
