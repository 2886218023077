import { AxiosResponse } from "axios";
import api from "./http/api";

class CompanyService {

    store(name: string) {
        return api({ auth: true }).post('/backoffice/v1/user/companies', {
            name
        });
    }

}

export default new CompanyService();