import { Module } from 'vuex'
import { state } from './state'
import { mutations } from './mutations'
import { getters } from './getters'
import { StateType } from './types'
import { IRootState } from '@/store/interfaces'

const layout: Module<StateType, IRootState> = {
    namespaced: true,
    state,
    mutations,
    getters,
}

export default layout;
export type LayoutModule = typeof layout;