import { IRootState } from "@/store/interfaces"
import { GetterTree } from "vuex"
import { GetterType, StateType } from "./types"

export const getters: GetterTree<StateType, IRootState> & GetterType<StateType> = {
    hasCompanies: (state: StateType)  => state.companies.length > 0,
    companies: (state: StateType) => state.companies,
    currentCompany: (state: StateType) => state.company,
    userInfo: (state: StateType) => state.user,
    emailVerified: (state: StateType) => !!state.user?.email_verified_at
}