<template>
<SideOverlayRight :open="props.open" @close="emit('close')" v-slot="{ close }">
    <div class="flex h-full flex-col bg-white shadow-xl relative overflow-visible p-0">
        <Overlay :show="isLoading">
            <Spinner border-color="border-gray-500" />
        </Overlay>

        <!--header-->
        <div class="px-4 py-4 sm:px-6 bg-gray-100">
            <div class="flex items-start justify-between">
                <DialogTitle class="text-sm font-medium text-gray-400">Detalhes do Lançamento</DialogTitle>
                <div class="ml-3 flex h-7 items-center">
                    <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="emit('close')">
                        <span class="sr-only">Fechar painel</span>
                        <XIcon class="h-4 w-4" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </div>

        <!--Content-->
        <div class="relative flex-1 px-4 sm:px-6 mt-4 overflow-y-scroll overflow-hidden">
            <div class="absolute inset-0 px-4 sm:px-6">
                <div class="h-full text-md flex flex-col" aria-hidden="true">
                    <div class="px-4 py-5 sm:px-0 sm:py-0">
                        <div class="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6 text-gray-600">
                            <div class="sm:flex-1">
                                <div class="flex items-center">
                                    <h3 class="text-xl font-medium sm:text-xl flex items-center">
                                        <!--<TransactionKindBadge :kind="transaction.kind" class="h-4 w-4" />-->
                                        {{ transaction?.description }}
                                    </h3>
                                </div>

                                <div class="text-lg mt-1 font-medium" :class="[transaction?.amount < 0 ? 'text-red-500' : 'text-blue-500']">
                                    {{ Dinero({ amount: transaction.amount,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                </div>
                            </div>
                        </div>

                        <dl class="space-y-8 px-4 mt-4 sm:space-y-6 sm:px-6">
                            <!--Date-->
                            <div>
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Data</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    <div class="text-sm">
                                        {{ dayjs(transaction.billing_date).format("DD/MM/YYYY") }}
                                        <span title="Lançamento atrasado" v-if="transaction.is_overdue">
                                            <ExclamationIcon class="h-4 w-4 inline text-orange-300" />
                                        </span>
                                    </div>
                                </dd>
                            </div>

                            <!--Accounts-->
                            <div v-if="transaction.origin_account && transaction.target_account" class="space-y-6">
                                <div>
                                    <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Conta origem</dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                        <span class="text-sm">
                                            <router-link 
                                                :to="{name:'company.accounts.detail', params: {id: transaction.origin_account_id }}" 
                                                custom
                                                v-slot="{ href, navigate }"
                                            >
                                                <a :href="href" @click="navigate" class="text-indigo-700 hover:text-indigo-600 hover:underline ease-in duration-200">
                                                    {{ transaction.origin_account.name }}
                                                </a>
                                            </router-link>
                                        </span>
                                    </dd>
                                </div>

                                <div>
                                    <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Conta destino</dt>
                                    <dd class="mt-1 text-sm sm:col-span-2">
                                        <span class="text-sm">
                                            <router-link 
                                                :to="{name:'company.accounts.detail', params: {id: transaction.target_account_id }}" 
                                                custom
                                                v-slot="{ href, navigate }"
                                            >
                                                <a :href="href" @click="navigate" class="text-indigo-700 hover:text-indigo-600 hover:underline ease-in duration-200">
                                                    {{ transaction.target_account.name }}
                                                </a>
                                            </router-link>
                                        </span>
                                    </dd>
                                </div>
                            </div>
                            <div v-else-if="transaction.origin_account" class="text-sm">
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Conta</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    <router-link :to="{name:'company.accounts.detail', params: {id: transaction.origin_account_id }}" tag="a" class="truncate hover:text-gray-500">
                                        {{ transaction.origin_account.name }}
                                    </router-link>
                                </dd>
                            </div>
                            <div v-else-if="transaction.target_account" class="text-sm">
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Conta</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    <router-link :to="{name:'company.accounts.detail', params: {id: transaction.target_account_id }}" tag="a" class="truncate hover:text-gray-500">
                                        {{ transaction.target_account.name }}
                                    </router-link>
                                </dd>
                            </div>

                            <!--Category-->
                            <div>
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Categoria</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    <!--<CategoryBadge :kind="transaction.kind" :text="transaction.category?.name" />-->
                                    {{ transaction.category?.name }}
                                </dd>
                            </div>

                            <!--Status-->
                            <div>
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Situação</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    <div v-if="transaction.is_payable">
                                        <div v-if="transaction.paid" title="Marcar como não-paga" class="flex items-center">
                                            <ThumbUpIcon class="h-4 w-4 inline mr-2" aria-hidden="true" /> 
                                            <span class="text-sm">Pago</span>
                                        </div>
                                        <div v-else title="Marcar como paga" class="flex items-center" :class="[!transaction.is_payable ? 'disabled:opacity-75' : '']">
                                            <ThumbDownIcon class="h-4 w-4 inline mr-2" aria-hidden="true" /> 
                                            <span class="text-sm">Não-pago</span>
                                        </div>
                                    </div>
                                </dd>
                            </div>

                            <!--Recurrence-->
                            <div v-if="transaction.is_recurring" class="rounded-md">
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Repetição</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 flex">
                                    <RecurringIcon :type="transaction.recurrence?.limit_by" class="h-6 w-6"/>
                                    <span v-if="transaction.recurrence?.limit_by=='DATE'">Recorrente, se repete em outras datas</span>
                                    <span v-else-if="transaction.recurrence?.limit_by=='COUNT'">Parcelado</span>
                                </dd>
                            </div>

                            <!--Installments-->
                            <div v-if="transaction.is_recurring && transaction.recurrence?.limit_by=='COUNT'">
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Parcela</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2">
                                    {{ transaction.recurrence_index }}/{{ transaction.recurrence_total }}
                                </dd>
                            </div>

                            <!--Installments-->
                            <div v-if="transaction.is_recurring && transaction.recurrence?.limit_by=='COUNT'">
                                <dt class="text-sm font-medium text-gray-400 sm:w-40 sm:flex-shrink-0">Valor parcelado</dt>
                                <dd class="mt-1 text-sm sm:col-span-2" :class="[transaction?.recurrence?.amount && transaction?.recurrence?.amount < 0 ? 'text-red-500' : 'text-blue-500']">
                                    {{ Dinero({ amount: transaction.recurrence.amount || 0,  currency: 'BRL' }).setLocale('pt-BR').toFormat() }}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>
        </div>

        <!--Actions-->
        <div class="flex justify-between border-t px-4 py-4" v-if="transaction.is_payable">
                <a href="#" @click.prevent="setPaid(transaction, false)" v-if="transaction.paid" title="Marcar como não-paga" class="justify-center  flex items-center text-green-600 hover:text-green-700">
                    <ThumbUpIcon class="h-5 w-5 inline mr-2" aria-hidden="true" /> 
                    <span class="text-sm">Pago</span>
                </a>
                <a href="#" @click.prevent="setPaid(transaction, true)" v-else title="Marcar como paga" class="justify-center flex items-center" :class="[!transaction.is_payable ? 'disabled:opacity-75' : '']">
                    <ThumbDownIcon class="h-5 w-5 inline text-gray-400 group-hover:text-gray-500 mr-2" aria-hidden="true" /> 
                    <span class="text-sm text-gray-500 group-hover:text-gray-500">Não-pago</span>
                </a>
            
                <a href="#" @click.prevent="openModal()" class="flex items-center justify-center text-sm group">
                    <PencilIcon class="w-4 h-4 text-gray-400 group-hover:text-gray-500 mr-2" /> 
                    <span class="group-hover:text-gray-500">Editar</span>
                </a>

                <a href="#" @click.prevent="confirmDelete" class="flex items-center justify-center  text-sm group">
                    <TrashIcon class="w-4 h-4 text-gray-400 mr-2 group-hover:text-red-500" />
                    <span class="group-hover:text-red-500">Excluir</span>
                </a>
        </div>
        <div id="toast-popup"></div>
    </div>

    <Modal :show="modalOpen" @close="() => modalOpen = false" :static="false" #default="{slotProps}">
        <Component
            :is="modalComponent"
            :transaction="transaction.id"
            @updated="() => { slotProps.close(); onUpdated(); }"
            @deleted="() => { slotProps.close(); onDeleted(); }"
            @canceled="slotProps.close(); onCanceled()" 
            @error="(error: any) => { slotProps.close(); onError(error); }" 
        />
    </Modal>
</SideOverlayRight>
</template>

<script setup lang="ts">
import { PropType, ref, shallowRef, toRef } from 'vue';
import { DialogTitle } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { 
    PencilIcon, 
    TrashIcon, 
    ThumbUpIcon, 
    ExclamationIcon 
} from '@heroicons/vue/solid';

import { 
    ThumbDownIcon, 
} from '@heroicons/vue/outline';


import dayjs from 'dayjs'
import Dinero from 'dinero.js';
import { DangerSwal, Toast } from '@/constants/swal-mixins';
import TransactionType from '@/types/Transaction/TransactionType';
import TransactionService from '@/services/TransactionService';
import SideOverlayRight from '@/components/Shared/SideOverlayRight.vue';
import Swal from 'sweetalert2';
import Modal from '@/components/Modal.vue';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import RecurringIcon from '../RecurringIcon.vue';
import UpdateExpenseForm from './UpdateExpenseForm.vue';
import UpdateIncomeForm from './UpdateIncomeForm.vue';
import UpdateTransferenceForm from './UpdateTransferenceForm.vue';
import ConfirmDeleteTransactionForm from './ConfirmDeleteTransactionForm.vue';

const props = defineProps({
    open: {
        type: Boolean,
        default: false
    },
    transaction: {
        type: Object as PropType<TransactionType>,
        required: true
    }

})

const transaction = toRef(props, 'transaction');

const emit = defineEmits(['open', 'close', 'updated', 'deleted'])

const isLoading = ref(false);

interface ModalComponentsType {
    key: string,
    component: any
}

const modalComponents: ModalComponentsType[] = [
    { key: "expense", component: UpdateExpenseForm },
    { key: "income", component: UpdateIncomeForm },
    { key: "transference", component: UpdateTransferenceForm },
    { key: "confirm-delete", component: ConfirmDeleteTransactionForm },
];

const modalComponent = shallowRef(UpdateExpenseForm);

const modalOpen = ref(false);

const setModalIsOpen = (value: boolean) => {
    modalOpen.value = value
}

const setModalComponent = (value: string) => {
    modalComponent.value = modalComponents.find(component => component.key == value)?.component
}

const openModal = () => {
    setModalComponent(transaction.value?.kind || '');
    setModalIsOpen(true);
}

const onCreated = async () => {
    
}

const onUpdated = () => {
    emit('updated')
}

const onDeleted = () => {
    emit('deleted')
}

const onCanceled = () => {
    
}

const onError = (error: any) => {

}

const confirmDelete = () => {
    setModalComponent('confirm-delete');
    setModalIsOpen(true);
}

const setPaid = async(transaction: TransactionType, pay: boolean) => {
    isLoading.value = true

    try {
    
        if(transaction.id) {
            let response = (pay) ? await TransactionService.pay(transaction.id) : await TransactionService.unpay(transaction.id);
            transaction.paid = pay;
            transaction.is_overdue = response.data.data.is_overdue
        }

        Toast.fire({
            position: 'top',
            icon: 'success',
            title: 'Sucesso!',
            text: 'Lançamento atualizado com sucesso!',
            iconColor: 'white',
        })

        isLoading.value = false;
    } catch( error ) {
        Toast.fire({
            icon: 'error',
            title: 'Ops!',
            text: 'Falha ao atualizar o lançamento!',
            iconColor: 'white',
        })
        isLoading.value = false;
    }
}
</script>