<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img class="mx-auto h-12 w-auto" src="@/assets/fluxous-logo.svg" alt="Workflow" />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Esqueceu sua senha?</h2>
            <p class="mt-2 text-center text-sm text-gray-600">
                Informe seu email abaixo para alterá-la.
            </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div v-if="state.sent">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                        <CheckCircleIcon class="h-10 inline text-green-400 align-center" aria-hidden="true" />
                        E-mail de recuperação enviado
                    </h3>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Enviamos para <strong>{{ state.email }}</strong> as instruções para alterar sua senha.</p>
                        <br>
                        <p><strong>O email ainda não chegou?</strong> Aguarde alguns minutos ou envie o link novamente.</p>
                        <button :disabled="isLoading"
                            @click="sendResetLink" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <Spinner v-if="isLoading" />
                            Envie o link novamente
                        </button>
                    </div>
                </div>

                <form v-else class="space-y-6" @submit.prevent="onSubmit" novalidate>
                    <div>
                        <label for="email" class="block text-sm font-medium text-gray-700">Informe seu e-mail</label>
                        <div class="mt-1">
                            <input id="email" name="email" v-model="state.email" type="email" autocomplete="email"
                                :required="true" placeholder="Informe seu email"
                                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                        </div>
                        <div v-if="v$.email.$error" class="text-sm text-red-600">
                            <div>{{ v$.email.$errors[0].$message }}</div>
                        </div>
                    </div>

                    <div>
                        <button :disabled="isLoading" type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <Spinner v-if="isLoading" />
                            Recuperar senha
                        </button>
                    </div>
                </form>
            </div>

            <p class="mt-6 text-center text-sm text-gray-400">
                Já possuo uma conta. <router-link tag="a" to="/login"
                    class="link-effect text-indigo-600 hover:text-indigo-500 font-bold">
                    Quero entrar
                </router-link>
            </p>

        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators';
import { AxiosError } from 'axios';
import { computed } from '@vue/reactivity';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import Spinner from '@/components/Spinner.vue'
import AuthService from '@/services/AuthService';

const state = reactive({
    email: '',
    sent: false
});

const isLoading = ref(false);

const rules = computed(() => ({
    email: {
        required: helpers.withMessage('Informe seu e-mail de cadastro', required),
        email: helpers.withMessage('Utilize um e-mail válido', email)
    }
}));

const v$ = useVuelidate(rules, state);

const sendResetLink = async () => {
    isLoading.value = true;

    try {
        await AuthService.sendResetPasswordEmail(state.email);

        state.sent = true;
        isLoading.value = false;

    } catch (error: any | AxiosError) {
        console.log(error?.response.data.message)
        state.sent = false;
        isLoading.value = false;
    }
};

const onSubmit = async () => {
    const isValid = await v$.value.$validate();
    state.sent = false;

    if (!isValid) return;

    await sendResetLink();
}

</script>