import api from "./http/api";
import CompanyInvite from "@/types/CompanyInvite";

interface UserInviteListResponse {
    data: Array<CompanyInvite>
}

class UserInviteService {
    list() {
        return api({ auth: true })
            .get<UserInviteListResponse>('/backoffice/v1/user/invites', { params: {
                accepted: false,
                sort: 'created_at',
                with: 'role,inviter,company'
            }});
    }
}

export default new UserInviteService();