<template>
<div class="relative overflow-visible pb-6">
    <Overlay :show="isLoading">
        <Spinner border-color="border-gray-500" />
    </Overlay>
    <div class="bg-white px-4 py-5 sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div class="ml-4 mt-2">
                <h2 class="text-xl leading-4 font-medium text-gray-900">Integrações</h2>
                <p class="mt-2 max-w-4xl text-sm text-gray-400">Permita que aplicações externas se autentiquem e acessem seus dados, crie transações e contas bancárias.</p>
            </div>
        </div>

        <div class="flex flex-wrap items-center justify-between border-b pb-2 mt-4">
            <h1 class="text-xl font-medium text-gray-900">Clientes</h1>
            <button v-if="clients.length" type="button" @click="createModal" class="inline-block items-center px-2 py-2 border border-transparent text-xs rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Novo cliente
            </button>
        </div>

        <div v-if="clients.length" class="mt-4">
            <p class="text-sm">Esta é uma lista de clientes associados a sua conta. Remova-os se não necessita mais ou se não os reconhece.</p>
            <ul role="list" class="divide-y divide-gray-300 border-2 border-gray-300 rounded-lg mt-4">
                <li class="" v-for="client in clients" :key="client.id">
                    <div class="px-2">
                        <div class="px-2 py-4 flex-1">
                            <div class="flex items-center justify-between">
                                <p class="truncate text-sm font-medium text-gray-600">
                                    <IdentificationIcon class="w-6 h-6 text-gray-400 inline"/> {{ client.name }}
                                </p>
                                <div class="ml-2 flex flex-shrink-0">
                                    <a href="#" @click.stop="editModal(client)" class="inline-flex rounded-full px-2 text-xs text-gray-600">editar</a>
                                    <a href="#" @click.stop="handleDelete(client)" class="inline-flex rounded-full px-2 text-xs text-red-600">excluir</a>
                                </div>
                            </div>

                            <div class="mt-2 sm:flex sm:justify-between">
                                <div class="sm:flex flex flex-col gap-2">
                                    <div class="flex items-center text-xs text-gray-600">
                                        <span class="text-right border p-1 rounded-lg mr-2">client_id</span>
                                        <span>{{ client.id }}</span>
                                    </div>
                                    <div class="flex items-center text-xs text-gray-600">
                                        <span class="text-right border p-1 rounded-lg mr-2">client_secret</span> 
                                        <span>******************</span>
                                    </div>
                                </div>
                                <div class="sm:flex flex flex-col gap-1">
                                    <div class="flex items-center justify-end  text-xs text-gray-400">
                                        <span class="text-right p-1 rounded-lg mr-2">Criado em: </span>
                                        <time>{{ dayjs(client.created_at).format('DD/MM/YYYY HH:mm') }}</time>
                                    </div>
                                    <div class="flex items-center justify-end text-xs text-gray-400">
                                        <span class="text-right p-1 rounded-lg mr-2">Alterado em: </span>
                                        <time>{{ dayjs(client.updated_at).format('DD/MM/YYYY HH:mm') }}</time>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div v-else class="flex flex-col items-center justify-center rounded-lg border bg-gray-100 p-6 mt-2">
            <div class="text-center text-sm">Nenhum cliente cadastrado.</div>
            
            <div class="ml-4 mt-2 flex-shrink-0">
                <button type="button" @click="createModal" class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Novo cliente
                </button>
            </div>
        </div>
    </div>

    <Modal :show="modalOpen" :static="true" @close="() => modalOpen = false" #default="{slotProps}">
        <Component 
            :is="componentsModal[modalMode]"
            :client="currentClient"
            @canceled="slotProps.close(); handleCancelModal()" 
            @created="(data: OAuthClient) => { slotProps.close(); handleCreateModal(data) }"
            @updated="() => { slotProps.close(); handleUpdateModal() }"
        />
    </Modal>
</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { IdentificationIcon } from '@heroicons/vue/outline';
import { DangerSwal, Toast } from '@/constants/swal-mixins';

import OAuthClientService from '@/services/OAuthClientService';
import OAuthClient from "@/types/OAuthClient";
import Modal from '@/components/Modal.vue';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import NewClientForm from '@/components/OAuthClients/NewClientForm.vue';
import PlainSecretForm from '@/components/OAuthClients/PlainSecretForm.vue';
import EditClientForm from '@/components/OAuthClients/EditClientForm.vue';

enum ModalModeEnum {
    CREATE = 'create', 
    CREATED = 'created',
    EDIT = 'edit'
}
const componentsModal = { 
    [ModalModeEnum.CREATE] : NewClientForm,
    [ModalModeEnum.CREATED] : PlainSecretForm,
    [ModalModeEnum.EDIT] : EditClientForm,
}
const isLoading = ref(false);
const modalOpen = ref(false);
const modalMode = ref(ModalModeEnum.CREATE);

const clients = ref<OAuthClient[]>([]);
const currentClient = ref<OAuthClient>();

const getClients = async () => {
    isLoading.value = true;
    try {
        let response = await OAuthClientService.list();
        clients.value = response.data;
        isLoading.value = false;
    } catch(error) {
        isLoading.value = false;
    }
}

const handleDelete = async (client: OAuthClient) => {
    await DangerSwal.fire({
        icon: 'question',
        title: `Excluir o cliente`,
        html: `Deseja realmente excluir o cliente <strong>${client.name}</strong>?`,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, excluir',
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: () => {
            return new Promise(async (resolve, reject) => {
                if(client.id) {
                    try {
                        await OAuthClientService.delete(client.id);
                        Toast.fire({
                            icon: 'success',
                            title: 'Cliente excluído com sucesso!',
                            iconColor: 'white',
                        })
                        await getClients();
                        resolve(true)
                    } catch(error) {
                        Toast.fire({
                            icon: 'error',
                            title: 'Erro ao excluir o cliente',
                            iconColor: 'white',
                        })
                        reject(error)
                    }
                }
            })
        }
    })
}

function createModal() {
    modalOpen.value = true
    modalMode.value = ModalModeEnum.CREATE
}

function editModal(client: OAuthClient) {
    currentClient.value = client;
    modalOpen.value = true
    modalMode.value = ModalModeEnum.EDIT
}

async function handleUpdateModal() {
    Toast.fire({
        icon: 'success',
        title: 'Cliente atualizado com sucesso',
        iconColor: 'white',
    });

    await getClients();
}

async function handleCancelModal() {

}

async function handleCreateModal(data: OAuthClient) {
    Toast.fire({
        icon: 'success',
        title: 'Cliente criado com sucesso',
        iconColor: 'white',
    })
    currentClient.value = data;
    modalOpen.value = true
    modalMode.value = ModalModeEnum.CREATED;

    await getClients();
}

onMounted(async () => {
    await getClients();
})
</script>