<template>
    <div class="relative">
        <Overlay :show="isLoading">
            <Spinner border-color="border-gray-500" />
        </Overlay>
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4 select-none">
            <div class="px-4 pt-2 pb-4 flex flex-col justify-start align-center bg-white shadow rounded-lg overflow-hidden">
                <dt class="text-sm text-gray-400 truncate font-medium">
                    Saldo Geral
                </dt>
                <dd class="mt-1" :class="{'blur-sm': !showAmount}">
                    <div>
                        <span class="text-sm text-gray-500 mr-2">R$</span> 
                        <span class="text-3xl font-medium" :class="[state.total < 0 ? 'text-red-500' : 'text-blue-500']">
                            {{ !showAmount ? '---' : Dinero({ amount: Number(state.total), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                </dd>
            </div>
            <div class="px-4 pt-2 pb-4 bg-white shadow rounded-lg overflow-hidden">
                <dt class="text-sm text-gray-400 truncate font-medium">
                    Saldo Mensal
                </dt>
                <dd class="mt-1" :class="{'blur-sm': !showAmount}">
                    <div>
                        <span class="text-sm text-gray-500 mr-2">R$</span> 
                        <span class="text-2xl font-medium" :class="[state.balance.consolidated < 0 ? 'text-red-500' : 'text-blue-500']">
                            {{ !showAmount ? '---' : Dinero({ amount: Number(state.balance.consolidated), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                    <div title="Saldo previsto">
                        <span class="text-xs text-gray-300 mr-1">R$</span>
                        <span class="text-xs text-gray-300">
                           {{ !showAmount ? '---' : Dinero({ amount: Number(state.balance.predicted), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                </dd>
            </div>
            <div class="px-4 pt-2 pb-4 bg-white shadow rounded-lg overflow-hidden">
                <dt class="text-sm text-gray-400 truncate font-medium">
                    Receita mensal
                </dt>
                <dd class="mt-1" :class="{'blur-sm': !showAmount}">
                    <div>
                        <span class="text-sm text-gray-500 mr-2">R$</span> 
                        <span class="text-2xl font-medium text-green-500">
                            {{ !showAmount ? '---' : Dinero({ amount: Number(state.incomes.consolidated), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                    <div title="Receita prevista">
                        <span class="text-xs text-gray-300 mr-1">R$</span>
                        <span class="text-xs text-gray-300">
                            {{ !showAmount ? '---' : Dinero({ amount: Number(state.incomes.predicted), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                </dd>
            </div>
            <div class="px-4 pt-2 pb-4 bg-white shadow rounded-lg overflow-hidden">
                <dt class="text-sm text-gray-400 truncate font-medium">
                    Despesa mensal
                </dt>
                <dd class="mt-1" :class="{'blur-sm': !showAmount}">
                    <div>
                        <span class="text-sm text-gray-500 mr-2">R$</span> 
                        <span class="text-2xl font-medium text-red-500">
                            {{ !showAmount ? '---' : Dinero({ amount: Number(state.expenses.consolidated), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                    <div title="Despesa prevista">
                        <span class="text-xs text-gray-300 mr-1">R$</span>
                        <span class="text-xs text-gray-300">
                           {{ !showAmount ? '---' :Dinero({ amount: Number(state.expenses.predicted), currency: 'BRL'}).setLocale('pt-BR').toFormat('0,0.00') }}
                        </span>
                    </div>
                </dd>
            </div>
        </dl>
    </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import Dinero from 'dinero.js';
import Overlay from '@/components/Overlay.vue';
import Spinner from '@/components/Spinner.vue';
import BalanceService from '@/services/BalanceService'
import { computed } from '@vue/reactivity';
import { useStore } from '@/store';

const store = useStore();

const showAmount= computed(() => store.getters['layout/showAmount'])

const isLoading = ref(false);

const monthRange = ref({
    date_start: dayjs().startOf('month').format('YYYY-MM-DD'),
    date_end:  dayjs().endOf('month').format('YYYY-MM-DD')
});

const state = reactive({
    total: 0,
    balance: {
        consolidated: 0,
        predicted: 0
    },
    incomes: {
        consolidated: 0,
        predicted: 0
    },
    expenses: {
        consolidated: 0,
        predicted: 0
    }
})

const loadBalanceResume = async () => {
    try {
        let result = await BalanceService.resume({
            date_start: monthRange.value.date_start, 
            date_end: monthRange.value.date_end
        })

        state.balance = result.data.data.balance;
        state.incomes = result.data.data.incomes;
        state.expenses = result.data.data.expenses;
    } catch( error ) {

    }
}

const loadBalanceTotal = async () => {
    try {
        let result = await BalanceService.total()

        state.total = result.data.data.total;
    } catch( error ) {

    }
}

onMounted(async () => {
    isLoading.value = true;
    await loadBalanceResume();
    await loadBalanceTotal();
    isLoading.value = false;
});
</script>
