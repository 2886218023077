import { MutationPayload, Store } from "vuex"
import _ from 'lodash';

export interface Item {
    path: string,
}

export interface PersistOptions {
    key: string,
    driver: (opt: any) => Storage
    items: Item[],
    onSave?: (value: any) => any,
    onRestore?: (value: any) => any
}

export default function PersistStore (options: PersistOptions) {

    return (store: Store<any>) => {

        const driver = options.driver(store);
        let stored = driver.getItem(options.key);
        let parsed = null;

        try {
            parsed = options.onRestore ? options.onRestore(stored) : stored;
        } catch (error :any) {
            parsed = '{}';
        }

        parsed = JSON.parse(String(parsed));

        _.merge(store.state, parsed, { driver: driver} );

        // Subscribe to state changes
        store.subscribe((mutation: MutationPayload, state:any) => {
            const driver = options.driver(store);
            const stateToStore = {};

            for(let item of options.items) {
                const storeHasItem = _.has(store.state, item.path);

                if(storeHasItem) {
                    let itemToStore = _.get(store.state, item.path);

                    _.set(stateToStore, item.path, itemToStore)
                }
            };

            driver.setItem(
                options.key, 
                options.onSave 
                    ? options.onSave(JSON.stringify(stateToStore)) 
                    : JSON.stringify(stateToStore)
            );
        })
    }
}  