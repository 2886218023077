
import { AxiosResponse } from "axios";
import api from "./http/api";

interface ReportParams {
    account_id?: string;
    date_start?: string;
    date_end?: string;
    predicted?: boolean;
    graph: string;
    type: string;
    periodicity: string;
}

class ReportService {

    generate(params: ReportParams) {
        return api({ auth: true, company: true }).get('/backoffice/v1/reports', {
            params
        });
    }

}

export default new ReportService();